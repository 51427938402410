import React, {
    useEffect,
    useState,
    useContext,
    useMemo,
    useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { BsCircleFill } from "react-icons/bs";
import { toast } from "react-toastify";
import Api, { linkBackend } from "../../../../config/api";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { Modal, Col, Row, Form, Card, Button } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import DateRangeInput from "../../../../utils/DateRangeInput";
import { DateRange } from "react-day-picker";
import { convertDataProFusoCorreto } from "../../../../utils/datesUtils";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactDOM from "react-dom";
import { error } from "console";
import GridEngajamentoDemanda from "../../../grids/engajamento/gridEngajamentoDemanda";

const UNDEFINED_COLOR = process.env.REACT_APP_UNDEFINED_COLOR;
const RED_COLOR = process.env.REACT_APP_RED_COLOR;
const YELLOW_COLOR = process.env.REACT_APP_YELLOW_COLOR;
const GREEN_COLOR = process.env.REACT_APP_GREEN_COLOR;

interface Oficio {
    id: number | null;
    // oficioEntrada: File | null;
    // oficioSaida: File | null;
    caminho_oficio_entrada: string;
    caminho_oficio_resposta: string;
}

interface OptionType {
    label: string;
    value: string | number;
}

interface AcaoEngaja {
    acao: string;
    responsavel: string;
    status: string;
    dataPrazo: Date | undefined;
}

function GridDemandaInternaGov({ config }: { config: any }) {
    const { t } = useTranslation();
    const navegar = useNavigate();
    const [datagridRows, setDatagridRows] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [AllStakeholders, setAllStakeholder] = useState<[]>([]);
    const [openModalFiltro, setOpenModalFiltro] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<any | null>(null);
    const [listaEstados, setListaEstados] = useState<any>([]);
    const [listaMunicipios, setListaMunicipios] = useState<any>([]);
    const [listaStakeholders, setListaStakeholders] = useState<any>([]);

    const [idDemanda, setIdDemanda] = useState<any>();
    const [atualizarDados, setAtualizardados] = useState<any>();
    const [autorOrigem, setAutorOrigem] = useState<string>("");
    //input
    const [descricaoTema, setDescricaoTema] = useState<string>("");
    const [acao, setAcao] = useState<any>([]);
    const [responsavel, setResponsavel] = useState<any>([]);
    const [fotoDemandaInterna, setFotoDemandaInterna] = useState<string>("");
    const [fotoDemandaInterna2, setFotoDemandaInterna2] = useState<string>("");
    //Select
    const [origem, setOrigem] = useState<string>("");
    const [origemTexto, setOrigemTexto] = useState<string>("");
    const [stakeholder, setStakeholder] = useState<any>([]);
    const [categoriaStakeholder, setCategoriaStakeholder] =
        useState<string>("");
    const [estado, setEstado] = useState<string>("");
    const [municipio, setMunicipio] = useState<string>("");
    const [categoriaTema, setCategoriaTema] = useState<string>("");
    const [criticidadeTema, setCriticidadeTema] = useState<string>("");
    const [impactoFinanceiro, setImpactoFinanceiro] = useState<string>("");
    const [prioridadeTema, setPrioridadeTema] = useState<string>("");
    const [prioridadeData, setPrioridadeData] = useState<string>("");
    const [status, setStatus] = useState<any>([]);

    const [categoriaTemaTexto, setCategoriaTemaTexto] = useState<string>("");
    const [categoriaStakeholderTexto, setCategoriaStakeholderTexto] =
        useState<string>("");
    //Switch
    const [planoAcao, setPlanoAcao] = useState<boolean>(false);
    const [aplicarRestricao, setAplicarRestricao] = useState<boolean>(false);
    const [atendida, setAtendida] = useState<boolean>(false);
    //Date
    const [dataEntrada, setDataEntrada] = useState<string>("");
    const [dataPrazo, setDataPrazo] = useState<any>([]);
    const [dataConclusao, setDataConclusao] = useState<string>("");
    //Check
    const [areasEnvolvidas, setAreasEnvolvidas] = useState<any>([]);
    const [showModalEngajamento, setShowModalEngajamento] =
        useState<boolean>(false);
    const [permissoesUser, setPermissoesUser] = useState({
        administrador: false,
        atendimentos_create: false,
        atendimentos_edit: false,
        atendimentos_view: false,
        demandas_create: false,
        demandas_edit: false,
        demandas_view: false,
        engajamentos_create: false,
        engajamentos_edit: false,
        engajamentos_view: false,
        id: 0,
        id_user: 0,
        noticias_create: false,
        noticias_edit: false,
        noticias_view: false,
        notificacoes_create: false,
        notificacoes_edit: false,
        notificacoes_view: false,
        stakeholders_create: false,
        stakeholders_edit: false,
        stakeholders_view: false,
        usuario_create: false,
        usuario_edit: false,
        visitas_create: false,
        visitas_edit: false,
        visitas_view: false,
    });

    // PLANO DE CAAO ENGAJAMENTO
    const [planoAcaoEngaja, setPlanoAcaoEngaja] = useState<boolean>(false);
    const [areasEnvolvidasEngaja, setAreasEnvolvidasEngaja] = useState<any>([]);
    const [acoesEngaja, setAcoesEngaja] = useState<AcaoEngaja[]>([
        { acao: "", responsavel: "", status: "", dataPrazo: undefined },
    ]);
    const [showModalGrid, setShowModalGrid] = useState<boolean>(false);
    const [relevancia, setRelevancia] = useState<string>("");
    const [tratativa, setTratativa] = useState<string>("");
    const [statusAtendimento, setStatusAtendimento] = useState<string>("");
    const [oficioParaExclusao, setOficioParaExclusao] = useState<Oficio[]>([]);

    //   ***---  INPUTS MODAL REGISTRO DE ENGAJAMENTO  ---***
    const [engajementosSalvos, setEngajementosSalvos] = useState([{}]);
    const [assunto, setAssunto] = useState<string>("");
    const [stakeholdersEngajamento, setStakeholdersEngajamento] = useState([
        { id: 1, value: "" },
    ]);
    const [esferaEngaja, setEsferaEngaja] = useState<string>("");
    const [esferaDemanda, setEsferaDemanda] = useState<string>("");

    const [descricao, setDescricao] = useState<string>("");
    const [dataEngajamento, setDataEngajamento] = useState<Date>();
    const [formaDeContato, setFormaDeContato] = useState<string>("");
    const [formaDeContatoText, setFormaDeContatoText] = useState<string>("");
    const [estadoEngajamento, setEstadoEngajamento] = useState<string>("");
    const [municipioEngajamento, setMunicipioEngajamento] =
        useState<string>("");
    const [participantesMosaicEngajamento, setParticipantesMosaicEngajamento] =
        useState<string>("");
    const [
        participantesExternosEngajamento,
        setParticipantesExternosEngajamento,
    ] = useState<string>("");
    const [acaoEngajamento, setAcaoEngajamento] = useState<string>("");
    const [responsaveisEngajamento, setResponsaveisEngajamentoEngajamento] =
        useState<string>("");
    const [dataPrazoEngajamento, setDataPrazoEngajamento] = useState<Date>();
    const [fotoEngajamento, setFotoEngajamento] = useState<any>(null);

    const [planoAcaoEngajamento, setPlanoAcaoEngajamento] = useState<boolean>();

    const [participantesMosaicList, setParticipantesMosaicList] = useState([
        { id: 1, value: "" },
    ]);
    const [participantesExternosList, setParticipantesExternosList] = useState([
        { id: 1, value: "" },
    ]);
    const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
    const [opemModalOficios, setOpemModalOficios] = useState<boolean>(false);
    const [oficios, setOficios] = useState<Oficio[]>([]);
    const handleCloseModalDelete = () => {
        setShowModalDelete(false);
    };

    const handleOpenModalDelete = () => {
        setShowModalDelete(true);
    };

    const handleOpenModalEngajamento = () => {
        setShowModalEngajamento(true);
    };

    const handleCloseModalEngajamento = () => {
        setShowModalEngajamento(false);
    };

    const handleCloseModalGrid = () => {
        setShowModalGrid(false);
    };

    const handleOpenModalGrid = () => {
        setShowModalGrid(true);
    };

    interface MunicipiosPorEstado {
        [estado: string]: string[];
    }
    const municipiosPorEstado: MunicipiosPorEstado = {};

    const [estadosExistentes, municipiosExistentes] = useMemo(() => {
        const estados: string[] = [];
        const municipios: string[] = [];

        datagridRows.forEach((r) => {
            r.estado && !estados.includes(r.estado) && estados.push(r.estado);
            r.municipio &&
                !municipios.includes(r.municipio) &&
                municipios.push(r.municipio);
        });

        return [
            estados.sort((a, b) => a.localeCompare(b)),
            municipios.sort((a, b) => a.localeCompare(b)),
        ];
    }, [datagridRows]);

    datagridRows.forEach((r) => {
        if (r.estado && r.municipio) {
            if (!municipiosPorEstado[r.estado]) {
                municipiosPorEstado[r.estado] = [];
            }

            if (!municipiosPorEstado[r.estado].includes(r.municipio)) {
                municipiosPorEstado[r.estado].push(r.municipio);
            }
        }
    });

    const [filtroVazio, setFiltroVazio] = useState(true);
    const [filtroOrigem, setFiltroOrigem] = useState<string>();
    const [filtroEstado, setFiltroEstado] = useState<string>();
    const [filtroCategoria, setFiltroCategoria] = useState<string>();
    const [filtroMunicipio, setFiltroMunicipio] = useState<string>();
    const [filtroPrioridade, setFiltroPrioridade] = useState<string>();
    const [filtroCriticidade, setFiltroCriticidade] = useState<string>();
    const [filtroAtual, setFiltroAtual] = useState<string>();
    // const [dadosFiltrados, setDadosFiltrados] = useState<any[]>([]);
    const [filtroDataEntrada, setFiltroDataEntrada] = useState<DateRange>();
    const [filtroDataConclusao, setFiltroDataConclusao] = useState<DateRange>();

    const municipiosFiltrados = useMemo(() => {
        return filtroEstado
            ? municipiosPorEstado[filtroEstado] || []
            : municipiosExistentes;
    }, [filtroEstado, municipiosPorEstado, municipiosExistentes]);

    const [showPickerData, setShowPickerData] = useState<string>();
    const [engajamentosByDemanda, setEngajamentosByDemanda] = useState([]);

    const tiposFiltro = [
        {
            value: "Data de Entrada",
            label: t("Data de Entrada"),
            type: "date",
            filter: filtroDataEntrada,
            setFilter: setFiltroDataEntrada,
        },
        {
            value: "Data de Conclusão",
            label: t("Data de Conclusão"),
            type: "date",
            filter: filtroDataConclusao,
            setFilter: setFiltroDataConclusao,
        },
        {
            value: "Origem",
            label: t("Origem"),
            type: "select",
            opts: [
                { label: t("Operações"), value: "Operações" },
                { label: t("Projetos"), value: "Projetos" },
                { label: t("EHS"), value: "EHS" },
                { label: t("Juridico"), value: "Juridico" },
                { label: t("Tributário"), value: "Tributário" },
                { label: t("Financeiro"), value: "Financeiro" },
                { label: t("CSC"), value: "CSC" },
                { label: t("RH"), value: "RH" },
                { label: t("Comunicação"), value: "Comunicação" },
                {
                    label: t("Relações Institucionais"),
                    value: "Relações Institucionais",
                },
                { label: t("Instituto Mosaic"), value: "Instituto Mosaic" },
                {
                    label: t("Relações com Comunidades"),
                    value: "Relações com Comunidades",
                },
                {
                    label: t("Relações Governamentais"),
                    value: "Relações Governamentais",
                },
            ],
            filter: filtroOrigem,
            setFilter: setFiltroOrigem,
        },
        {
            value: "Categoria",
            label: t("Categoria"),
            type: "select",
            opts: [
                {
                    label: t("Poder Público Municipal - Prefeitura"),
                    value: "Poder Público Municipal - Prefeitura",
                },
                {
                    label: t("Poder Público Municipal - Secretaria"),
                    value: "Poder Público Municipal - Secretaria",
                },
                {
                    label: t("Poder Público Municipal - Autarquia"),
                    value: "Poder Público Municipal - Autarquia",
                },
                {
                    label: t("Poder Público Municipal - Câmara de Vereadores"),
                    value: "Poder Público Municipal - Câmara de Vereadores",
                },
                {
                    label: t("Poder Público Estadual"),
                    value: "Poder Público Estadual",
                },
                {
                    label: t("Poder Público Federal"),
                    value: "Poder Público Federal",
                },
                { label: t("Ministério Público"), value: "Ministério Público" },
                {
                    label: t("Entidades Setoriais"),
                    value: "Entidades Setoriais",
                },
                {
                    label: t("Entidades do Terceiro Setor"),
                    value: "Entidades do Terceiro Setor",
                },
                { label: t("Setor Privado"), value: "Setor Privado" },
                { label: t("Academia"), value: "Academia" },
            ],
            filter: filtroCategoria,
            setFilter: setFiltroCategoria,
        },
        {
            value: "Criticidade",
            label: t("Criticidade"),
            type: "select",
            opts: [
                { label: t("Alta"), value: "Alta" },
                { label: t("Média"), value: "Média" },
                { label: t("Baixa"), value: "Baixa" },
            ],
            filter: filtroCriticidade,
            setFilter: setFiltroCriticidade,
        },
        {
            value: "Estado",
            label: t("Estado"),
            type: "select",
            opts: estadosExistentes,
            filter: filtroEstado,
            setFilter: setFiltroEstado,
        },
        {
            value: "Municipio",
            label: t("Municipio"),
            type: "select",
            opts: municipiosFiltrados,
            filter: filtroMunicipio,
            setFilter: setFiltroMunicipio,
        },
        // {
        //   value: "Prioridade",
        //   label: t("Prioridade"),
        //   type: "select",
        //   opts: [
        //     { label: t("Urgente"), value: "Urgente" },
        //     { label: t("Importante"), value: "Importante" },
        //     { label: t("Média"), value: "Média" },
        //     { label: t("Baixa"), value: "Baixa" },
        //   ],
        //   filter: filtroPrioridade,
        //   setFilter: setFiltroPrioridade,
        // },
    ];

    const [filtrosAtivos, setFiltroAtivo] = useState<string[]>([]);

    const dadosFiltrados = useMemo(
        () =>
            datagridRows.filter(
                (v) =>
                    (!filtroOrigem ||
                        v?.origem
                            ?.toLowerCase()
                            .includes(filtroOrigem?.toLowerCase())) &&
                    (!filtroCategoria ||
                        v.categoria_stakeholder
                            ?.toLowerCase()
                            .includes(filtroCategoria?.toLowerCase())) &&
                    (!filtroCriticidade ||
                        v.criticidade_tema
                            ?.toLowerCase()
                            .includes(filtroCriticidade?.toLowerCase())) &&
                    (!filtroEstado ||
                        v.estado
                            ?.toLowerCase()
                            .includes(filtroEstado?.toLowerCase())) &&
                    (!filtroMunicipio ||
                        v.municipio
                            ?.toLowerCase()
                            .includes(filtroMunicipio?.toLowerCase())) &&
                    (!filtroPrioridade ||
                        v.prioridade_tema
                            ?.toLowerCase()
                            .includes(filtroPrioridade?.toLowerCase())) &&
                    (!filtroDataEntrada ||
                        (v.dt_entrada >=
                            (filtroDataEntrada.from || new Date()) &&
                            v.dt_entrada <=
                                (filtroDataEntrada.to
                                    ? filtroDataEntrada.to
                                    : filtroDataEntrada.from || new Date()))) &&
                    (!filtroDataConclusao ||
                        (v.dt_conclusao >=
                            (filtroDataConclusao.from || new Date()) &&
                            v.dt_conclusao <=
                                (filtroDataConclusao.to
                                    ? filtroDataConclusao.to
                                    : filtroDataConclusao.from || new Date())))
            ),
        [
            datagridRows,
            filtroOrigem,
            filtroCategoria,
            filtroCriticidade,
            filtroEstado,
            filtroMunicipio,
            filtroPrioridade,
            filtroDataEntrada,
            filtroDataConclusao,
        ]
    );

    const [acoes, setAcoes] = useState<any>([]);

    const getAPIEngajamentosByDemanda = async (id: number) => {
        const engajamentos = await Api.getEngajamentosRgByDemanda(id);
        setEngajamentosByDemanda(engajamentos);
    };

    useEffect(() => {
        if (selectedRowData) {
            setDescricaoTema(selectedRowData.descricao || "");
            setIdDemanda(selectedRowData.id || "");
            setEsferaDemanda(selectedRowData.esfera || "");
            setAcao(selectedRowData.acao || "");
            setAcoes(selectedRowData.acoes || []);
            setResponsavel(selectedRowData.resposanvel || "");
            setFotoDemandaInterna(selectedRowData.foto || "");
            setFotoDemandaInterna2(selectedRowData.foto2 || "");
            setOrigem(selectedRowData.origem || "");
            setOrigemTexto(selectedRowData.origem_texto || "");

            const getOficiosId = async () => {
                const oficios = await Api.getOficiosRgById(
                    selectedRowData.id,
                    "interna"
                );
                setOficios(oficios);
            };
            getOficiosId();

            const stakeMapeadosComId =
                selectedRowData.stakeholder &&
                selectedRowData.stakeholder.map(
                    (stringValue: any, index: any) => ({
                        id: index + 1,
                        value: stringValue,
                    })
                );
            setStakeholder(stakeMapeadosComId || "");
            setAutorOrigem(selectedRowData.autor_origem);
            setCategoriaStakeholder(
                selectedRowData.categoria_stakeholder || ""
            );
            setEstado(selectedRowData.estado || "");
            setImpactoFinanceiro(selectedRowData.impacto_financeiro || "");
            setMunicipio(selectedRowData.municipio || "");
            setCategoriaTema(selectedRowData.categoria_tema || "");
            setCriticidadeTema(selectedRowData.criticidade_tema || "");
            setPrioridadeTema(selectedRowData.prioridade_tema || "");
            setPrioridadeData(
                formatDatePrevisao(selectedRowData.dt_previsao || "")
            );
            setStatus(selectedRowData.status || "");
            setPlanoAcao(selectedRowData.plano_acao || "");
            setAplicarRestricao(selectedRowData.aplicar_restricao || "");
            setDataEntrada(formatDate(selectedRowData.dt_entrada || ""));
            setDataPrazo(selectedRowData.dt_prazo || "");
            setDataConclusao(
                selectedRowData.dt_conclusao
                    ? formatDate(selectedRowData.dt_conclusao)
                    : ""
            );
            setAreasEnvolvidas(selectedRowData.areas_envolvidas || "");
            setRelevancia(selectedRowData.relevancia || "");
            setTratativa(selectedRowData.tratativa || "");
            setStatusAtendimento(selectedRowData.status_atendimento || "");
            setAtendida(selectedRowData.atendida || "");
            getAPIEngajamentosByDemanda(selectedRowData.id);
        }
    }, [selectedRowData]);

    useEffect(() => {
        const permissionsUsuario = sessionStorage.getItem("permissoesUsuario");
        if (permissionsUsuario) {
            setPermissoesUser(JSON.parse(permissionsUsuario));
        }
    }, []);

    useEffect(() => {
        const atualizarStakeholder = async () => {
            const stakeAtualizado = await Api.GetStakeholders(config);
            setAllStakeholder(stakeAtualizado);
        };
        atualizarStakeholder();
    }, []);

    const handleRowClick = (params: any) => {
        setSelectedRowData(params.row);
        setIsModalOpen(true);
    };

    const datagridColunas: readonly GridColDef<any>[] = [
        {
            field: "dt_entrada",
            headerName: t("Data de Entrada") as string,
            type: "date",
            width: 150,
            valueGetter: ({ row }: { row: any }) => {
                if (!row.dt_entrada) {
                    return null;
                }
                return new Date(row.dt_entrada);
            },
            renderCell: (params: GridRenderCellParams<any, any, any, any>) => {
                return params.value && params.value instanceof Date
                    ? format(params.value, "dd/MM/yyyy", { locale: ptBR })
                    : "";
            },
        },
        {
            field: "dt_conclusao",
            headerName: t("Data de Conclusão") as string,
            type: "date",
            width: 150,
            valueGetter: ({ row }: { row: any }) => {
                if (!row.dt_conclusao) {
                    return null;
                }
                return new Date(row.dt_conclusao);
            },
            renderCell: (params: GridRenderCellParams<any, any, any, any>) => {
                return params.value && params.value instanceof Date
                    ? format(params.value, "dd/MM/yyyy", { locale: ptBR })
                    : "";
            },
        },
        {
            field: "origem",
            headerName: t("Origem") as string,
            type: "string",
            width: 150,
        },
        {
            field: "descricao",
            headerName: t("Descrição") as string,
            type: "string",
            width: 200,
        },
        {
            field: "stakeholder",
            headerName: t("Stakeholder") as string,
            type: "string",
            width: 150,
        },
        {
            field: "categoria_stakeholder",
            headerName: t("Categoria Stakeholder") as string,
            type: "string",
            width: 200,
        },
        {
            field: "estado",
            headerName: t("header_Estado") as string,
            type: "select",
            width: 120,
        },
        {
            field: "municipio",
            headerName: t("header_Municipio") as string,
            type: "string",
            width: 120,
        },
        {
            field: "categoria_tema",
            headerName: t("Categoria Tema") as string,
            type: "string",
            width: 150,
        },
        {
            field: "prioridade_tema",
            headerName: t("Prioridade") as string,
            type: "string",
            width: 150,
        },
        {
            field: "criticidade_tema",
            headerName: t("header_Criticidade") as string,
            type: "select",
            width: 150,
            renderCell: (params: GridRenderCellParams<any, any, any, any>) => {
                const icon =
                    params.value === t("Alta")
                        ? RED_COLOR
                        : params.value === t("Média")
                        ? YELLOW_COLOR
                        : params.value === t("Baixa")
                        ? GREEN_COLOR
                        : UNDEFINED_COLOR;

                return (
                    <span>
                        <BsCircleFill color={icon} /> {params.value}
                    </span>
                );
            },
        },
        {
            field: "relevancia",
            headerName: "Relevância",
            type: "text",
            width: 150,
        },
        {
            field: "impacto_financeiro",
            headerName: "Imapacto financeiro" as string,
            type: "text",
            width: 150,
        },
    ];

    const deleteDemanda = async () => {
        await Api.deleteDemandaRgInterna(selectedRowData.id)
            .then((res) => {
                toast.success("Demanda deletada com sucesso!");
                handleCloseModalDelete();
                closeModal();
                updateDatagrid();
            })
            .catch((err) => {
                toast.error("Erro ao tantar deletar Demanda!");
            });
    };

    const updateDatagrid = useCallback(() => {
        if (!config || !navegar || !t) return;

        Api.GetDemandaRgInterna(config)
            .then((data: any[]) => {
                // Mapeia os dados e converte para o formato desejado
                const mappedData = data.map((d: any, index: number) => {
                    let areasEnvolvidasString = d.areas_envolvidas.replace(
                        /\\/g,
                        ""
                    );
                    areasEnvolvidasString = areasEnvolvidasString.replace(
                        /^"|"$/g,
                        ""
                    );

                    const areasEnvolvidasOriginal =
                        areasEnvolvidasString &&
                        JSON.parse(areasEnvolvidasString);
                    const areasEnvolvidasSimples =
                        areasEnvolvidasOriginal &&
                        areasEnvolvidasOriginal.flat();
                    let valuesAreas =
                        areasEnvolvidasSimples &&
                        areasEnvolvidasSimples.map((area: any) => {
                            return {
                                value: area,
                                label: area,
                            };
                        });

                    const acoesTratadas = d.acao
                        ? JSON.parse(d.acao).map((acoes: any) => {
                              return acoes;
                          })
                        : null;

                    const stakeholdersTratados = d.stakeholder
                        ? JSON.parse(d.stakeholder).map((stake: any) => {
                              return stake;
                          })
                        : null;

                    const responsavelTratados = d.responsavel
                        ? JSON.parse(d.responsavel).map((resp: any) => {
                              return resp;
                          })
                        : null;

                    let prazoTratados: any[] = [];
                    if (d.dt_prazo) {
                        JSON.parse(d.dt_prazo).map((dt: any) => {
                            let dataRender = formatDatePrazo(dt);
                            prazoTratados.push(dataRender);
                        });
                    }

                    const statusTratados = d.status
                        ? JSON.parse(d.status).map((status: any) => {
                              return status;
                          })
                        : null;

                    const criarArrayDeObjetos = (
                        acoes: any,
                        responsaveis: any,
                        status: any,
                        dt_prazo: any
                    ) => {
                        const arrayDeObjetos = [];
                        for (let i = 0; i < acoes.length; i++) {
                            const objeto = {
                                acao: acoes[i],
                                responsavel: responsaveis[i],
                                status: status[i],
                                dt_prazo: dt_prazo[i],
                            };
                            arrayDeObjetos.push(objeto);
                        }
                        return arrayDeObjetos;
                    };

                    const arrayDeObjetos = criarArrayDeObjetos(
                        JSON.parse(d.acao),
                        JSON.parse(d.responsavel),
                        JSON.parse(d.status),
                        JSON.parse(d.dt_prazo)
                    );

                    const dataEntradaAjustada = d.dt_entrada
                        ? convertDataProFusoCorreto(d.dt_entrada)
                        : null;
                    const dataConclusaoAjustada = d.dt_conclusao
                        ? convertDataProFusoCorreto(d.dt_conclusao)
                        : null;

                    return {
                        ...d,
                        id: d.id,
                        descricao: d.descricao_tema,
                        origem: d.origem,
                        stakeholder: stakeholdersTratados,
                        categoria_stakeholder: d.categoria_stakeholder,
                        estado: d.estado,
                        municipio: d.municipio,
                        categoria_tema: d.categoria_tema,
                        criticidade_tema: d.criticidade_tema,
                        prioridade_tema: d.prioridade_tema,
                        plano_acao: d.plano_acao,
                        areas_envolvidas: valuesAreas,
                        acoes: arrayDeObjetos && arrayDeObjetos,
                        acao: acoesTratadas,
                        resposanvel: responsavelTratados,
                        status: statusTratados,
                        dt_entrada: dataEntradaAjustada,
                        dt_prazo: prazoTratados,
                        dt_conclusao: dataConclusaoAjustada,
                        dt_previsao: d.dt_previsao,
                        foto: d.foto,
                        foto2: d.foto2,
                        relevancia: d.relevancia,
                        tratativa: d.tratativa,
                        status_atendimento: d.status_atendimento,
                        atendida: d.atendida,
                    };
                });

                const sortedData = mappedData.sort((a, b) => b.id - a.id);
                setDatagridRows(sortedData);
            })
            .catch((err: any) => {
                if (typeof err === "string") {
                    toast.error(t(err));
                    navegar("/login");
                    return;
                }

                toast.error(
                    t("Houve um erro ao carregar os Demandas Internas")
                );
            });
    }, [config, navegar, t]);

    const updateLocales = useCallback(() => {
        Api.GetLocales().then((data) => {
            setListaEstados(data.localidades.estados);
            setListaMunicipios(data.localidades.municipios);
        });
    }, []);

    useEffect(updateDatagrid, [updateDatagrid]);
    useEffect(updateLocales, [updateLocales]);

    const handleModalFiltros = (filtro: string) => {
        setFiltroAtivo((filtros) => {
            const newFiltros = [...filtros];
            const fIndex = newFiltros.findIndex((f) => f === filtro);
            if (fIndex >= 0) {
                newFiltros.splice(fIndex, 1);
                tiposFiltro
                    .find((f) => f.value === filtro)
                    ?.setFilter(undefined);
            } else {
                newFiltros.push(filtro);
            }
            return newFiltros;
        });
        setOpenModalFiltro(true);
    };

    function formatDate(dateString: string | Date) {
        const dateOri = new Date(dateString);

        const date = new Date(
            dateOri.setHours(
                dateOri.getHours() + dateOri.getTimezoneOffset() / 60
            )
        );
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear().toString().padStart(4, "0");

        return `${year}-${month}-${day}`;
    }

    function formatDatePrazo(dateString: string | Date) {
        const date = new Date(dateString);

        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear().toString().padStart(4, "0");

        return `${year}-${month}-${day}`;
    }

    function formatDatePrevisao(dateString: string | Date) {
        const date = new Date(dateString);
        const day = (date.getDate() + 1).toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear().toString().padStart(4, "0");
        return `${day}/${month}/${year}`;
    }

    const handleFileChange = (e: any) => {
        setFotoDemandaInterna(e.target.files ? e.target.files : null);
    };

    const generateOptionsStakeholders = useCallback(() => {
        if (Array.isArray(AllStakeholders)) {
            return [
                { value: "", label: "Selecione Stakeholder..." },
                ...AllStakeholders.filter(
                    (stakeholder: any) =>
                        municipio && stakeholder.municipio === municipio
                ).map(
                    ({
                        stakeholder,
                        estado,
                        municipio,
                    }: {
                        stakeholder: any;
                        estado: any;
                        municipio: any;
                    }) => ({
                        value: stakeholder,
                        label: `${stakeholder} - ${municipio}`,
                    })
                ),
            ];
        }

        return [];
    }, [AllStakeholders, municipio]);

    const optionsStakeholders = useMemo(
        () => generateOptionsStakeholders(),
        [generateOptionsStakeholders]
    );

    const HandleEditDemanda = async () => {
        const formData = new FormData();

        const valueAreas =
            areasEnvolvidas &&
            areasEnvolvidas.map((area: any) => {
                return area.value;
            });

        if (fotoDemandaInterna !== null) {
            formData.append("avatar", fotoDemandaInterna[0]);
            formData.append("avatar2", fotoDemandaInterna[1]);
            formData.append("avatar3", fotoDemandaInterna[2]);
            formData.append("avatar4", fotoDemandaInterna[3]);
        }
        if (fotoEngajamento !== null) {
            formData.append("avatarEngajamento", fotoEngajamento);
        }
        formData.append("id", idDemanda);
        formData.append("dataEntrada", dataEntrada);
        formData.append("dataPrevisao", prioridadeData);
        formData.append("origem", origem);
        formData.append("autorOrigem", autorOrigem);

        const stakeholdersValues = stakeholder.map(
            ({ value }: { value: any }) => {
                return value;
            }
        );
        stakeholder
            ? formData.append("stakeholder", JSON.stringify(stakeholdersValues))
            : formData.append("stakeholder", "");
        formData.append("estado", estado);
        formData.append("municipio", municipio);
        formData.append("esfera", esferaDemanda);
        formData.append("categoriaStakeholder", categoriaStakeholder);
        formData.append("categoriaTema", categoriaTema);
        formData.append("criticidadeTema", criticidadeTema);
        formData.append("impactoFinanceiro", impactoFinanceiro);
        formData.append("prioridadeTema", prioridadeTema);
        formData.append("descricaoTema", descricaoTema);
        formData.append("planoAcao", String(planoAcao));
        formData.append("areasEnvolvidas", JSON.stringify(valueAreas));
        formData.append("atendida", String(atendida));

        const acoesDB = acoes.map((acao: any) => {
            return acao.acao;
        });
        const responsaveisDB = acoes.map((acao: any) => {
            return acao.responsavel;
        });
        const prazosDB = acoes.map((acao: any) => {
            const dt = removerHorarioDaData(acao.dt_prazo);
            return dt;
        });
        const statusDB = acoes.map((acao: any) => {
            return acao.status;
        });
        function removerHorarioDaData(data: any) {
            const dataObj = new Date(data);
            const dataFormatada = dataObj.toISOString().split("T")[0];
            return dataFormatada;
        }

        formData.append("acoes", JSON.stringify(acoesDB));
        formData.append("responsaveis", JSON.stringify(responsaveisDB));
        formData.append("prazos", JSON.stringify(prazosDB));
        formData.append("status", JSON.stringify(statusDB));
        formData.append("tratativa", tratativa);

        formData.append(
            "statusAtendimento",
            typeof dataConclusao === "object" ? "Atendida" : "Em andamento"
        );
        if (dataConclusao)
            formData.append("dataConclusao", String(dataConclusao));
        formData.append("engajamentos", JSON.stringify(engajementosSalvos));

        const idsOficiosExclusao = oficioParaExclusao.map(
            (oficio) => oficio.id
        );
        formData.append(
            "idsOficiosExclusao",
            JSON.stringify(idsOficiosExclusao)
        );

        oficios.forEach((oficio, index) => {
            if (
                oficio.caminho_oficio_entrada &&
                typeof oficio.caminho_oficio_entrada === "object"
            ) {
                formData.append(
                    `oficio_entrada${index + 1}`,
                    oficio.caminho_oficio_entrada
                );
            } else {
                formData.append(
                    `oficioEntradaString${index + 1}`,
                    oficio.caminho_oficio_entrada
                );
            }

            if (
                oficio.caminho_oficio_resposta &&
                typeof oficio.caminho_oficio_resposta === "object"
            ) {
                formData.append(
                    `oficio_resposta${index + 1}`,
                    oficio.caminho_oficio_resposta
                );
            } else {
                formData.append(
                    `oficioRespostaString${index + 1}`,
                    oficio.caminho_oficio_resposta
                );
            }

            formData.append(`oficio_id${index + 1}`, String(oficio.id));
        });

        await Api.PutDemandaRgInterna(formData, config)
            .then(() => {
                toast.success("Demanda Editada com sucesso!");
                closeModal();
                updateDatagrid();
            })
            .catch((err) => toast.error("Erro ao editar demanda!"));

        setEngajementosSalvos([{}]);
        updateDatagrid();
    };

    const handleSubmitFormEngajamento = async (event: any) => {
        event.preventDefault();

        const participantesMosaicStringfy = JSON.stringify(
            participantesMosaicList.map(({ value }: { value: any }) => {
                return value;
            })
        );

        const participantesExternosStringfy = JSON.stringify(
            participantesExternosList.map(({ value }: { value: any }) => {
                return value;
            })
        );

        const obj = {
            estado: estadoEngajamento,
            municipio: municipioEngajamento,
            data: dataEngajamento,
            stakeholder: stakeholdersEngajamento.map((stake) => stake.value),
            tema: assunto,
            contato:
                formaDeContato === "Outros"
                    ? formaDeContatoText
                    : formaDeContato,
            participantesMosaic: participantesMosaicStringfy,
            participantesExternos: participantesExternosStringfy,
            descricao: descricao,
            planoAcao: planoAcaoEngaja,
            areasEnvolvidas: areasEnvolvidasEngaja,
            acoes: acoesEngaja,
            esfera: esferaEngaja,
        };

        setEngajementosSalvos((prevEngajementos) => {
            const novoArray = [...prevEngajementos, obj];
            return novoArray;
        });

        toast.success(t("Engajamento salvo com sucesso!"));

        const clearInputs = () => {
            setEstadoEngajamento("");
            setEsferaEngaja("");
            setMunicipioEngajamento("");
            setDataEngajamento(new Date());
            setStakeholdersEngajamento([{ id: 1, value: "" }]);
            setFormaDeContato("");
            setFormaDeContatoText("");
            setParticipantesMosaicEngajamento("");
            setParticipantesExternosEngajamento("");
            setDescricao("");
            setAssunto("");
            setParticipantesExternosList([]);
            setParticipantesMosaicList([]);
            setPlanoAcaoEngaja(false);
            setAreasEnvolvidasEngaja([]);
            setAcoesEngaja([
                { acao: "", responsavel: "", status: "", dataPrazo: undefined },
            ]);
        };

        clearInputs();
    };

    const handleChangeStakeholderEngajamento = (id: any, value: any) => {
        const updatedStakeholders = stakeholdersEngajamento.map(
            (stakeholder: any) =>
                stakeholder.id === id ? { ...stakeholder, value } : stakeholder
        );
        setStakeholdersEngajamento(updatedStakeholders);
    };

    const handleAddStakeholderEngajamento = () => {
        const newStakeholder = {
            id: stakeholdersEngajamento.length + 1,
            value: "",
        };
        setStakeholdersEngajamento([
            ...stakeholdersEngajamento,
            newStakeholder,
        ]);
    };

    const handleAddParticipanteMosaic = () => {
        const newParticipanteMosaic = {
            id: participantesMosaicList.length + 1,
            value: participantesMosaicEngajamento,
        };
        setParticipantesMosaicList([
            ...participantesMosaicList,
            newParticipanteMosaic,
        ]);
        setParticipantesMosaicEngajamento("");
    };

    const handleRemoveParticipanteMosaic = (id: number) => {
        const updatedParticipantesMosaic = participantesMosaicList.filter(
            (participanteMosaic) => participanteMosaic.id !== id
        );
        setParticipantesMosaicList(updatedParticipantesMosaic);
    };

    const handleAddParticipanteExternos = () => {
        const newParticipanteExternos = {
            id: participantesExternosList.length + 1,
            value: participantesExternosEngajamento,
        };
        setParticipantesExternosList([
            ...participantesExternosList,
            newParticipanteExternos,
        ]);
        setParticipantesExternosEngajamento("");
    };

    const handleRemoveStakeholderEngajamaneto = (id: any) => {
        const updatedStakeholders = stakeholdersEngajamento.filter(
            (stakeholder: any) => stakeholder.id !== id
        );
        setStakeholdersEngajamento(updatedStakeholders);
    };

    const handleRemoveParticipanteExternos = (id: number) => {
        const updatedParticipantesExternos = participantesExternosList.filter(
            (participanteExternos) => participanteExternos.id !== id
        );
        setParticipantesExternosList(updatedParticipantesExternos);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        const getApi = async () => {
            await Api.GetLocales().then((data) => {
                setListaEstados(data.localidades.estados);
                setListaMunicipios(data.localidades.municipios);
            });

            await Api.GetStakeholders(config).then((data) => {
                setListaStakeholders(data);
            });
        };

        getApi();
    }, []);

    const ExibirImagemCarrousel = ({ imageUrls }: { imageUrls: string[] }) => {
        const handleViewImage = () => {
            const overlayElement = document.createElement("div");
            overlayElement.style.cssText = `
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
      `;

            const carouselElement = document.createElement("div");
            carouselElement.style.cssText = `
        width: 80%;
        height: 80%;
      `;
            overlayElement.appendChild(carouselElement);

            const closeButton = document.createElement("button");
            closeButton.textContent = "Fechar";
            closeButton.style.cssText = `
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: transparent;
        border: none;
        color: white;
        font-size: 16px;
        cursor: pointer;
      `;
            closeButton.addEventListener("click", () => {
                document.body.removeChild(overlayElement);
            });
            overlayElement.appendChild(closeButton);

            const showNoImageMessage = () => {
                const noImageMessage = document.createElement("div");
                noImageMessage.textContent = "Nenhuma imagem disponível";
                noImageMessage.style.cssText = `
          color: white;
          font-size: 20px;
        `;
                overlayElement.appendChild(noImageMessage);
            };

            const CustomPrevArrow = (props: any) => {
                const { className, onClick } = props;
                return (
                    <div className={className} onClick={onClick}>
                        Previous
                    </div>
                );
            };

            const CustomNextArrow = (props: any) => {
                const { className, onClick } = props;
                return (
                    <div className={className} onClick={onClick}>
                        Next
                    </div>
                );
            };

            if (imageUrls.length === 0) {
                showNoImageMessage();
            } else {
                ReactDOM.render(
                    <Slider
                        dots={true}
                        slidesToShow={1}
                        slidesToScroll={1}
                        infinite={true}
                        speed={1000}
                        prevArrow={<CustomPrevArrow />}
                        nextArrow={<CustomNextArrow />}
                    >
                        {imageUrls.map((imageUrl, index) => {
                            const formattedImageUrl = imageUrl.replace(
                                /\\/g,
                                "/"
                            );
                            const linkConcat = `${linkBackend}${formattedImageUrl}`;
                            const linkCompleto = linkConcat.replace(
                                /\/\/public/,
                                "/public"
                            );

                            return (
                                <div
                                    key={index}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                    }}
                                >
                                    {imageUrl ? (
                                        <img
                                            src={linkCompleto}
                                            alt={`Imagem ${index + 1}`}
                                            style={{
                                                maxHeight: "85vh",
                                                maxWidth: "100%",
                                                margin: "auto",
                                                objectFit: "contain",
                                            }}
                                        />
                                    ) : (
                                        <h4
                                            style={{
                                                color: "white",
                                                textAlign: "center",
                                            }}
                                        >
                                            Não há imagem
                                        </h4>
                                    )}
                                </div>
                            );
                        })}
                    </Slider>,
                    carouselElement
                );

                document.body.appendChild(overlayElement);

                // Event listener para fechar o carrossel ao clicar fora dele
                overlayElement.addEventListener("click", (event) => {
                    if (event.target === overlayElement) {
                        document.body.removeChild(overlayElement);
                    }
                });
            }
        };

        return (
            <Col sm={12}>
                <Col sm={12}>
                    <button
                        onClick={handleViewImage}
                        className="btn btn-primary btn-sm"
                        style={{ width: "100%" }}
                        type="button"
                    >
                        {t("Visualizar Imagens")}
                    </button>
                </Col>
            </Col>
        );
    };

    const ExibirImagem = ({ imageUrl }: { imageUrl: string }) => {
        const handleViewImage = () => {
            const overlayElement = document.createElement("div");
            overlayElement.style.cssText = `
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.8);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 9999;
        `;

            let imgElement: HTMLImageElement | null =
                document.createElement("img");

            imgElement.onload = () => {
                if (imgElement && imgElement.complete) {
                    overlayElement.appendChild(imgElement);
                } else {
                    showNoImageMessage();
                }
            };

            imgElement.onerror = () => {
                showNoImageMessage();
            };

            const formattedImageUrl = imageUrl && imageUrl.replace(/\\/g, "/");
            const linkConcat = `${linkBackend}${formattedImageUrl}`;
            const linkCompleto = linkConcat.replace(/\/\/public/, "/public");
            imgElement.src = linkCompleto;
            imgElement.alt = "Imagem";
            imgElement.style.cssText = `
        max-height: 80vh;
        max-width: 100%;
      `;

            const showNoImageMessage = () => {
                const noImageMessage = document.createElement("div");
                noImageMessage.textContent = "Nenhuma imagem disponível";
                noImageMessage.style.cssText = `
            color: white;
            font-size: 20px;
          `;

                overlayElement.appendChild(noImageMessage);
            };

            overlayElement.addEventListener("click", () => {
                document.body.removeChild(overlayElement);
            });

            document.body.appendChild(overlayElement);
        };

        return (
            <Col sm={12}>
                <Col sm={12}>
                    <button
                        onClick={handleViewImage}
                        className="btn btn-primary btn-sm"
                        style={{ width: "100%" }}
                        type="button"
                    >
                        {t("Visualizar foto")}
                    </button>
                </Col>
            </Col>
        );
    };

    const handleChangeOficios = (
        index: number,
        field: keyof Oficio,
        value: File | null
    ) => {
        const novosOficios = oficios.map((oficio, i) => {
            if (i === index) {
                return {
                    ...oficio,
                    [field]: value,
                };
            }
            return oficio;
        });

        setOficios(novosOficios);
    };

    interface Option {
        value: string;
        label: string;
    }

    const handleAreasChange = (selectedOptions: any) => {
        const newOptions = (selectedOptions as Option[]).filter(
            (option) =>
                !areasEnvolvidas.some(
                    (existingOption: any) =>
                        existingOption.value === option.value
                )
        );

        setAreasEnvolvidas((prev: any) => [...prev, ...newOptions]);
    };

    useEffect(() => {
        const calculatePrioridade = (prioridade: string, dataEntrada: any) => {
            let diaDataEntrada = new Date(dataEntrada) || new Date();

            switch (prioridade) {
                case "Urgente":
                    diaDataEntrada.setDate(diaDataEntrada.getDate() + 2);
                    break;
                case "Importante":
                    diaDataEntrada.setDate(diaDataEntrada.getDate() + 8);
                    break;
                case "Média":
                    diaDataEntrada.setDate(diaDataEntrada.getDate() + 16);
                    break;
                case "Baixa":
                    diaDataEntrada.setDate(diaDataEntrada.getDate() + 31);
                    break;
                default:
                    if (!isNaN(diaDataEntrada.getTime())) {
                        diaDataEntrada = new Date();
                    } else {
                        diaDataEntrada = new Date();
                    }
                    break;
            }

            let dia = {
                string: diaDataEntrada
                    ? diaDataEntrada.toLocaleDateString()
                    : "",
                date: diaDataEntrada,
            };

            return dia;
        };

        const { string } = calculatePrioridade(prioridadeTema, dataEntrada);

        setPrioridadeData(string);
    }, [prioridadeTema]);

    const optionsAreas = [
        { value: "Operações", label: "Operações" },
        { value: "Projetos", label: "Projetos" },
        { value: "EHS", label: "EHS" },
        { value: "Jurídico", label: "Jurídico" },
        { value: "Tributário", label: "Tributário" },
        {
            value: "Relações com Comunidades",
            label: "Relações com Comunidades",
        },
        { value: "Financeiro", label: "Financeiro" },
        { value: "CSC", label: "CSC" },
        { value: "RH", label: "RH" },
        { value: "Comunicação", label: "Comunicação" },
        { value: "Relações Institucionais", label: "Relações Institucionais" },
        { value: "Relações Governamentais", label: "Relações Governamentais" },
        { value: "Instituto Mosaic", label: "Instituto Mosaic" },
        { value: "Licenciamento", label: "Licenciamento" },

    ];

    const getRangeText = (range: DateRange): string => {
        if (!range?.from) return "";

        return (
            format(range.from, "dd/MM/yyyy", { locale: ptBR }) +
            " à " +
            format(range.to || range.from, "dd/MM/yyyy", { locale: ptBR })
        );
    };

    const handleChangeStakeholderDemandaExterna = (id: any, value: any) => {
        const updatedStakeholders = stakeholder.map((stakeholder: any) =>
            stakeholder.id === id ? { ...stakeholder, value } : stakeholder
        );

        setStakeholder(updatedStakeholders);
    };

    const handleAddStakeholderDemandaExterna = () => {
        const newStakeholder = {
            id: stakeholder.length + 1,
            value: "",
        };
        setStakeholder([...stakeholder, newStakeholder]);
    };

    const handleRemoveStakeholderDemanda = (stake: any) => {
        const updatedStakeholders = stakeholder.filter(
            (stakeholder: any) => stakeholder !== stake
        );
        setStakeholder(updatedStakeholders);
    };

    const adicionarCampoAcao = () => {
        setAcoes([
            ...acoes,
            { acao: "", responsavel: "", status: "", dt_prazo: undefined },
        ]);
    };

    const removerCampoAcao = (index: number) => {
        const novasAcoes = [...acoes];
        novasAcoes.splice(index, 1);
        setAcoes(novasAcoes);
    };

    interface Acao {
        acao: string;
        responsavel: string;
        status: string;
        dt_prazo: Date | undefined;
    }

    const handleChangeAcoes = (
        index: number,
        field: keyof Acao,
        value: string | Date
    ) => {
        const novasAcoes = [...acoes];

        if (field === "dt_prazo") {
            novasAcoes[index][field] =
                typeof value === "string"
                    ? new Date(value + "T00:00:00")
                    : (value as Date);
        } else {
            novasAcoes[index][field] = value as string;
        }

        setAcoes(novasAcoes);
    };

    const handleOpenModalOficios = () => {
        setOpemModalOficios(true);
    };

    const handleCloseModalOficios = () => {
        setOpemModalOficios(false);
    };

    const ExibirPDF = ({ pdfPath }: { pdfPath: string }) => {
        const handleViewPDF = () => {
            const pdfLink = getPdfLink(pdfPath);

            if (pdfLink) {
                window.open(pdfLink, "_blank");
            } else {
                toast.error("Não há PDF disponível.");
            }
        };

        const getPdfLink = (pdfPath: string): string | null => {
            if (pdfPath) {
                const formattedPdf = pdfPath && pdfPath.replace(/\\/g, "/");
                const linkConcat = `${linkBackend}${formattedPdf}`;
                const linkCompleto = linkConcat.replace(
                    /\/\/public/,
                    "/public"
                );

                return linkCompleto;
            } else {
                return null;
            }
        };

        return (
            <Col sm={12}>
                <Col sm={12}>
                    <button
                        onClick={handleViewPDF}
                        className="btn btn-primary btn-sm"
                        style={{ width: "100%" }}
                        type="button"
                    >
                        {t("Visualizar PDF")}
                    </button>
                </Col>
            </Col>
        );
    };

    const handleChangeAcoesEngaja = (
        index: number,
        field: keyof AcaoEngaja,
        value: string | Date
    ) => {
        const novasAcoes = [...acoesEngaja];

        if (field === "dataPrazo") {
            novasAcoes[index][field] =
                typeof value === "string"
                    ? new Date(value + "T00:00:00")
                    : (value as Date);
        } else {
            novasAcoes[index][field] = value as string;
        }

        setAcoesEngaja(novasAcoes);
    };

    const adicionarCampoAcaoEngaja = () => {
        if (acoesEngaja.length >= 4) {
            toast.error(
                "Limite de ações por engajamento atingido, maximo 4 ações"
            );
        } else {
            setAcoesEngaja([
                ...acoesEngaja,
                { acao: "", responsavel: "", status: "", dataPrazo: undefined },
            ]);
        }
    };

    const removerCampoAcaoEngaja = (index: number) => {
        const novasAcoes = [...acoesEngaja];
        novasAcoes.splice(index, 1);
        setAcoesEngaja(novasAcoes);
    };

    const adicionarCampoOficio = () => {
        setOficios([
            ...oficios,
            {
                id: null,
                caminho_oficio_entrada: "",
                caminho_oficio_resposta: "",
            },
        ]);
    };

    const removerCampoOficio = (index: number) => {
        const novosOficios = [...oficios];
        const oficioRemovido = novosOficios[index];
        setOficioParaExclusao((prevState) => [...prevState, oficioRemovido]); // Salva o ofício removido
        novosOficios.splice(index, 1);
        setOficios(novosOficios);
    };

    return (
        <Col sm={12}>
            <div className="grid-dados-relacionamento">
                <Col sm={12}>
                    <Row>
                        <Col sm={9}>
                            {filtrosAtivos.map((filtro, i) => {
                                const filterObj = tiposFiltro.find(
                                    ({ value }: { value: any }) =>
                                        value === filtro
                                );
                                if (!filterObj) return null;

                                const { filter, setFilter, type, opts } =
                                    filterObj;

                                return (
                                    <Col className="filtro-ativo" key={i}>
                                        <Row>
                                            <Col sm={9}>
                                                {type === "date" ? (
                                                    <>
                                                        <button
                                                            onClick={() =>
                                                                setShowPickerData(
                                                                    filtro
                                                                )
                                                            }
                                                            style={{
                                                                border: 0,
                                                                outline: 0,
                                                                backgroundColor:
                                                                    "#0000",
                                                                display:
                                                                    "block",
                                                                width: "100%",
                                                                height: "100%",
                                                                padding: 0,
                                                                margin: 0,
                                                            }}
                                                        >
                                                            <Form.Control
                                                                type="text"
                                                                placeholder={`Filtrar ${filtro}`}
                                                                value={getRangeText(
                                                                    filter as any
                                                                )}
                                                                style={{
                                                                    height: "73%",
                                                                }}
                                                                onFocus={(e) =>
                                                                    e.target.blur()
                                                                }
                                                                readOnly
                                                            />
                                                        </button>
                                                        {showPickerData ===
                                                            filtro && (
                                                            <DateRangeInput
                                                                selected={
                                                                    filter as any
                                                                }
                                                                onChange={
                                                                    setFilter
                                                                }
                                                                onFinish={() =>
                                                                    setShowPickerData(
                                                                        undefined
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    </>
                                                ) : type === "select" ? (
                                                    <Form.Select
                                                        value={
                                                            filter?.toString() ||
                                                            ""
                                                        }
                                                        onChange={(e) => {
                                                            setFilter(
                                                                e.target
                                                                    .value as any
                                                            );
                                                        }}
                                                        style={{
                                                            height: "73%",
                                                        }}
                                                    >
                                                        <option
                                                            label={`Filtrar ${filtro}`}
                                                            value={""}
                                                        />
                                                        {opts?.map((opt, i) => {
                                                            const isString =
                                                                typeof opt ===
                                                                "string";

                                                            return (
                                                                <option
                                                                    key={i}
                                                                    label={
                                                                        isString
                                                                            ? opt
                                                                            : opt.label
                                                                    }
                                                                    value={
                                                                        isString
                                                                            ? opt
                                                                            : opt.value
                                                                    }
                                                                />
                                                            );
                                                        })}
                                                    </Form.Select>
                                                ) : (
                                                    <Form.Control
                                                        type="text"
                                                        placeholder={`Filtrar ${filtro}`}
                                                        value={
                                                            filter?.toString() ||
                                                            ""
                                                        }
                                                        onChange={(e) => {
                                                            setFilter(
                                                                e.target
                                                                    .value as any
                                                            );
                                                        }}
                                                        style={{
                                                            height: "73%",
                                                        }}
                                                    />
                                                )}
                                            </Col>
                                            <Col sm={3}>
                                                <button
                                                    onClick={() => {
                                                        setFilter(undefined);
                                                    }}
                                                    className="botao-filtro mt-2"
                                                >
                                                    {t("Limpar Filtro")}
                                                </button>
                                            </Col>
                                        </Row>
                                    </Col>
                                );
                            })}
                        </Col>
                        <Col sm={3}>
                            <button
                                className="botao-filtro mt-2"
                                onClick={() =>
                                    setOpenModalFiltro(!openModalFiltro)
                                }
                            >
                                {t("Filtros")}
                            </button>
                        </Col>
                    </Row>

                    {openModalFiltro && (
                        <Col sm={12} className="mb-2">
                            <Card>
                                <Card.Body>
                                    <Col
                                        sm={12}
                                        className="justify-content-center"
                                    >
                                        <Row>
                                            {tiposFiltro.map((filtro, i) => (
                                                <Col sm={3} key={i}>
                                                    <button
                                                        className={`filtros-modal ${
                                                            filtrosAtivos?.includes(
                                                                filtro.value
                                                            )
                                                                ? "ativo"
                                                                : ""
                                                        }`}
                                                        onClick={() => {
                                                            handleModalFiltros(
                                                                filtro.value
                                                            );
                                                        }}
                                                    >
                                                        {filtro.label}
                                                    </button>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                </Col>

                <div>
                    {!permissoesUser.demandas_view && (
                        <div>
                            <DataGrid rows={[]} columns={datagridColunas} />
                        </div>
                    )}
                    {permissoesUser.demandas_view && (
                        <DataGrid
                            rows={dadosFiltrados}
                            columns={datagridColunas}
                            onRowClick={handleRowClick}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                        />
                    )}
                </div>
            </div>

            {/* Modal */}
            <Modal
                show={isModalOpen}
                onHide={closeModal}
                className="modal-xl"
                dialogClassName="modal-rollover"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t("Detalhes da Demanda Interna")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedRowData && (
                        <div id="content-grid">
                            <Col sm={12} className="mb-2">
                                <Col sm={12}>
                                    <Col sm={12} className="mt-4 mb-4">
                                        <Row>
                                            <Col sm={4}>
                                                <Form.Label htmlFor="data-entrada">
                                                    {t("Data de entrada")}
                                                </Form.Label>
                                                <Form.Control
                                                    className="formInput"
                                                    type="date"
                                                    value={dataEntrada}
                                                    onChange={(e) => {
                                                        setDataEntrada(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Col>
                                            <Col sm={4}>
                                                <Form.Label>
                                                    {t("Prioridade do Tema")}
                                                </Form.Label>
                                                <Form.Select
                                                    className="formSelect"
                                                    value={prioridadeTema}
                                                    onChange={(event) => {
                                                        setPrioridadeTema(
                                                            event.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value="vazio">
                                                        {t(
                                                            "Selecione Prioridade"
                                                        )}
                                                    </option>
                                                    <option value="Urgente">
                                                        {t("Urgente (1 dia)")}
                                                    </option>
                                                    <option value="Importante">
                                                        {t(
                                                            "Importante (7 dias)"
                                                        )}
                                                    </option>
                                                    <option value="Média">
                                                        {t("Média (15 dias)")}
                                                    </option>
                                                    <option value="Baixa">
                                                        {t("Baixa (30 dias)")}
                                                    </option>
                                                </Form.Select>
                                            </Col>
                                            <Col sm={4}>
                                                <Form.Label htmlFor="previsao-conclusao">
                                                    {t("Previsão de conclusão")}
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="previsao-conclusao"
                                                    placeholder="Previsão de conclusão"
                                                    disabled
                                                    value={prioridadeData}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={12} className="mt-4 mb-4">
                                        <Row>
                                            <Col sm={3}>
                                                <Form.Label>
                                                    {t("Origem")}
                                                </Form.Label>
                                                <Form.Select
                                                    className="formSelect"
                                                    required
                                                    value={origem}
                                                    onChange={(e) => {
                                                        setOrigem(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value={""}>
                                                        {t("Selecione Origem")}
                                                    </option>
                                                    <option value={"Operações"}>
                                                        {t("Operações")}
                                                    </option>
                                                    <option value={"Projetos"}>
                                                        {t("Projetos")}
                                                    </option>
                                                    <option value={"EHS"}>
                                                        {t("EHS")}
                                                    </option>
                                                    <option value={"Juridico"}>
                                                        {t("Juridico")}
                                                    </option>
                                                    <option
                                                        value={"Tributário"}
                                                    >
                                                        {t("Tributário")}
                                                    </option>
                                                    <option
                                                        value={"Financeiro"}
                                                    >
                                                        {t("Financeiro")}
                                                    </option>
                                                    <option value={"CSC"}>
                                                        {t("CSC")}
                                                    </option>
                                                    <option value={"RH"}>
                                                        {t("RH")}
                                                    </option>
                                                    <option
                                                        value={"Comunicação"}
                                                    >
                                                        {t("Comunicação")}
                                                    </option>
                                                    <option
                                                        value={
                                                            "Relações Institucionais"
                                                        }
                                                    >
                                                        {t(
                                                            "Relações Institucionais"
                                                        )}
                                                    </option>
                                                    <option
                                                        value={
                                                            "Instituto Mosaic"
                                                        }
                                                    >
                                                        {t("Instituto Mosaic")}
                                                    </option>
                                                    <option
                                                        value={
                                                            "Relações com Comunidades"
                                                        }
                                                    >
                                                        {t(
                                                            "Relações com Comunidades"
                                                        )}
                                                    </option>
                                                    <option
                                                        value={
                                                            "Relações Governamentais"
                                                        }
                                                    >
                                                        {t(
                                                            "Relações Governamentais"
                                                        )}
                                                    </option>
                                                    <option
                                                        value={"Licenciamento"}
                                                    >
                                                        {t("Licenciamento")}
                                                    </option>
                                                    <option value={"outros"}>
                                                        {t("Outros")}
                                                    </option>
                                                </Form.Select>
                                            </Col>
                                            <Col sm={3}>
                                                <Form.Label>
                                                    {t("Autor Origem")}
                                                </Form.Label>
                                                <Form.Control
                                                    className="formInput"
                                                    type={"text"}
                                                    placeholder="Digite Autor Origem"
                                                    value={autorOrigem}
                                                    onChange={(e) => {
                                                        setAutorOrigem(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Col>
                                            <Col sm={3}>
                                                <Form.Label>
                                                    {t("Esfera")}
                                                </Form.Label>
                                                <Form.Select
                                                    value={esferaDemanda}
                                                    onChange={(e) => {
                                                        setEsferaDemanda(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value={""}>
                                                        {t(
                                                            "Selecione a Esfera"
                                                        )}
                                                    </option>
                                                    <option value={"Federal"}>
                                                        {t("Federal")}
                                                    </option>
                                                    <option value={"Estadual"}>
                                                        {t("Estadual")}
                                                    </option>
                                                    <option value={"Municipal"}>
                                                        {t("Municipal")}
                                                    </option>
                                                </Form.Select>
                                            </Col>
                                            <Col sm={3}>
                                                <Form.Label>
                                                    {t("Estado")}
                                                </Form.Label>
                                                <Form.Select
                                                    className="formSelect"
                                                    required
                                                    value={estado}
                                                    onChange={(e) => {
                                                        setEstado(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value={""}>
                                                        {t(
                                                            "Selecione o estado"
                                                        )}
                                                    </option>
                                                    {listaEstados?.map(
                                                        (estado: any) => {
                                                            return (
                                                                <option
                                                                    value={
                                                                        estado.sigla
                                                                    }
                                                                >
                                                                    {
                                                                        estado.nome
                                                                    }
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {origem === "outros" && (
                                        <Col sm={12} className="mt-4 mb-4">
                                            <Row>
                                                <Col>
                                                    <Form.Control
                                                        className="formInput"
                                                        type={"text"}
                                                        placeholder="Digite Origem"
                                                        required
                                                        value={origemTexto}
                                                        onChange={(e) => {
                                                            setOrigemTexto(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    )}

                                    <Col sm={12} className="mt-4 mb-4">
                                        <Row>
                                            <Col sm={3}>
                                                <Form.Label>
                                                    {t("Municipio")}
                                                </Form.Label>
                                                <Form.Select
                                                    className="formSelect"
                                                    required
                                                    value={municipio}
                                                    onChange={(e) => {
                                                        setMunicipio(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value={""}>
                                                        {t(
                                                            "Selecione o municipio"
                                                        )}
                                                    </option>
                                                    {listaMunicipios[
                                                        estado
                                                    ]?.map((municipio: any) => {
                                                        return (
                                                            <option
                                                                value={
                                                                    municipio
                                                                }
                                                            >
                                                                {municipio}
                                                            </option>
                                                        );
                                                    })}
                                                </Form.Select>
                                            </Col>
                                            <Col sm={3}>
                                                <Form.Label>
                                                    {t(
                                                        "Categoria do(s) stakeholder(s)"
                                                    )}
                                                </Form.Label>
                                                <Form.Select
                                                    value={categoriaStakeholder}
                                                    className="formSelect"
                                                    onChange={(event) => {
                                                        setCategoriaStakeholder(
                                                            event.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value={""}>
                                                        {t(
                                                            "Selecione Categoria"
                                                        )}
                                                    </option>
                                                    <option
                                                        value={
                                                            "Poder Público Municipal - Secretaria"
                                                        }
                                                    >
                                                        {t(
                                                            "Poder Público Municipal - Secretaria"
                                                        )}
                                                    </option>
                                                    <option
                                                        value={
                                                            "Poder Público Municipal - Prefeitura"
                                                        }
                                                    >
                                                        {t(
                                                            "Poder Público Municipal - Prefeitura"
                                                        )}
                                                    </option>
                                                    <option
                                                        value={
                                                            "Poder Público Municipal - Autarquia"
                                                        }
                                                    >
                                                        {t(
                                                            "Poder Público Municipal - Autarquia"
                                                        )}
                                                    </option>
                                                    <option
                                                        value={
                                                            "Poder Público Municipal - Câmara de Vereadores"
                                                        }
                                                    >
                                                        {t(
                                                            "Poder Público Municipal - Câmara de Vereadores"
                                                        )}
                                                    </option>
                                                    <option
                                                        value={
                                                            "Poder Público Estadual"
                                                        }
                                                    >
                                                        {t(
                                                            "Poder Público Estadual"
                                                        )}
                                                    </option>
                                                    <option
                                                        value={
                                                            "Poder Público Federal"
                                                        }
                                                    >
                                                        {t(
                                                            "Poder Público Federal"
                                                        )}
                                                    </option>
                                                    <option
                                                        value={
                                                            "Ministério Público"
                                                        }
                                                    >
                                                        {t(
                                                            "Ministério Público"
                                                        )}
                                                    </option>
                                                    <option
                                                        value={
                                                            "Entidades Setoriais"
                                                        }
                                                    >
                                                        {t(
                                                            "Entidades Setoriais"
                                                        )}
                                                    </option>
                                                    <option
                                                        value={
                                                            "Entidades do Terceiro Setor"
                                                        }
                                                    >
                                                        {t(
                                                            "Entidades do Terceiro Setor"
                                                        )}
                                                    </option>
                                                    <option
                                                        value={"Setor Privado"}
                                                    >
                                                        {t("Setor Privado")}
                                                    </option>
                                                    <option value={"Academia"}>
                                                        {t("Academia")}
                                                    </option>
                                                    <option value={"outros"}>
                                                        {t("Outros")}
                                                    </option>
                                                </Form.Select>
                                            </Col>
                                            {categoriaStakeholder ===
                                                "outros" && (
                                                <Col
                                                    sm={12}
                                                    className="mt-4 mb-4"
                                                >
                                                    <Row>
                                                        <Col>
                                                            <Form.Control
                                                                className="formInput"
                                                                type={"text"}
                                                                placeholder="Digite categoria de stakeholder"
                                                                value={
                                                                    categoriaStakeholderTexto
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setCategoriaStakeholderTexto(
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )}
                                            <Col sm={3}>
                                                <Form.Label>
                                                    Stakeholder(s)
                                                </Form.Label>
                                                {Array.isArray(stakeholder) &&
                                                    stakeholder.map(
                                                        (
                                                            stakeholder: any,
                                                            index: any
                                                        ) => (
                                                            <div
                                                                key={
                                                                    stakeholder.id
                                                                }
                                                                className="mb-2"
                                                            >
                                                                <Row>
                                                                    <Col
                                                                        sm={11}
                                                                    >
                                                                        <Select
                                                                            onChange={(
                                                                                e: any
                                                                            ) => {
                                                                                handleChangeStakeholderDemandaExterna(
                                                                                    stakeholder.id,
                                                                                    e.value
                                                                                );
                                                                            }}
                                                                            options={
                                                                                optionsStakeholders
                                                                            }
                                                                            value={optionsStakeholders.find(
                                                                                (
                                                                                    option: any
                                                                                ) =>
                                                                                    option.value ===
                                                                                    stakeholder.value
                                                                            )}
                                                                            isSearchable={
                                                                                true
                                                                            }
                                                                            placeholder="Selecione Stakeholder"
                                                                        />
                                                                    </Col>
                                                                    <Col sm={1}>
                                                                        {index >
                                                                            0 && (
                                                                            <button
                                                                                type="button"
                                                                                className="button-excluir-stake"
                                                                                onClick={() =>
                                                                                    handleRemoveStakeholderDemanda(
                                                                                        stakeholder
                                                                                    )
                                                                                }
                                                                            >
                                                                                <FaTimes />
                                                                            </button>
                                                                        )}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )
                                                    )}
                                            </Col>
                                            <Col sm={3}>
                                                <Form.Label>
                                                    {t("Add Stakeholder")}
                                                </Form.Label>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    style={{ width: "100%" }}
                                                    onClick={
                                                        handleAddStakeholderDemandaExterna
                                                    }
                                                >
                                                    {t("Adicionar Stakeholder")}
                                                </button>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col sm={12} className="mt-4 mb-4">
                                        <Row>
                                            <Col sm={3}>
                                                <Form.Label>
                                                    {t(
                                                        "Impacto financeiro (potencial)"
                                                    )}
                                                </Form.Label>
                                                <Form.Select
                                                    className="formSelect"
                                                    value={impactoFinanceiro}
                                                    onChange={(e) => {
                                                        setImpactoFinanceiro(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value="">
                                                        {t(
                                                            "Selecione o impacto financeiro"
                                                        )}
                                                    </option>
                                                    <option value="Não identeificado">
                                                        {t("Não identeificado")}
                                                    </option>
                                                    <option value="Em análise">
                                                        {t("Em análise")}
                                                    </option>
                                                    <option value="< US$ 10 M">
                                                        {t("< US$ 10 M")}
                                                    </option>
                                                    <option value="Entre US$ 10M  e US$ 50 M">
                                                        {t(
                                                            "Entre US$ 10M  e US$ 50 M"
                                                        )}
                                                    </option>
                                                    <option value="> US$ 50M">
                                                        {t("> US$ 50M")}
                                                    </option>
                                                </Form.Select>
                                            </Col>

                                            <Col sm={3}>
                                                <Form.Label>
                                                    {t("Categoria do Tema")}
                                                </Form.Label>
                                                <Form.Select
                                                    className="formSelect"
                                                    value={categoriaTema}
                                                    onChange={(event) => {
                                                        setCategoriaTema(
                                                            event.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value="Selecione Categoria">
                                                        {t(
                                                            "Selecione Categoria"
                                                        )}
                                                    </option>
                                                    <option value="Questões Tributárias">
                                                        {t(
                                                            "Questões Tributárias"
                                                        )}
                                                    </option>
                                                    <option value="Questões Ambientais">
                                                        {t(
                                                            "Questões Ambientais"
                                                        )}
                                                    </option>
                                                    <option value="Questões Fundiárias">
                                                        {t(
                                                            "Questões Fundiárias"
                                                        )}
                                                    </option>
                                                    <option value="Licenciamento">
                                                        {t("Licenciamento")}
                                                    </option>
                                                    <option value="Continuidade Operacional">
                                                        {t(
                                                            "Continuidade Operacional"
                                                        )}
                                                    </option>
                                                    <option value="Projetos">
                                                        {t("Projetos")}
                                                    </option>
                                                    <option value="Infraestrutura">
                                                        {t("Infraestrutura")}
                                                    </option>
                                                    <option value="Empregabilidade e Capacitação">
                                                        {t(
                                                            "Empregabilidade e Capacitação"
                                                        )}
                                                    </option>
                                                    <option value="Programa Instituto Mosaic">
                                                        {t(
                                                            "Programa Instituto Mosaic"
                                                        )}
                                                    </option>
                                                    <option value="Relacionamento Institucional">
                                                        {t(
                                                            "Relacionamento Institucional"
                                                        )}
                                                    </option>
                                                    <option value="Doações">
                                                        {t("Doações")}
                                                    </option>
                                                    <option value="Patrocínio">
                                                        {t("Patrocínio")}
                                                    </option>
                                                    <option value="outros">
                                                        {t("Outros")}
                                                    </option>
                                                </Form.Select>
                                            </Col>
                                            {categoriaTema === "outros" && (
                                                <Col
                                                    sm={12}
                                                    className="mt-4 mb-4"
                                                >
                                                    <Row>
                                                        <Col>
                                                            <Form.Control
                                                                className="formInput"
                                                                type={"text"}
                                                                placeholder="Digite a Categoria do Tema"
                                                                value={
                                                                    categoriaTemaTexto
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setCategoriaTemaTexto(
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )}
                                            <Col sm={6}>
                                                <Form.Label>
                                                    {t("Descrição do Tema")}
                                                </Form.Label>
                                                <Form.Control
                                                    className="formInput"
                                                    type={"text"}
                                                    required
                                                    value={descricaoTema}
                                                    onChange={(e) => {
                                                        setDescricaoTema(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col sm={12}>
                                        <Row>
                                            <Col sm={3}>
                                                <Form.Label>
                                                    {t("Criticidade do Tema")}
                                                </Form.Label>
                                                <Form.Select
                                                    className="formSelect"
                                                    value={criticidadeTema}
                                                    onChange={(event) => {
                                                        setCriticidadeTema(
                                                            event.target.value
                                                        );
                                                    }}
                                                >
                                                    <option>
                                                        {t(
                                                            "Selecione Criticidade"
                                                        )}
                                                    </option>
                                                    <option value="Alta">
                                                        {t("Alto")}
                                                    </option>
                                                    <option value="Média">
                                                        {t("Médio")}
                                                    </option>
                                                    <option value="Baixa">
                                                        {t("Baixo")}
                                                    </option>
                                                </Form.Select>
                                            </Col>

                                            <Col sm={3}>
                                                <Form.Label>
                                                    {t("Relevância")}
                                                </Form.Label>
                                                <Form.Select
                                                    value={relevancia}
                                                    className="formSelect"
                                                    onChange={(event) => {
                                                        setRelevancia(
                                                            event.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value="">
                                                        {t(
                                                            "Seleciona Relevância"
                                                        )}
                                                    </option>
                                                    <option value="Favorável">
                                                        {t("Favorável")}
                                                    </option>
                                                    <option value="Requer Atenção">
                                                        {t("Requer Atenção")}
                                                    </option>
                                                    <option value="Neutro">
                                                        {t("Neutro")}
                                                    </option>
                                                    <option value="Não Favorável">
                                                        {t("Não Favorável")}
                                                    </option>
                                                </Form.Select>
                                            </Col>

                                            <Col sm={6}>
                                                <Form.Label>
                                                    {t("Tratativa")}
                                                </Form.Label>
                                                <Form.Control
                                                    className="formInput"
                                                    type={"text"}
                                                    as="textarea"
                                                    value={tratativa}
                                                    onChange={(e) => {
                                                        setTratativa(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col sm={12} className="mt-4 mb-4">
                                        <Row>
                                            <Col sm={3}>
                                                <Form.Label>
                                                    {t("Data de Conclusão")}
                                                </Form.Label>
                                                <Form.Control
                                                    className="formInput"
                                                    type="date"
                                                    value={dataConclusao}
                                                    onChange={(e) => {
                                                        setDataConclusao(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Col>
                                            <Col sm={2}>
                                                <Form.Check
                                                    className="mt-4"
                                                    type="switch"
                                                    id="oficioSwitch"
                                                    label="Demanda atendida?"
                                                    checked={atendida}
                                                    onChange={() =>
                                                        setAtendida(!atendida)
                                                    }
                                                />
                                            </Col>
                                            <Col sm={2} className=" mt-4">
                                                <Form.Check
                                                    className="formSwitch"
                                                    type="switch"
                                                    label={t("Plano de Ação?")}
                                                    checked={planoAcao}
                                                    onChange={(e) => {
                                                        setPlanoAcao(
                                                            e.target.checked
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    {planoAcao && (
                                        <div>
                                            <Col sm={12} className="mt-4 mb-4">
                                                <Row>
                                                    <Col sm={12}>
                                                        <Form.Label>
                                                            {t(
                                                                "Áreas Envolvidas"
                                                            )}
                                                        </Form.Label>
                                                        <Form.Group as={Row}>
                                                            <Col>
                                                                <Row>
                                                                    <Select
                                                                        isMulti
                                                                        options={
                                                                            optionsAreas
                                                                        }
                                                                        value={
                                                                            areasEnvolvidas
                                                                        }
                                                                        onChange={
                                                                            handleAreasChange
                                                                        }
                                                                    />
                                                                </Row>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <div>
                                                <Col
                                                    sm={12}
                                                    className="mt-4 mb-4"
                                                >
                                                    <Row>
                                                        {acoes &&
                                                            acoes.map(
                                                                (
                                                                    acao: any,
                                                                    index: any
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <Col
                                                                            sm={
                                                                                12
                                                                            }
                                                                            className="mt-4 mb-4"
                                                                        >
                                                                            <Row>
                                                                                <Col
                                                                                    sm={
                                                                                        6
                                                                                    }
                                                                                >
                                                                                    <Form.Label>
                                                                                        {t(
                                                                                            `Ação ${
                                                                                                index +
                                                                                                1
                                                                                            }`
                                                                                        )}
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        className="formInput"
                                                                                        type="text"
                                                                                        value={
                                                                                            acao.acao &&
                                                                                            acao.acao
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleChangeAcoes(
                                                                                                index,
                                                                                                "acao",
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </Col>
                                                                                <Col
                                                                                    sm={
                                                                                        6
                                                                                    }
                                                                                >
                                                                                    <Form.Label>
                                                                                        {t(
                                                                                            "Responsável"
                                                                                        )}
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        className="formInput"
                                                                                        type="text"
                                                                                        value={
                                                                                            acao.responsavel
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleChangeAcoes(
                                                                                                index,
                                                                                                "responsavel",
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col
                                                                            sm={
                                                                                12
                                                                            }
                                                                            className="mt-4 mb-4"
                                                                        >
                                                                            <Row>
                                                                                <Col
                                                                                    sm={
                                                                                        6
                                                                                    }
                                                                                >
                                                                                    <Form.Label>
                                                                                        {t(
                                                                                            "Prazo"
                                                                                        )}
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        className="formInput"
                                                                                        type="date"
                                                                                        required
                                                                                        value={
                                                                                            (acao.dt_prazo
                                                                                                ? new Date(
                                                                                                      acao.dt_prazo
                                                                                                  )
                                                                                                      .toISOString()
                                                                                                      .split(
                                                                                                          "T"
                                                                                                      )[0]
                                                                                                : "") ||
                                                                                            ""
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleChangeAcoes(
                                                                                                index,
                                                                                                "dt_prazo",
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </Col>
                                                                                <Col
                                                                                    sm={
                                                                                        6
                                                                                    }
                                                                                >
                                                                                    <Form.Label>
                                                                                        {t(
                                                                                            "Status"
                                                                                        )}
                                                                                    </Form.Label>
                                                                                    <Form.Select
                                                                                        className="formSelect"
                                                                                        value={
                                                                                            acao.status
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleChangeAcoes(
                                                                                                index,
                                                                                                "status",
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <option>
                                                                                            {t(
                                                                                                "Selecionar Status"
                                                                                            )}
                                                                                        </option>
                                                                                        <option>
                                                                                            {t(
                                                                                                "Não Iniciado"
                                                                                            )}
                                                                                        </option>
                                                                                        <option>
                                                                                            {t(
                                                                                                "Em Andamento"
                                                                                            )}
                                                                                        </option>
                                                                                        <option>
                                                                                            {t(
                                                                                                "Concluído"
                                                                                            )}
                                                                                        </option>
                                                                                    </Form.Select>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col
                                                                            sm={
                                                                                12
                                                                            }
                                                                        >
                                                                            {index >=
                                                                                0 && (
                                                                                <Row>
                                                                                    <Col
                                                                                        sm={
                                                                                            12
                                                                                        }
                                                                                    >
                                                                                        <Button
                                                                                            style={{
                                                                                                width: "100%",
                                                                                            }}
                                                                                            variant="danger mt-2 mb-3"
                                                                                            onClick={() =>
                                                                                                removerCampoAcao(
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            Remover
                                                                                            Ação
                                                                                        </Button>
                                                                                    </Col>
                                                                                </Row>
                                                                            )}
                                                                        </Col>
                                                                    </div>
                                                                )
                                                            )}
                                                    </Row>
                                                    <Col sm={12}>
                                                        <button
                                                            className="btn btn-primary"
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            type="button"
                                                            onClick={
                                                                adicionarCampoAcao
                                                            }
                                                        >
                                                            {t(
                                                                "Adicionar Ação"
                                                            )}
                                                        </button>
                                                    </Col>
                                                </Col>
                                            </div>
                                        </div>
                                    )}
                                    <Col sm={12}>
                                        <Row>
                                            <Col sm={4}>
                                                <Form.Label>
                                                    {t("Gerar Engajamento?")}
                                                </Form.Label>
                                                <div>
                                                    <button
                                                        className="btn btn-primary btn-sm"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        type="button"
                                                        onClick={
                                                            handleOpenModalEngajamento
                                                        }
                                                    >
                                                        {t("Gerar Engajamento")}
                                                    </button>
                                                </div>
                                                <Form.Label>
                                                    <strong>
                                                        {engajamentosByDemanda.length +
                                                            (engajementosSalvos.length -
                                                                1)}{" "}
                                                        Engajamento(s) salvos
                                                    </strong>
                                                </Form.Label>
                                            </Col>

                                            <Col sm={4}>
                                                <Form.Label>
                                                    {t(
                                                        "Visualizar Engajamentos"
                                                    )}
                                                </Form.Label>
                                                <div>
                                                    <Button
                                                        type="button"
                                                        className="btn btn-primary btn-sm"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        onClick={
                                                            handleOpenModalGrid
                                                        }
                                                    >
                                                        {t(
                                                            "Visualizar Engajamentos"
                                                        )}
                                                    </Button>
                                                </div>
                                            </Col>

                                            <Col sm={4}>
                                                <Form.Label>
                                                    {t("Visualizar Oficios")}
                                                </Form.Label>
                                                <div>
                                                    <Button
                                                        type="button"
                                                        className="btn btn-primary btn-sm"
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        onClick={
                                                            handleOpenModalOficios
                                                        }
                                                    >
                                                        {t(
                                                            "Visualizar Oficios"
                                                        )}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={11}>
                                        <Row className="mt-2">
                                            <Col
                                                sm={12}
                                                className="cadastroFotos"
                                                style={{ marginLeft: "2%" }}
                                            >
                                                <Form.Label>
                                                    {t(
                                                        "Selecione até 4 documentos/Fotos, pressionando Ctrl e selecionando os aquivos desejados (Use arquivos .pdf, .jpg, jpeg, png, e webp com no máximo 2048 kB)"
                                                    )}
                                                </Form.Label>
                                                <Form.Control
                                                    type="file"
                                                    name="avatar"
                                                    multiple
                                                    className="inputFoto"
                                                    style={{ fontSize: "120%" }}
                                                    onChange={handleFileChange}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={12}>
                                        <Row className="mt-2">
                                            <Col sm={12}>
                                                <ExibirImagemCarrousel
                                                    imageUrls={[
                                                        selectedRowData.foto,
                                                        selectedRowData.foto2,
                                                        selectedRowData.foto3,
                                                        selectedRowData.foto4,
                                                    ]}
                                                ></ExibirImagemCarrousel>
                                            </Col>
                                            {/* <Col sm={6}>
                        <ExibirImagem imageUrl={selectedRowData.foto} />
                      </Col>
                      <Col sm={6}>
                        <ExibirImagem imageUrl={selectedRowData.foto2} />
                      </Col> */}
                                        </Row>
                                    </Col>
                                    <Col sm={12}>
                                        <Row>
                                            {permissoesUser.demandas_edit ? (
                                                <Col sm={4}>
                                                    <button
                                                        className="botao-grid"
                                                        onClick={
                                                            HandleEditDemanda
                                                        }
                                                    >
                                                        {t("Salvar")}
                                                    </button>
                                                </Col>
                                            ) : (
                                                <Col sm={4}>
                                                    <button
                                                        className="botao-grid"
                                                        onClick={() =>
                                                            toast.error(
                                                                "Você não tem permissão para editar uma Demanda!"
                                                            )
                                                        }
                                                    >
                                                        {t("Salvar")}
                                                    </button>
                                                </Col>
                                            )}
                                            <Col sm={4}>
                                                <button
                                                    className="botao-grid"
                                                    type="button"
                                                    onClick={closeModal}
                                                >
                                                    {t("Cancelar")}
                                                </button>
                                            </Col>
                                            <Col sm={4}>
                                                <button
                                                    className="botao-grid"
                                                    type="button"
                                                    onClick={
                                                        handleOpenModalDelete
                                                    }
                                                >
                                                    {t("Excluir")}
                                                </button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Col>
                            </Col>
                        </div>
                    )}
                </Modal.Body>
            </Modal>

            {/* MODAL CONFIRMACÇÂO DE EXCLUSAO */}
            <Modal
                show={showModalDelete}
                onHide={handleCloseModalDelete}
                centered={true}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div id="modalLogin">
                        <form className="form">
                            <p className="titleForgotPassword">
                                {t("Deseja Excluir Demanda?")}
                            </p>

                            <button
                                className="botao-grid"
                                type="button"
                                onClick={deleteDemanda}
                            >
                                {t("Sim")}
                            </button>
                            <button
                                className="botao-grid"
                                type="button"
                                onClick={handleCloseModalDelete}
                            >
                                {t("Não")}
                            </button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

            {/* MODAL ENGAJAMENTO */}
            <Modal
                show={showModalEngajamento}
                onHide={handleCloseModalEngajamento}
                className="modal-lg"
                dialogClassName="modal-rollover"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Cadastrar Engajamento")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id="content-grid">
                        <Form
                            onSubmit={handleSubmitFormEngajamento}
                            encType="multipart/form-data"
                        >
                            <Col sm={12} className="mt-4 mb-4">
                                <Row>
                                    <Col sm={4}>
                                        <Form.Label style={{ fontSize: "85%" }}>
                                            {t("Esfera")}
                                        </Form.Label>
                                        <Form.Select
                                            value={esferaEngaja}
                                            onChange={(e) => {
                                                setEsferaEngaja(e.target.value);
                                            }}
                                        >
                                            <option value={""}>
                                                {t("Selecione a Esfera")}
                                            </option>
                                            <option value={"Federal"}>
                                                {t("Federal")}
                                            </option>
                                            <option value={"Estadual"}>
                                                {t("Estadual")}
                                            </option>
                                            <option value={"Municipal"}>
                                                {t("Municipal")}
                                            </option>
                                        </Form.Select>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Label>{t("Estado")}</Form.Label>
                                        <Form.Select
                                            required
                                            value={estadoEngajamento}
                                            onChange={(e) => {
                                                setEstadoEngajamento(
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            <option value={""}>
                                                {t("Selecione o estado")}
                                            </option>
                                            {listaEstados?.map(
                                                (estado: any, i: any) => {
                                                    return (
                                                        <option
                                                            key={i}
                                                            value={estado.sigla}
                                                        >
                                                            {estado.nome}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </Form.Select>
                                    </Col>

                                    <Col sm={4}>
                                        <Form.Label>
                                            {t("Municipio")}
                                        </Form.Label>
                                        <Form.Select
                                            required
                                            value={municipioEngajamento}
                                            onChange={(e) => {
                                                setMunicipioEngajamento(
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            <option value={""}>
                                                {t("Selecione o municipio")}
                                            </option>
                                            {estado &&
                                                listaMunicipios[estado]?.map(
                                                    (municipio: any) => (
                                                        <option
                                                            key={municipio}
                                                            value={municipio}
                                                        >
                                                            {municipio}
                                                        </option>
                                                    )
                                                )}
                                        </Form.Select>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={12} className="mt-4 mb-4">
                                <Row>
                                    <Col sm={12}>
                                        <Form.Label>{t("Data")}</Form.Label>
                                        <Form.Control
                                            required
                                            className="formInput"
                                            type={"date"}
                                            value={
                                                dataEngajamento
                                                    ? dataEngajamento
                                                          .toISOString()
                                                          .split("T")[0]
                                                    : ""
                                            }
                                            onChange={(e) => {
                                                setDataEngajamento(
                                                    new Date(
                                                        e.target.value +
                                                            " 00:00:00"
                                                    )
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>

                            <Col sm={12}>
                                <Form.Label>
                                    {t("Participantes Mosaic")}
                                </Form.Label>
                                <Col sm={12}>
                                    <Row>
                                        <Col sm={6}>
                                            <Form.Control
                                                placeholder="Participantes Mosaic"
                                                className="formInput"
                                                value={
                                                    participantesMosaicList[0]
                                                        ?.value || ""
                                                }
                                                type="text"
                                                onChange={(event) => {
                                                    const updatedList = [
                                                        ...participantesMosaicList,
                                                    ];
                                                    updatedList[0] = {
                                                        ...updatedList[0],
                                                        value: event.target
                                                            .value,
                                                    };
                                                    setParticipantesMosaicList(
                                                        updatedList
                                                    );
                                                }}
                                            />
                                        </Col>
                                        <Col sm={6}>
                                            <Button
                                                type="button"
                                                className="btn btn-primary"
                                                style={{ width: "100%" }}
                                                onClick={
                                                    handleAddParticipanteMosaic
                                                }
                                            >
                                                {t(
                                                    "Adicionar Participantes Mosaic"
                                                )}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                {participantesMosaicList
                                    .slice(1)
                                    .map((participanteMosaic, index) => (
                                        <div
                                            key={participanteMosaic.id}
                                            className="mt-2 mb-2"
                                        >
                                            <Row className="mt-3">
                                                <Col sm={11}>
                                                    <Form.Control
                                                        placeholder="Participantes Mosaic"
                                                        className="formInput"
                                                        value={
                                                            participanteMosaic.value
                                                        }
                                                        type="text"
                                                        onChange={(event) => {
                                                            const updatedList =
                                                                participantesMosaicList.map(
                                                                    (item) =>
                                                                        item.id ===
                                                                        participanteMosaic.id
                                                                            ? {
                                                                                  ...item,
                                                                                  value: event
                                                                                      .target
                                                                                      .value,
                                                                              }
                                                                            : item
                                                                );
                                                            setParticipantesMosaicList(
                                                                updatedList
                                                            );
                                                        }}
                                                    />
                                                </Col>
                                                <Col sm={1}>
                                                    <button
                                                        type="button"
                                                        className="button-excluir-stake"
                                                        onClick={() =>
                                                            handleRemoveParticipanteMosaic(
                                                                participanteMosaic.id
                                                            )
                                                        }
                                                    >
                                                        <FaTimes />
                                                    </button>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                            </Col>

                            <Col sm={12} className="mt-4">
                                <Form.Label>
                                    {t("Participantes Externos")}
                                </Form.Label>
                                <Col sm={12}>
                                    <Row className="mb-2">
                                        <Col sm={6}>
                                            <Form.Control
                                                placeholder="Participantes Externos"
                                                className="formInput"
                                                value={
                                                    participantesExternosList[0]
                                                        ?.value || ""
                                                }
                                                type="text"
                                                onChange={(event) => {
                                                    const updatedList = [
                                                        ...participantesExternosList,
                                                    ];
                                                    updatedList[0] = {
                                                        ...updatedList[0],
                                                        value: event.target
                                                            .value,
                                                    };
                                                    setParticipantesExternosList(
                                                        updatedList
                                                    );
                                                }}
                                            />
                                        </Col>
                                        <Col sm={6}>
                                            <Button
                                                type="button"
                                                className="btn btn-primary"
                                                style={{ width: "100%" }}
                                                onClick={
                                                    handleAddParticipanteExternos
                                                }
                                            >
                                                {t(
                                                    "Adicionar Participantes Externos"
                                                )}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                {participantesExternosList
                                    .slice(1)
                                    .map((participanteExternos, index) => (
                                        <div
                                            key={participanteExternos.id}
                                            className="mt-2 mb-2"
                                        >
                                            <Row>
                                                <Col sm={11} className="mt-2">
                                                    <Form.Control
                                                        placeholder="Participantes Externos"
                                                        className="formInput"
                                                        value={
                                                            participanteExternos.value
                                                        }
                                                        type="text"
                                                        onChange={(event) => {
                                                            const updatedList =
                                                                participantesExternosList.map(
                                                                    (item) =>
                                                                        item.id ===
                                                                        participanteExternos.id
                                                                            ? {
                                                                                  ...item,
                                                                                  value: event
                                                                                      .target
                                                                                      .value,
                                                                              }
                                                                            : item
                                                                );
                                                            setParticipantesExternosList(
                                                                updatedList
                                                            );
                                                        }}
                                                    />
                                                </Col>
                                                <Col sm={1}>
                                                    <button
                                                        type="button"
                                                        className="button-excluir-stake"
                                                        onClick={() =>
                                                            handleRemoveParticipanteExternos(
                                                                participanteExternos.id
                                                            )
                                                        }
                                                    >
                                                        <FaTimes />
                                                    </button>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                            </Col>

                            {formaDeContato === "Outros" ? (
                                <div>
                                    <Col sm={12} className="mt-4 mb-4">
                                        <Row>
                                            <Col sm={6}>
                                                <Form.Label>
                                                    {t("Forma de engajamento")}
                                                </Form.Label>
                                                <Form.Select
                                                    required
                                                    className="inputSelect"
                                                    onChange={(event) => {
                                                        setFormaDeContato(
                                                            event.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value="">
                                                        {t("Selecione Formato")}
                                                    </option>
                                                    <option value="Virtual">
                                                        {t("Virtual")}
                                                    </option>
                                                    <option value="Presencial">
                                                        {t("Presencial")}
                                                    </option>
                                                    <option value="Ligação Telefônica">
                                                        {t(
                                                            "Ligação Telefônica"
                                                        )}
                                                    </option>
                                                    <option value="Outros">
                                                        {t("Outros")}
                                                    </option>
                                                </Form.Select>
                                            </Col>

                                            <Col sm={6}>
                                                <Form.Label>
                                                    {t("Digite")}
                                                </Form.Label>
                                                <Form.Control
                                                    required
                                                    value={formaDeContatoText}
                                                    className="formInput"
                                                    type="text"
                                                    placeholder="forma de contato"
                                                    onChange={(e) => {
                                                        setFormaDeContatoText(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} className="mt-3">
                                                <Form.Label>
                                                    {t(
                                                        "Selecione foto/documento"
                                                    )}
                                                </Form.Label>
                                                <div
                                                    className="cadastroFotos"
                                                    style={{ padding: "1%" }}
                                                >
                                                    <Form.Control
                                                        type="file"
                                                        name="avatar"
                                                        multiple
                                                        className="inputFoto"
                                                        style={{
                                                            fontSize: "100%",
                                                        }}
                                                        onChange={(e: any) => {
                                                            setFotoEngajamento(
                                                                e.target
                                                                    .files[0]
                                                                    ? e.target
                                                                          .files[0]
                                                                    : null
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </div>
                            ) : (
                                <Col sm={12} className="mt-4 mb-4">
                                    <Row>
                                        <Col sm={6}>
                                            <Form.Label>
                                                {t("Forma de engajamento")}
                                            </Form.Label>
                                            <Form.Select
                                                className="inputSelect"
                                                value={formaDeContato}
                                                onChange={(event) => {
                                                    setFormaDeContato(
                                                        event.target.value
                                                    );
                                                }}
                                            >
                                                <option value="">
                                                    {t("Selecione Formato")}
                                                </option>
                                                <option value="Virtual">
                                                    {t("Virtual")}
                                                </option>
                                                <option value="Presencial">
                                                    {t("Presencial")}
                                                </option>
                                                <option value="Ligação Telefônica">
                                                    {t("Ligação Telefônica")}
                                                </option>
                                                <option value="Outros">
                                                    {t("Outros")}
                                                </option>
                                            </Form.Select>
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Label>
                                                {t("Selecione foto/documento")}
                                            </Form.Label>
                                            <div
                                                className="cadastroFotos"
                                                style={{ padding: "1%" }}
                                            >
                                                <Form.Control
                                                    type="file"
                                                    name="avatarEngajamento"
                                                    multiple
                                                    className="inputFoto"
                                                    style={{ fontSize: "100%" }}
                                                    onChange={(e: any) => {
                                                        setFotoEngajamento(
                                                            e.target.files[0]
                                                                ? e.target
                                                                      .files[0]
                                                                : null
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                            <Col sm={12} className="mb-3">
                                <Row>
                                    <Col sm={8}>
                                        <Form.Label>
                                            {t("Resumo Engajamento")}
                                        </Form.Label>
                                        <Form.Control
                                            required
                                            placeholder="Resumo sobre Engajamento"
                                            className="formInput"
                                            as="textarea"
                                            maxLength={1000}
                                            type={"text"}
                                            value={descricao}
                                            onChange={(e) => {
                                                setDescricao(e.target.value);
                                            }}
                                        />
                                    </Col>

                                    <Col sm={4}>
                                        <Form.Check
                                            className="formSwitch"
                                            type="switch"
                                            style={{
                                                marginTop: "6%",
                                                marginLeft: "5%",
                                            }}
                                            label={t("Plano de Ação?")}
                                            checked={planoAcaoEngaja}
                                            onChange={(e) => {
                                                setPlanoAcaoEngaja(
                                                    e.target.checked
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>

                            {planoAcaoEngaja && (
                                <div>
                                    <Col sm={12} className="mt-4 mb-4">
                                        <Row>
                                            <Col sm={12}>
                                                <Form.Label>
                                                    {t("Áreas Envolvidas")}
                                                </Form.Label>
                                                <Form.Group as={Row}>
                                                    <Col>
                                                        <Row>
                                                            <Select
                                                                isMulti
                                                                options={
                                                                    optionsAreas
                                                                }
                                                                value={
                                                                    areasEnvolvidasEngaja
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setAreasEnvolvidasEngaja(
                                                                        e
                                                                    );
                                                                }}
                                                                placeholder="Selecione"
                                                            />
                                                        </Row>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col sm={12} className="mt-4 mb-4">
                                        <Row>
                                            {acoesEngaja.map((acao, index) => (
                                                <div key={index}>
                                                    <Col
                                                        sm={12}
                                                        className="mt-4 mb-4"
                                                    >
                                                        <Row>
                                                            <Col sm={6}>
                                                                <Form.Label>
                                                                    {t(
                                                                        `Ação ${
                                                                            index +
                                                                            1
                                                                        }`
                                                                    )}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    className="formInput"
                                                                    type="text"
                                                                    value={
                                                                        acao.acao
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleChangeAcoesEngaja(
                                                                            index,
                                                                            "acao",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col sm={6}>
                                                                <Form.Label>
                                                                    {t(
                                                                        "Responsável"
                                                                    )}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    className="formInput"
                                                                    type="text"
                                                                    value={
                                                                        acao.responsavel
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleChangeAcoesEngaja(
                                                                            index,
                                                                            "responsavel",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col
                                                        sm={12}
                                                        className="mt-4 mb-4"
                                                    >
                                                        <Row>
                                                            <Col sm={6}>
                                                                <Form.Label>
                                                                    {t("Prazo")}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    className="formInput"
                                                                    type="date"
                                                                    required
                                                                    value={
                                                                        (acao.dataPrazo
                                                                            ? new Date(
                                                                                  acao.dataPrazo
                                                                              )
                                                                                  .toISOString()
                                                                                  .split(
                                                                                      "T"
                                                                                  )[0]
                                                                            : "") ||
                                                                        ""
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleChangeAcoesEngaja(
                                                                            index,
                                                                            "dataPrazo",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col sm={6}>
                                                                <Form.Label>
                                                                    {t(
                                                                        "Status"
                                                                    )}
                                                                </Form.Label>
                                                                <Form.Select
                                                                    className="formSelect"
                                                                    value={
                                                                        acao.status
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleChangeAcoesEngaja(
                                                                            index,
                                                                            "status",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                >
                                                                    <option>
                                                                        {t(
                                                                            "Selecionar Status"
                                                                        )}
                                                                    </option>
                                                                    <option>
                                                                        {t(
                                                                            "Não Iniciado"
                                                                        )}
                                                                    </option>
                                                                    <option>
                                                                        {t(
                                                                            "Em Andamento"
                                                                        )}
                                                                    </option>
                                                                    <option>
                                                                        {t(
                                                                            "Concluído"
                                                                        )}
                                                                    </option>
                                                                </Form.Select>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col sm={12}>
                                                        {index > 0 && (
                                                            <Row>
                                                                <Col sm={12}>
                                                                    <Button
                                                                        style={{
                                                                            width: "100%",
                                                                        }}
                                                                        variant="danger mt-2 mb-3 btn-sm"
                                                                        onClick={() =>
                                                                            removerCampoAcaoEngaja(
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        Remover
                                                                        Ação
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </Col>
                                                </div>
                                            ))}
                                        </Row>
                                        <Col sm={12}>
                                            <button
                                                className="btn btn-primary btn-sm"
                                                style={{ width: "100%" }}
                                                type="button"
                                                onClick={
                                                    adicionarCampoAcaoEngaja
                                                }
                                            >
                                                {t("Adicionar Ação")}
                                            </button>
                                        </Col>
                                    </Col>
                                </div>
                            )}

                            <Col sm={12}>
                                <div
                                    style={{
                                        display: "flex",
                                        alignContent: "space-",
                                    }}
                                >
                                    <Col sm={6}>
                                        <button
                                            className="btn btn-primary"
                                            style={{ width: "90%" }}
                                            type="submit"
                                        >
                                            {t("Salvar")}
                                        </button>
                                    </Col>
                                    <Col sm={6}>
                                        <button
                                            className="btn btn-danger"
                                            type="reset"
                                            style={{ width: "95%" }}
                                            onClick={
                                                handleCloseModalEngajamento
                                            }
                                        >
                                            {t("Cancelar")}
                                        </button>
                                    </Col>
                                </div>
                            </Col>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>

            {/* MODAL OFICIOS */}
            <Modal
                show={opemModalOficios}
                onHide={handleCloseModalOficios}
                centered={true}
                size="lg"
            >
                <Modal.Header closeButton>
                    <h3>Ofícios</h3>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <h5>Ofícios de Entrada</h5>
                            {oficios.map((oficio: any, i: number) => {
                                const extensao =
                                    oficio?.caminho_oficio_entrada?.slice(-3);
                                return (
                                    <Col className="mt-3" key={i}>
                                        <Form.Label> Oficio {i + 1}</Form.Label>
                                        <Col sm={12} className="cadastroFotos">
                                            <Form.Label>
                                                Selecione foto/PDF para editar
                                                Ofício.
                                            </Form.Label>
                                            <Form.Control
                                                type="file"
                                                name={`oficio_entrada${i}`}
                                                className="inputFoto"
                                                onChange={(e: any) => {
                                                    handleChangeOficios(
                                                        i,
                                                        "caminho_oficio_entrada",
                                                        e.target.files[0]
                                                            ? e.target.files[0]
                                                            : null
                                                    );
                                                }}
                                            />
                                        </Col>
                                        <Col className="mt-2">
                                            {extensao === "pdf" ? (
                                                <ExibirPDF
                                                    pdfPath={
                                                        oficio.caminho_oficio_entrada
                                                    }
                                                />
                                            ) : typeof extensao !== "string" ? (
                                                <p>Arquivo salvo com sucesso</p>
                                            ) : (
                                                <ExibirImagem
                                                    imageUrl={
                                                        oficio.caminho_oficio_entrada
                                                    }
                                                />
                                            )}
                                        </Col>
                                        <Row>
                                            <Col sm={12}>
                                                <Button
                                                    style={{ width: "100%" }}
                                                    variant="danger mt-3 btn-sm"
                                                    onClick={() =>
                                                        removerCampoOficio(i)
                                                    }
                                                >
                                                    Remover ofícios {i + 1}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                );
                            })}
                        </Col>

                        <Col>
                            <h5>Ofícios de Resposta</h5>
                            {oficios.map((oficio: any, i: number) => {
                                const extensao =
                                    oficio?.caminho_oficio_resposta?.slice(-3);
                                return (
                                    <Col className="mt-3" key={i}>
                                        <Form.Label> Oficio {i + 1}</Form.Label>
                                        <Col sm={12} className="cadastroFotos">
                                            <Form.Label>
                                                Selecione foto/PDF para editar
                                                Ofício.
                                            </Form.Label>
                                            <Form.Control
                                                type="file"
                                                name={`oficio_resposta${i}`}
                                                className="inputFoto"
                                                onChange={(e: any) => {
                                                    handleChangeOficios(
                                                        i,
                                                        "caminho_oficio_resposta",
                                                        e.target.files[0]
                                                            ? e.target.files[0]
                                                            : null
                                                    );
                                                }}
                                            />
                                        </Col>
                                        <Col className="mt-2">
                                            {extensao === "pdf" ? (
                                                <ExibirPDF
                                                    pdfPath={
                                                        oficio.caminho_oficio_resposta
                                                    }
                                                />
                                            ) : typeof extensao !== "string" ? (
                                                <p>Arquivo salvo com sucesso</p>
                                            ) : (
                                                <ExibirImagem
                                                    imageUrl={
                                                        oficio.caminho_oficio_resposta
                                                    }
                                                />
                                            )}
                                        </Col>
                                        <Row>
                                            <Col sm={12}>
                                                <Button
                                                    style={{ width: "100%" }}
                                                    variant="danger mt-3 btn-sm"
                                                    onClick={() =>
                                                        removerCampoOficio(i)
                                                    }
                                                >
                                                    Remover ofícios {i + 1}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                );
                            })}
                        </Col>
                        <Col sm={12} className="mt-3">
                            <Button
                                className="btn btn-primary btn-sm"
                                style={{ width: "100%" }}
                                onClick={adicionarCampoOficio}
                            >
                                Adicionar ofício
                            </Button>
                        </Col>
                        <Col sm={12}>
                            <button
                                type="button"
                                className="btn btn-primary mt-4"
                                style={{ width: "100%" }}
                                onClick={handleCloseModalOficios}
                            >
                                Salvar
                            </button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            {/* {MODAL DE VISUALIZAR OS GRIDS} */}
            <Modal
                show={showModalGrid}
                onHide={handleCloseModalGrid}
                centered={true}
                size="lg"
                dialogClassName="modal-rollover"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Visualizar Engajamentos")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-cadastro mb-5">
                    <GridEngajamentoDemanda
                        config={config}
                        idDemanda={idDemanda}
                    />
                </Modal.Body>
            </Modal>
        </Col>
    );
}

export default GridDemandaInternaGov;
