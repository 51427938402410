import React, {
    useEffect,
    useState,
    useContext,
    useMemo,
    useCallback,
} from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { BsCircleFill } from "react-icons/bs";
import { toast } from "react-toastify";
import Api, { linkBackend } from "../../../../config/api";
import { useNavigate } from "react-router-dom";
import { Modal, Col, Row, Form, Card, Button } from "react-bootstrap";
import Select from "react-select";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { FaTimes } from "react-icons/fa";
import DateRangeInput from "../../../../utils/DateRangeInput";
import { DateRange } from "react-day-picker";
import { convertDataProFusoCorreto } from "../../../../utils/datesUtils";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactDOM from "react-dom";
import GridEngajamentoDemandaRg from "../engajamento/gridEngajamentoDemandaRg";

const UNDEFINED_COLOR = process.env.REACT_APP_UNDEFINED_COLOR;
const RED_COLOR = process.env.REACT_APP_RED_COLOR;
const YELLOW_COLOR = process.env.REACT_APP_YELLOW_COLOR;
const GREEN_COLOR = process.env.REACT_APP_GREEN_COLOR;

interface Oficio {
    id: number | null;
    caminho_oficio_entrada: string;
    caminho_oficio_resposta: string;
}

interface OptionType {
    label: string;
    value: string | number;
}

interface AcaoEngaja {
    acao: string;
    responsavel: string;
    status: string;
    dataPrazo: Date | undefined;
}

function GridDemandaExternaGov({ config }: { config: any }) {
    const { t } = useTranslation();
    const navegar = useNavigate();
    const [datagridRows, setDatagridRows] = useState<any[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openModalFiltro, setOpenModalFiltro] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<any | null>(null);
    const [listaEstados, setListaEstados] = useState<any>([]);
    const [listaMunicipios, setListaMunicipios] = useState<any>([]);
    const [listaStakeholders, setListaStakeholders] = useState<any>([]);

    const [idDemanda, setIdDemanda] = useState<any>();

    const [loadingButton, setLoadingButton] = useState<boolean>(false);

    //inpu
    const [descricaoTema, setDescricaoTema] = useState<string>("");
    const [acao, setAcao] = useState<any>([]);
    const [responsavel, setResponsavel] = useState<any>([]);
    const [fotoDemandaExterna, setFotoDemandaExterna] = useState<string>("");
    const [fotoDemandaExterna2, setFotoDemandaExterna2] = useState<string>("");
    // const [participantesExternosEngajamento,setParticipantesExternosEngajamento,] = useState<string>("");
    const [participantesMosaicList, setParticipantesMosaicList] = useState([
        { id: 1, value: "" },
    ]);
    const [participantesExternosList, setParticipantesExternosList] = useState([
        { id: 1, value: "" },
    ]);
    //Select
    const [origem, setOrigem] = useState<string>("");
    const [origemTexto, setOrigemTexto] = useState<string>("");

    const [stakeholder, setStakeholder] = useState<any>([{ id: 1, value: "" }]);
    const [autorOrigem, setAutorOrigem] = useState<string>("");
    const [estado, setEstado] = useState<string>("");
    const [municipio, setMunicipio] = useState<string>("");
    const [formaContato, setFormaContato] = useState<string>("");
    const [formaContatoTexto, setFormaContatoTexto] = useState<string>("");
    const [categoriaTema, setCategoriaTema] = useState<string>("");
    const [categoriaTemaTexto, setCategoriaTemaTexto] = useState<string>("");
    const [criticidadeTema, setCriticidadeTema] = useState<string>("");
    const [impactoFinanceiro, setImpactoFinanceiro] = useState<string>("");
    const [prioridadeTema, setPrioridadeTema] = useState<string>("");
    const [prioridadeData, setPrioridadeData] = useState<string>("");
    const [status, setStatus] = useState<any>([]);
    const [relevancia, setRelevancia] = useState<string>("");
    const [tratativa, setTratativa] = useState<string>("");
    const [statusAtendimento, setStatusAtendimento] = useState<string>("");

    const [oficioBool, setOficioBool] = useState(false);
    const [oficioEntrada, setOficioEntrada] = useState<string>("");
    const [oficioResposta, setOficioResposta] = useState<string>("");
    const [oficioComentario, setOficioComentario] = useState("");

    const [listaOficiosEntrada, setListaOficiosEntrada] = useState([]);
    const [listaOficiosResposta, setListaOficiosResposta] = useState([]);
    const [listaOficiosGeral, setListaOficiosGeral] = useState({
        oficiosEntrada: [],
        oficiosResposta: [],
    });
    const [oficios, setOficios] = useState<Oficio[]>([]);
    const [oficioParaExclusao, setOficioParaExclusao] = useState<Oficio[]>([]);
    //Switch
    const [planoAcao, setPlanoAcao] = useState<boolean>(false);
    const [aplicarRestricao, setAplicarRestricao] = useState<boolean>(false);
    const [atendida, setAtendida] = useState<boolean>(false);
    //Date
    const [dataEntrada, setDataEntrada] = useState<string>("");
    const [dataPrazo, setDataPrazo] = useState<any>([]);
    const [dataConclusao, setDataConclusao] = useState<any>();
    //Check
    const [areasEnvolvidas, setAreasEnvolvidas] = useState<any>([]);
    const [outrosCheckAreas, setOutrosCheckAreas] = useState<any>(false);

    const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
    const [opemModalOficios, setOpemModalOficios] = useState<boolean>(false);
    const [showModalGrid, setShowModalGrid] = useState<boolean>(false);

    const [esferaDemanda, setEsferaDemanda] = useState<string>("");

    const handleCloseModalGrid = () => {
        setShowModalGrid(false);
    };

    const handleOpenModalGrid = () => {
        setShowModalGrid(true);
    };

    // PLANO DE CAAO ENGAJAMENTO
    const [planoAcaoEngaja, setPlanoAcaoEngaja] = useState<boolean>(false);
    const [areasEnvolvidasEngaja, setAreasEnvolvidasEngaja] = useState<any>([]);
    const [acoesEngaja, setAcoesEngaja] = useState<AcaoEngaja[]>([
        { acao: "", responsavel: "", status: "", dataPrazo: undefined },
    ]);
    //   ***---  INPUTS MODAL REGISTRO DE ENGAJAMENTO  ---***
    const [engajementosSalvos, setEngajementosSalvos] = useState([{}]);
    const [AllStakeholders, setAllStakeholder] = useState<[]>([]);
    const [fotoEngajamento, setFotoEngajamento] = useState<any>(null);
    const [assunto, setAssunto] = useState<string>("");
    const [stakeholdersEngajamento, setStakeholdersEngajamento] = useState<any>(
        [{ id: 1, value: "" }]
    );
    const [descricao, setDescricao] = useState<string>("");
    const [dataEngajamento, setDataEngajamento] = useState<Date | null>();
    const [esferaEngaja, setEsferaEngaja] = useState<string>("");
    const [formaDeContato, setFormaDeContato] = useState<string>("");
    const [formaDeContatoText, setFormaDeContatoText] = useState<string>("");
    const [estadoEngajamento, setEstadoEngajamento] = useState<string>("");
    const [municipioEngajamento, setMunicipioEngajamento] =
        useState<string>("");
    const [participantesMosaicEngajamento, setParticipantesMosaicEngajamento] =
        useState<string>("");
    const [
        participantesExternosEngajamento,
        setParticipantesExternosEngajamento,
    ] = useState<string>("");
    const [acaoEngajamento, setAcaoEngajamento] = useState<string>("");
    const [responsaveisEngajamento, setResponsaveisEngajamentoEngajamento] =
        useState<string>("");
    const [planoAcaoEngajamento, setPlanoAcaoEngajamento] = useState<boolean>();

    const [showModalStake, setShowModalStake] = useState<boolean>(false);
    const [showModalEngajamentoCadastro, setShowModalEngajamentoCadastro] =
        useState<boolean>(false);
    const [showModalEngajamentoGrid, setShowModalEngajamentoGrid] =
        useState<boolean>(false);

    const [permissoesUser, setPermissoesUser] = useState({
        administrador: false,
        atendimentos_create: false,
        atendimentos_edit: false,
        atendimentos_view: false,
        demandas_create: false,
        demandas_edit: false,
        demandas_view: false,
        engajamentos_create: false,
        engajamentos_edit: false,
        engajamentos_view: false,
        id: 0,
        id_user: 0,
        noticias_create: false,
        noticias_edit: false,
        noticias_view: false,
        notificacoes_create: false,
        notificacoes_edit: false,
        notificacoes_view: false,
        stakeholders_create: false,
        stakeholders_edit: false,
        stakeholders_view: false,
        usuario_create: false,
        usuario_edit: false,
        visitas_create: false,
        visitas_edit: false,
        visitas_view: false,
    });

    const [engajamentosByDemanda, setEngajamentosByDemanda] = useState([]);

    const handleOpenModalStake = () => {
        setShowModalStake(true);
    };

    const handleOpenModalEngajamentoCadastro = () => {
        setShowModalEngajamentoCadastro(true);
    };

    const handleCloseModalEngajamentoCadastro = () => {
        setShowModalEngajamentoCadastro(false);
    };

    const handleCloseModalDelete = () => {
        setShowModalDelete(false);
    };

    const handleOpenModalDelete = () => {
        setShowModalDelete(true);
    };

    interface MunicipiosPorEstado {
        [estado: string]: string[];
    }
    const municipiosPorEstado: MunicipiosPorEstado = {};

    const [estadosExistentes, municipiosExistentes] = useMemo(() => {
        const estados: string[] = [];
        const municipios: string[] = [];

        datagridRows.forEach((r) => {
            r.estado && !estados.includes(r.estado) && estados.push(r.estado);
            r.municipio &&
                !municipios.includes(r.municipio) &&
                municipios.push(r.municipio);
        });

        return [
            estados.sort((a, b) => a.localeCompare(b)),
            municipios.sort((a, b) => a.localeCompare(b)),
        ];
    }, [datagridRows]);

    datagridRows.forEach((r) => {
        if (r.estado && r.municipio) {
            if (!municipiosPorEstado[r.estado]) {
                municipiosPorEstado[r.estado] = [];
            }

            if (!municipiosPorEstado[r.estado].includes(r.municipio)) {
                municipiosPorEstado[r.estado].push(r.municipio);
            }
        }
    });

    const [filtroEstado, setFiltroEstado] = useState<string>();
    const [filtroOrigem, setFiltroOrigem] = useState<string>();
    const [filtroCategoria, setFiltroCategoria] = useState<string>();
    const [filtroMunicipio, setFiltroMunicipio] = useState<string>();
    const [filtroPrioridade, setFiltroPrioridade] = useState<string>();
    const [filtroCriticidade, setFiltroCriticidade] = useState<string>();
    const [showPickerData, setShowPickerData] = useState<string>();
    const [filtroDataEntrada, setFiltroDataEntrada] = useState<DateRange>();
    const [filtroDataConclusao, setFiltroDataConclusao] = useState<DateRange>();

    const municipiosFiltrados = useMemo(() => {
        return filtroEstado
            ? municipiosPorEstado[filtroEstado] || []
            : municipiosExistentes;
    }, [filtroEstado, municipiosPorEstado, municipiosExistentes]);

    const tiposFiltro = [
        {
            value: "Data de Entrada",
            label: t("Data de Entrada"),
            type: "date",
            filter: filtroDataEntrada,
            setFilter: setFiltroDataEntrada,
        },
        {
            value: "Data de Conclusão",
            label: t("Data de Conclusão"),
            type: "date",
            filter: filtroDataConclusao,
            setFilter: setFiltroDataConclusao,
        },
        {
            value: "Origem",
            label: t("Origem"),
            type: "string",
            filter: filtroOrigem,
            setFilter: setFiltroOrigem,
        },
        {
            value: "Categoria",
            label: t("Categoria"),
            type: "string",
            filter: filtroCategoria,
            setFilter: setFiltroCategoria,
        },
        {
            value: "Criticidade",
            label: t("Criticidade"),
            type: "select",
            opts: [
                { label: t("Alta"), value: "Alta" },
                { label: t("Média"), value: "Média" },
                { label: t("Baixa"), value: "Baixa" },
            ],
            filter: filtroCriticidade,
            setFilter: setFiltroCriticidade,
        },
        {
            value: "Estado",
            label: t("Estado"),
            type: "select",
            opts: estadosExistentes,
            filter: filtroEstado,
            setFilter: setFiltroEstado,
        },
        {
            value: "Municipio",
            label: t("Municipio"),
            type: "select",
            opts: municipiosFiltrados,
            filter: filtroMunicipio,
            setFilter: setFiltroMunicipio,
        },
        // {
        //   value: "Prioridade",
        //   label: t("Prioridade"),
        //   type: "select",
        //   opts: [
        //     { label: t("Urgente"), value: "Urgente" },
        //     { label: t("Importante"), value: "Importante" },
        //     { label: t("Média"), value: "Média" },
        //     { label: t("Baixa"), value: "Baixa" },
        //   ],
        //   filter: filtroPrioridade,
        //   setFilter: setFiltroPrioridade,
        // },
    ];

    const [filtrosAtivos, setFiltroAtivo] = useState<string[]>([]);

    const dadosFiltrados = useMemo(
        () =>
            datagridRows.filter(
                (v) =>
                    (!filtroOrigem ||
                        v?.origem
                            ?.toLowerCase()
                            .includes(filtroOrigem?.toLowerCase())) &&
                    (!filtroCategoria ||
                        v.categoria_tema
                            ?.toLowerCase()
                            .includes(filtroCategoria?.toLowerCase())) &&
                    (!filtroCriticidade ||
                        v.criticidade_tema
                            ?.toLowerCase()
                            .includes(filtroCriticidade?.toLowerCase())) &&
                    (!filtroEstado ||
                        v.estado
                            ?.toLowerCase()
                            .includes(filtroEstado?.toLowerCase())) &&
                    (!filtroMunicipio ||
                        v.municipio
                            ?.toLowerCase()
                            .includes(filtroMunicipio?.toLowerCase())) &&
                    (!filtroPrioridade ||
                        v.prioridade_tema
                            ?.toLowerCase()
                            .includes(filtroPrioridade?.toLowerCase())) &&
                    (!filtroDataEntrada ||
                        (v.dt_entrada >=
                            (filtroDataEntrada.from || new Date()) &&
                            v.dt_entrada <=
                                (filtroDataEntrada.to
                                    ? filtroDataEntrada.to
                                    : filtroDataEntrada.from || new Date()))) &&
                    (!filtroDataConclusao ||
                        (v.dt_conclusao >=
                            (filtroDataConclusao.from || new Date()) &&
                            v.dt_conclusao <=
                                (filtroDataConclusao.to
                                    ? filtroDataConclusao.to
                                    : filtroDataConclusao.from || new Date())))
            ),
        [
            datagridRows,
            filtroOrigem,
            filtroCategoria,
            filtroCriticidade,
            filtroEstado,
            filtroMunicipio,
            filtroPrioridade,
            filtroDataEntrada,
            filtroDataConclusao,
        ]
    );

    const [acoes, setAcoes] = useState<any>([]);

    const getAPIEngajamentosByDemanda = async (id: number) => {
        const engajamentos = await Api.getEngajamentosRgByDemanda(id);
        setEngajamentosByDemanda(engajamentos);
    };

    useEffect(() => {
        if (!selectedRowData) return;

        setIdDemanda(selectedRowData.id || 0);
        setDescricaoTema(selectedRowData.descricao_tema || "");
        setAcoes(selectedRowData.acoes || []);
        setAcao(selectedRowData.acao || "");
        setResponsavel(selectedRowData.responsavel || "");
        setFotoDemandaExterna(selectedRowData.foto || "");
        setFotoDemandaExterna2(selectedRowData.foto2 || "");
        setEsferaDemanda(selectedRowData.esfera || "");

        const getOficiosId = async () => {
            const oficios = await Api.getOficiosRgById(
                selectedRowData.id,
                "externa"
            );
            setOficios(oficios);
        };
        getOficiosId();

        setOficioEntrada(selectedRowData.oficio_entrada || "");
        setOficioResposta(selectedRowData.oficio_resposta || "");

        setOrigem(selectedRowData.origem || "");
        setOrigemTexto(selectedRowData.origem_texto || "");

        const stakeMapeadosComId = selectedRowData.stakeholder.map(
            (stringValue: any, index: any) => ({
                id: index + 1,
                value: stringValue,
            })
        );

        setAutorOrigem(selectedRowData.autor_origem);

        setStakeholder(stakeMapeadosComId || "");
        setEstado(selectedRowData.estado || "");
        setMunicipio(selectedRowData.municipio || "");
        setFormaContato(selectedRowData.forma_contato || "");
        setFormaContatoTexto(selectedRowData.forma_contato || "");
        setCategoriaTema(selectedRowData.categoria_tema || "");
        setCriticidadeTema(selectedRowData.criticidade_tema || "");
        setImpactoFinanceiro(selectedRowData.impacto_financeiro || "");
        setPrioridadeTema(selectedRowData.prioridade_tema || "");
        setPrioridadeData(
            formatDatePrevisao(selectedRowData.dt_previsao || "")
        );
        setStatus(selectedRowData.status || "");
        setPlanoAcao(selectedRowData.plano_acao || "");
        setDataEntrada(formatDate(selectedRowData.dt_entrada));
        setDataPrazo(selectedRowData.dt_prazo);
        setDataConclusao(
            selectedRowData.dt_conclusao
                ? formatDate(selectedRowData.dt_conclusao)
                : null
        );
        setOficioBool(
            selectedRowData.oficio_entrada
                ? true
                : false || selectedRowData.oficio_resposta
                ? true
                : false
        );
        setAreasEnvolvidas(selectedRowData.areas_envolvidas || "");
        setRelevancia(selectedRowData.relevancia || "");
        setTratativa(selectedRowData.tratativa || "");
        setStatusAtendimento(selectedRowData.status_atendimento || "");
        setAtendida(selectedRowData.atendida || "");
        getAPIEngajamentosByDemanda(selectedRowData.id);
    }, [selectedRowData]);

    useEffect(() => {
        const permissionsUsuario = sessionStorage.getItem("permissoesUsuario");
        if (permissionsUsuario) {
            setPermissoesUser(JSON.parse(permissionsUsuario));
        }
    }, []);

    const handleRowClick = (params: any) => {
        setSelectedRowData(params.row);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const datagridColunas: readonly GridColDef<any>[] = [
        {
            field: "descricao_tema",
            headerName: t("Tema") as string,
            type: "string",
            width: 200,
        },
        {
            field: "dt_entrada",
            headerName: t("Data de Entrada") as string,
            type: "date",
            width: 150,
            valueGetter: ({ row }: { row: any }) => {
                if (!row.dt_entrada) {
                    return null;
                }
                return new Date(row.dt_entrada);
            },
            renderCell: (params: GridRenderCellParams<any, any, any, any>) => {
                return params.value && params.value instanceof Date
                    ? format(params.value, "dd/MM/yyyy", { locale: ptBR })
                    : "";
            },
        },
        {
            field: "dt_conclusao",
            headerName: t("Data de Conclusão") as string,
            type: "date",
            width: 150,
            valueGetter: ({ row }: { row: any }) => {
                if (!row.dt_conclusao) {
                    return null;
                }
                return new Date(row.dt_conclusao);
            },
            renderCell: (params: GridRenderCellParams<any, any, any, any>) => {
                return params.value && params.value instanceof Date
                    ? format(params.value, "dd/MM/yyyy", { locale: ptBR })
                    : "";
            },
        },
        {
            field: "origem",
            headerName: t("Origem") as string,
            type: "string",
            width: 200,
        },
        {
            field: "stakeholder",
            headerName: t("Stakeholder") as string,
            type: "string",
            width: 150,
        },
        {
            field: "estado",
            headerName: t("header_Estado") as string,
            type: "select",
            width: 120,
        },
        {
            field: "municipio",
            headerName: t("header_Municipio") as string,
            type: "string",
            width: 120,
        },
        // {
        //   field: "forma_contato",
        //   headerName: t("Contato") as string,
        //   type: "string",
        //   width: 150,
        // },
        {
            field: "categoria_tema",
            headerName: t("Categoria") as string,
            type: "string",
            width: 150,
        },
        {
            field: "prioridade_tema",
            headerName: t("Prioridade") as string,
            type: "string",
            width: 150,
        },
        {
            field: "criticidade_tema",
            headerName: t("header_Criticidade") as string,
            type: "select",
            width: 150,
            renderCell: (params: GridRenderCellParams<any, any, any, any>) => {
                const icon =
                    params.value === t("Alta")
                        ? RED_COLOR
                        : params.value === t("Média")
                        ? YELLOW_COLOR
                        : params.value === t("Baixa")
                        ? GREEN_COLOR
                        : UNDEFINED_COLOR;

                return (
                    <span>
                        <BsCircleFill color={icon} /> {params.value}
                    </span>
                );
            },
        },
        {
            field: "relevancia",
            headerName: "Relevância" as string,
            type: "text",
            width: 150,
        },
        {
            field: "impacto_financeiro",
            headerName: "Imapacto financeiro" as string,
            type: "text",
            width: 150,
        },
    ];

    interface AcaoObjeto {
        acao: string;
        responsavel: string;
        status: string;
        dt_prazo: string;
    }

    interface Demanda {
        id: number;
        areas_envolvidas: string;
        stakeholder: string;
        acao: string;
        responsavel: string;
        status: string;
        dt_prazo: string;
        dt_entrada: string | null;
        dt_resposta: string | null;
        dt_conclusao: string | null;
        atendida: boolean;
        [key: string]: any; // Para lidar com quaisquer outras propriedades dinâmicas
    }
    interface DataDemandaOficios {
        demandas: Demanda[];
        oficios: Oficio[];
    }

    const updateDatagrid = useCallback(() => {
        if (!config || !navegar || !t) return;

        Api.GetDemandaRgExterna(config)
            .then((data: DataDemandaOficios) => {
                const mappedData = data.demandas.map((d, i) => {
                    let areasEnvolvidasString = d.areas_envolvidas.replace(
                        /\\/g,
                        ""
                    );
                    areasEnvolvidasString = areasEnvolvidasString.replace(
                        /^"|"$/g,
                        ""
                    );
                    const areasEnvolvidasOriginal =
                        areasEnvolvidasString &&
                        JSON.parse(areasEnvolvidasString);
                    const areasEnvolvidasSimples =
                        areasEnvolvidasOriginal &&
                        areasEnvolvidasOriginal.flat();
                    let valuesAreas =
                        areasEnvolvidasSimples &&
                        areasEnvolvidasSimples.map((area: any) => {
                            return {
                                value: area,
                                label: area,
                            };
                        });
                    const StakeholdersArray = JSON.parse(d.stakeholder);

                    const criarArrayDeObjetos = (
                        acoes: string[],
                        responsaveis: string[],
                        status: string[],
                        dt_prazo: string[]
                    ): AcaoObjeto[] => {
                        const arrayDeObjetos: AcaoObjeto[] = [];
                        for (let i = 0; i < acoes.length; i++) {
                            const objeto: AcaoObjeto = {
                                acao: acoes[i],
                                responsavel: responsaveis[i],
                                status: status[i],
                                dt_prazo: dt_prazo[i],
                            };
                            arrayDeObjetos.push(objeto);
                        }
                        return arrayDeObjetos;
                    };

                    const arrayDeObjetos = criarArrayDeObjetos(
                        JSON.parse(d.acao),
                        JSON.parse(d.responsavel),
                        JSON.parse(d.status),
                        JSON.parse(d.dt_prazo)
                    );

                    const dataEntradaAjustada = d.dt_entrada
                        ? convertDataProFusoCorreto(d.dt_entrada)
                        : null;
                    const dataRespostaAjustada = d.dt_resposta
                        ? convertDataProFusoCorreto(d.dt_resposta)
                        : null;
                    const dataConclusaoAjustada = d.dt_conclusao
                        ? convertDataProFusoCorreto(d.dt_conclusao)
                        : null;

                    return {
                        ...d,
                        arrayId: i + 1,
                        dt_entrada: dataEntradaAjustada,
                        dt_prazo: d.dt_prazo ? JSON.parse(d.dt_prazo) : [],
                        dt_previsao: d.dt_previsao ? d.dt_previsao : null,
                        dt_resposta: d.dt_resposta
                            ? new Date(d.dt_resposta)
                            : null,
                        dt_conclusao: dataConclusaoAjustada,
                        acoes: arrayDeObjetos && arrayDeObjetos,
                        acao: d.acao ? JSON.parse(d.acao) : [],
                        stakeholder: JSON.parse(d.stakeholder)
                            ? StakeholdersArray
                            : [],
                        responsavel: d.responsavel
                            ? JSON.parse(d.responsavel)
                            : [],
                        areas_envolvidas: valuesAreas || [],
                        status: d.status ? JSON.parse(d.status) : [],
                        atendida: d.atendida,
                    };
                });

                const sortedData = mappedData.sort((a, b) => b.id - a.id);
                setDatagridRows(sortedData);
            })
            .catch((err: any) => {
                if (typeof err === "string") {
                    toast.error(t(err));
                    navegar("/login");
                    return;
                }
                toast.error(t("Houve um erro ao carregar as informações"));
            });
    }, [config, navegar, t]);

    const updateLocales = useCallback(() => {
        Api.GetLocales().then((data) => {
            setListaEstados(data.localidades.estados);
            setListaMunicipios(data.localidades.municipios);
        });

        Api.GetStakeholders(config).then((data) => {
            setListaStakeholders(data);
        });
    }, []);

    useEffect(updateDatagrid, [updateDatagrid]);
    useEffect(updateLocales, [updateLocales]);

    const handleModalFiltros = (filtro: string) => {
        setFiltroAtivo((filtros) => {
            const newFiltros = [...filtros];
            const fIndex = newFiltros.findIndex((f) => f === filtro);
            if (fIndex >= 0) {
                newFiltros.splice(fIndex, 1);
                tiposFiltro
                    .find((f) => f.value === filtro)
                    ?.setFilter(undefined);
            } else {
                newFiltros.push(filtro);
            }
            return newFiltros;
        });
        setOpenModalFiltro(true);
    };

    function formatDate(dateString: string | Date) {
        const dateOri = new Date(dateString);

        const date = new Date(
            dateOri.setHours(
                dateOri.getHours() + dateOri.getTimezoneOffset() / 60
            )
        );
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear().toString().padStart(4, "0");

        return `${year}-${month}-${day}`;
    }

    function formatDatePrevisao(dateString: string | Date) {
        const date = new Date(dateString);
        const day = (date.getDate() + 1).toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear().toString().padStart(4, "0");
        return `${day}/${month}/${year}`;
    }

    const handleAddParticipanteMosaic = () => {
        const newParticipanteMosaic = {
            id: participantesMosaicList.length + 1,
            value: participantesMosaicEngajamento,
        };
        setParticipantesMosaicList([
            ...participantesMosaicList,
            newParticipanteMosaic,
        ]);
        setParticipantesMosaicEngajamento("");
    };

    const handleRemoveParticipanteMosaic = (id: number) => {
        const updatedParticipantesMosaic = participantesMosaicList.filter(
            (participanteMosaic) => participanteMosaic.id !== id
        );
        setParticipantesMosaicList(updatedParticipantesMosaic);
    };

    const handleAddParticipanteExternos = () => {
        const newParticipanteExternos = {
            id: participantesExternosList.length + 1,
            value: participantesExternosEngajamento,
        };
        setParticipantesExternosList([
            ...participantesExternosList,
            newParticipanteExternos,
        ]);
        setParticipantesExternosEngajamento("");
    };

    const handleRemoveParticipanteExternos = (id: number) => {
        const updatedParticipantesExternos = participantesExternosList.filter(
            (participanteExternos) => participanteExternos.id !== id
        );
        setParticipantesExternosList(updatedParticipantesExternos);
    };

    const HandleEditDemanda = async () => {
        const formData = new FormData();

        const valueAreas = areasEnvolvidas.map((area: any) => {
            return area.value;
        });

        if (fotoDemandaExterna !== null) {
            formData.append("avatar", fotoDemandaExterna[0]);
            formData.append("avatar2", fotoDemandaExterna[1]);
            formData.append("avatar3", fotoDemandaExterna[2]);
            formData.append("avatar4", fotoDemandaExterna[3]);
        }
        if (fotoEngajamento !== null) {
            formData.append("avatarEngajamento", fotoEngajamento);
        }
        formData.append("id", idDemanda);

        formData.append("idOficio", idDemanda);
        formData.append("dataEntrada", String(dataEntrada));

        formData.append("dataPrevisao", prioridadeData);
        formData.append("origem", origem);

        const stakeholdersValues = stakeholder.map(
            ({ value }: { value: any }) => {
                return value;
            }
        );
        stakeholder
            ? formData.append(
                  "stakeholderDemandaExtena",
                  JSON.stringify(stakeholdersValues)
              )
            : formData.append("stakeholderDemandaExtena", "");

        const acoesDB = acoes.map((acao: any) => {
            return acao.acao;
        });
        const responsaveisDB = acoes.map((acao: any) => {
            return acao.responsavel;
        });
        const prazosDB = acoes.map((acao: any) => {
            const dt = removerHorarioDaData(acao.dt_prazo);
            return dt;
        });
        const statusDB = acoes.map((acao: any) => {
            return acao.status;
        });
        function removerHorarioDaData(data: any) {
            const dataObj = new Date(data);
            const dataFormatada = dataObj.toISOString().split("T")[0];
            return dataFormatada;
        }

        formData.append("estadoDemandaExtena", estado);
        formData.append("municipioDemandaExtena", municipio);
        formData.append("formaContato", formaContato);
        formData.append("categoriaTema", categoriaTema);
        formData.append("criticidadeTema", criticidadeTema);
        formData.append("impactoFinanceiro", impactoFinanceiro);
        formData.append("prioridadeTemaString", prioridadeTema);
        formData.append("esfera", esferaDemanda);

        formData.append("descricaoTema", descricaoTema);
        formData.append("planoAcao", String(planoAcao));
        formData.append("areasEnvolvidas", JSON.stringify(valueAreas));
        formData.append("acoes", JSON.stringify(acoesDB));
        formData.append("responsaveis", JSON.stringify(responsaveisDB));
        formData.append("prazos", JSON.stringify(prazosDB));
        formData.append("status", JSON.stringify(statusDB));
        formData.append("atendida", String(atendida));

        formData.append("autorOrigem", autorOrigem);

        if (dataConclusao)
            formData.append("dataConclusao", String(dataConclusao));
        formData.append("tratativa", tratativa);
        formData.append(
            "statusAtendimento",
            typeof dataConclusao === "object" ? "Atendida" : "Em andamento"
        );
        formData.append("comentario", "");
        formData.append("engajamentos", JSON.stringify(engajementosSalvos));

        const idsOficiosExclusao = oficioParaExclusao.map(
            (oficio) => oficio.id
        );
        formData.append(
            "idsOficiosExclusao",
            JSON.stringify(idsOficiosExclusao)
        );

        oficios.forEach((oficio, index) => {
            if (
                oficio.caminho_oficio_entrada &&
                typeof oficio.caminho_oficio_entrada === "object"
            ) {
                formData.append(
                    `oficio_entrada${index + 1}`,
                    oficio.caminho_oficio_entrada
                );
            } else {
                formData.append(
                    `oficioEntradaString${index + 1}`,
                    oficio.caminho_oficio_entrada
                );
            }

            if (
                oficio.caminho_oficio_resposta &&
                typeof oficio.caminho_oficio_resposta === "object"
            ) {
                formData.append(
                    `oficio_resposta${index + 1}`,
                    oficio.caminho_oficio_resposta
                );
            } else {
                formData.append(
                    `oficioRespostaString${index + 1}`,
                    oficio.caminho_oficio_resposta
                );
            }

            formData.append(`oficio_id${index + 1}`, String(oficio.id));
        });

        await Api.PutDemandaRgExterna(formData, config)
            .then(() => {
                toast.success("Demanda Editada com sucesso!");
                closeModal();
                updateDatagrid();
            })
            .catch((err) => {
                toast.error("Erro ao editar demanda!");
            });

        setEngajementosSalvos([{}]);
        setFotoEngajamento(null);
        updateDatagrid();
    };

    const handleSubmitFormEngajamento = async (event: any) => {
        event.preventDefault();

        const participantesMosaicStringfy = JSON.stringify(
            participantesMosaicList.map(({ value }: { value: any }) => {
                return value;
            })
        );

        const participantesExternosStringfy = JSON.stringify(
            participantesExternosList.map(({ value }: { value: any }) => {
                return value;
            })
        );

        const obj = {
            estado: estado,
            municipio: municipio,
            data: dataEngajamento,
            stakeholder: stakeholdersEngajamento.map(
                (stake: any) => stake.value
            ),
            tema: assunto,
            contato:
                formaDeContato === "Outros"
                    ? formaDeContatoText
                    : formaDeContato,
            participantesMosaic: participantesMosaicStringfy,
            participantesExternos: participantesExternosStringfy,
            descricao: descricao,
            planoAcao: planoAcaoEngaja,
            areasEnvolvidas: areasEnvolvidasEngaja,
            acoes: acoesEngaja,
            esfera: esferaEngaja,
        };

        setEngajementosSalvos((prevEngajementos) => {
            const novoArray = [...prevEngajementos, obj];
            return novoArray;
        });

        toast.success(t("Engajamento salvo com sucesso!"));

        const clearInputs = () => {
            setEstadoEngajamento("");
            setEsferaEngaja("");
            setMunicipioEngajamento("");
            setStakeholdersEngajamento([{ id: 1, value: "" }]);
            setDataEngajamento(null);
            setFormaDeContato("");
            setFormaDeContatoText("");
            setParticipantesMosaicEngajamento("");
            setParticipantesExternosEngajamento("");
            setDescricao("");
            setAssunto("");
            setParticipantesExternosList([]);
            setParticipantesMosaicList([]);
            setPlanoAcaoEngaja(false);
            setAreasEnvolvidasEngaja([]);
            setAcoesEngaja([
                { acao: "", responsavel: "", status: "", dataPrazo: undefined },
            ]);
        };

        clearInputs();
    };

    const deleteDemanda = async () => {
        await Api.deleteDemandaRgExterna(selectedRowData.id)
            .then((res) => {
                toast.success("Demanda deletada com sucesso!");
                handleCloseModalDelete();
                closeModal();
                updateDatagrid();
            })
            .catch((err) => {
                toast.error("Erro ao tantar deletar Demanda!");
            });
    };

    const generateOptionsStakeholders = useCallback(() => {
        if (Array.isArray(AllStakeholders)) {
            return [
                { value: "", label: "Selecione Stakeholder..." },
                ...AllStakeholders.filter((stakeholder: any) => true).map(
                    ({
                        stakeholder,
                        estado,
                        municipio,
                    }: {
                        stakeholder: any;
                        estado: any;
                        municipio: any;
                    }) => ({
                        value: stakeholder,
                        label: `${stakeholder} - ${municipio}`,
                    })
                ),
            ];
        }

        return [];
    }, [AllStakeholders, municipio]);

    const optionsStakeholders = useMemo(
        () => generateOptionsStakeholders(),
        [generateOptionsStakeholders]
    );

    const optionsAreas = [
        { value: "Operações", label: "Operações" },
        { value: "Projetos", label: "Projetos" },
        { value: "EHS", label: "EHS" },
        { value: "Jurídico", label: "Jurídico" },
        { value: "Tributário", label: "Tributário" },
        {
            value: "Relações com Comunidades",
            label: "Relações com Comunidades",
        },
        { value: "Financeiro", label: "Financeiro" },
        { value: "CSC", label: "CSC" },
        { value: "RH", label: "RH" },
        { value: "Comunicação", label: "Comunicação" },
        { value: "Relações Institucionais", label: "Relações Institucionais" },
        { value: "Relações Governamentais", label: "Relações Governamentais" },
        { value: "Instituto Mosaic", label: "Instituto Mosaic" },
        { value: "Licenciamento", label: "Licenciamento" },

    ];

    useEffect(() => {
        const atualizarStakeholder = async () => {
            const stakeAtualizado = await Api.GetStakeholders(config);
            setAllStakeholder(stakeAtualizado);
        };
        atualizarStakeholder();
    }, []);

    const ExibirImagemCarrousel = ({ imageUrls }: { imageUrls: string[] }) => {
        const handleViewImage = () => {
            const overlayElement = document.createElement("div");
            overlayElement.style.cssText = `
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
      `;

            const carouselElement = document.createElement("div");
            carouselElement.style.cssText = `
        width: 80%;
        height: 80%;
      `;
            overlayElement.appendChild(carouselElement);

            const closeButton = document.createElement("button");
            closeButton.textContent = "Fechar";
            closeButton.style.cssText = `
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: transparent;
        border: none;
        color: white;
        font-size: 16px;
        cursor: pointer;
      `;
            closeButton.addEventListener("click", () => {
                document.body.removeChild(overlayElement);
            });
            overlayElement.appendChild(closeButton);

            const showNoImageMessage = () => {
                const noImageMessage = document.createElement("div");
                noImageMessage.textContent = "Nenhuma imagem disponível";
                noImageMessage.style.cssText = `
          color: white;
          font-size: 20px;
        `;
                overlayElement.appendChild(noImageMessage);
            };

            const CustomPrevArrow = (props: any) => {
                const { className, onClick } = props;
                return (
                    <div className={className} onClick={onClick}>
                        Previous
                    </div>
                );
            };

            const CustomNextArrow = (props: any) => {
                const { className, onClick } = props;
                return (
                    <div className={className} onClick={onClick}>
                        Next
                    </div>
                );
            };

            if (imageUrls.length === 0) {
                showNoImageMessage();
            } else {
                ReactDOM.render(
                    <Slider
                        dots={true}
                        slidesToShow={1}
                        slidesToScroll={1}
                        infinite={true}
                        speed={1000}
                        prevArrow={<CustomPrevArrow />}
                        nextArrow={<CustomNextArrow />}
                    >
                        {imageUrls.map((fileUrl, index) => {
                            const formattedFileUrl = fileUrl.replace(
                                /\\/g,
                                "/"
                            );
                            const linkConcat = `${linkBackend}${formattedFileUrl}`;
                            const linkCompleto = linkConcat.replace(
                                /\/\/public/,
                                "/public"
                            );

                            return (
                                <div
                                    key={index}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                    }}
                                >
                                    {fileUrl.endsWith(".pdf") ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                height: "85vh",
                                                textAlign: "center",
                                            }}
                                        >
                                            <button
                                                onClick={() =>
                                                    window.open(
                                                        linkCompleto,
                                                        "_blank"
                                                    )
                                                }
                                                style={{
                                                    padding: "10px 20px",
                                                    backgroundColor: "#007bff",
                                                    color: "white",
                                                    border: "none",
                                                    borderRadius: "5px",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                Visualizar PDF
                                            </button>
                                        </div>
                                    ) : (
                                        <img
                                            src={linkCompleto}
                                            alt={`Imagem ${index + 1}`}
                                            style={{
                                                maxHeight: "85vh",
                                                maxWidth: "100%",
                                                margin: "auto",
                                                objectFit: "contain",
                                            }}
                                        />
                                    )}
                                </div>
                            );
                        })}
                    </Slider>,
                    carouselElement
                );

                document.body.appendChild(overlayElement);

                overlayElement.addEventListener("click", (event) => {
                    if (event.target === overlayElement) {
                        document.body.removeChild(overlayElement);
                    }
                });
            }
        };

        return (
            <Col sm={12}>
                <Col sm={12}>
                    <button
                        onClick={handleViewImage}
                        className="btn btn-primary btn-sm"
                        style={{ width: "100%" }}
                        type="button"
                    >
                        Visualizar Imagens
                    </button>
                </Col>
            </Col>
        );
    };

    const ExibirImagem = ({ imageUrl }: { imageUrl: string }) => {
        const handleViewImage = () => {
            const overlayElement = document.createElement("div");
            overlayElement.style.cssText = `
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
      `;

            let imgElement: HTMLImageElement | null =
                document.createElement("img");

            imgElement.onload = () => {
                if (imgElement && imgElement.complete) {
                    overlayElement.appendChild(imgElement);
                } else {
                    showNoImageMessage();
                }
            };

            imgElement.onerror = () => {
                showNoImageMessage();
            };

            const formattedImageUrl = imageUrl && imageUrl.replace(/\\/g, "/");
            const linkConcat = `${linkBackend}${formattedImageUrl}`;
            const linkCompleto = linkConcat.replace(/\/\/public/, "/public");
            imgElement.src = linkCompleto;
            imgElement.alt = "Imagem";
            imgElement.style.cssText = `
        max-height: 80vh;
        max-width: 100%;
      `;

            const showNoImageMessage = () => {
                const noImageMessage = document.createElement("div");
                noImageMessage.textContent = "Nenhuma imagem disponível";
                noImageMessage.style.cssText = `
          color: white;
          font-size: 20px;
        `;

                overlayElement.appendChild(noImageMessage);
            };

            overlayElement.addEventListener("click", () => {
                document.body.removeChild(overlayElement);
            });

            document.body.appendChild(overlayElement);
        };

        return (
            <Col sm={12}>
                <Col sm={12}>
                    <button
                        onClick={handleViewImage}
                        className="btn btn-primary btn-sm"
                        style={{ width: "100%" }}
                        type="button"
                    >
                        {t("Visualizar Foto")}
                    </button>
                </Col>
            </Col>
        );
    };

    const ExibirPDF = ({ pdfPath }: { pdfPath: string }) => {
        const handleViewPDF = () => {
            const pdfLink = getPdfLink(pdfPath);

            if (pdfLink) {
                window.open(pdfLink, "_blank");
            } else {
                toast.error("Não há PDF disponível.");
            }
        };

        const getPdfLink = (pdfPath: string): string | null => {
            if (pdfPath) {
                const formattedPdf = pdfPath && pdfPath.replace(/\\/g, "/");
                const linkConcat = `${linkBackend}${formattedPdf}`;
                const linkCompleto = linkConcat.replace(
                    /\/\/public/,
                    "/public"
                );

                return linkCompleto;
            } else {
                return null;
            }
        };

        return (
            <Col sm={12}>
                <Col sm={12}>
                    <button
                        onClick={handleViewPDF}
                        className="btn btn-primary btn-sm"
                        style={{ width: "100%" }}
                        type="button"
                    >
                        {t("Visualizar PDF")}
                    </button>
                </Col>
            </Col>
        );
    };

    interface Option {
        value: string;
        label: string;
    }
    const handleAreasChange = (selectedOptions: any) => {
        const newOptions = (selectedOptions as Option[]).filter(
            (option) =>
                !areasEnvolvidas.some(
                    (existingOption: any) =>
                        existingOption.value === option.value
                )
        );

        setAreasEnvolvidas((prev: any) => [...prev, ...newOptions]);
    };

    useEffect(() => {
        const calculatePrioridade = (prioridade: string, dataEntrada: any) => {
            let diaDataEntrada = new Date(dataEntrada) || new Date();

            switch (prioridade) {
                case "Urgente":
                    diaDataEntrada.setDate(diaDataEntrada.getDate() + 2);
                    break;
                case "Importante":
                    diaDataEntrada.setDate(diaDataEntrada.getDate() + 8);
                    break;
                case "Média":
                    diaDataEntrada.setDate(diaDataEntrada.getDate() + 16);
                    break;
                case "Baixa":
                    diaDataEntrada.setDate(diaDataEntrada.getDate() + 31);
                    break;
                default:
                    if (!isNaN(diaDataEntrada.getTime())) {
                        diaDataEntrada = new Date();
                    } else {
                        diaDataEntrada = new Date();
                    }
                    break;
            }

            let dia = {
                string: diaDataEntrada
                    ? diaDataEntrada.toLocaleDateString()
                    : "",
                date: diaDataEntrada,
            };

            return dia;
        };

        const { string } = calculatePrioridade(prioridadeTema, dataEntrada);

        setPrioridadeData(string);
    }, [prioridadeTema]);

    const getRangeText = (range: DateRange): string => {
        if (!range?.from) return "";

        return (
            format(range.from, "dd/MM/yyyy", { locale: ptBR }) +
            " à " +
            format(range.to || range.from, "dd/MM/yyyy", { locale: ptBR })
        );
    };

    const handleFileChange = (e: any) => {
        setFotoDemandaExterna(e.target.files ? e.target.files : null);
    };

    const handleChangeStakeholderDemandaExterna = (id: any, value: any) => {
        const updatedStakeholders = stakeholder.map((stakeholder: any) =>
            stakeholder.id === id ? { ...stakeholder, value } : stakeholder
        );

        setStakeholder(updatedStakeholders);
    };

    const handleAddStakeholderDemandaExterna = () => {
        const newStakeholder = {
            id: stakeholder.length + 1,
            value: "",
        };
        setStakeholder([...stakeholder, newStakeholder]);
    };

    const handleRemoveStakeholderDemanda = (stake: any) => {
        const updatedStakeholders = stakeholder.filter(
            (stakeholder: any) => stakeholder !== stake
        );
        setStakeholder(updatedStakeholders);
    };

    const adicionarCampoAcao = () => {
        setAcoes([
            ...acoes,
            { acao: "", responsavel: "", status: "", dt_prazo: undefined },
        ]);
    };

    const removerCampoAcao = (index: number) => {
        const novasAcoes = [...acoes];
        novasAcoes.splice(index, 1);
        setAcoes(novasAcoes);
    };

    interface Acao {
        acao: string;
        responsavel: string;
        status: string;
        dt_prazo: Date | undefined;
    }

    const handleChangeAcoes = (
        index: number,
        field: keyof Acao,
        value: string | Date
    ) => {
        const novasAcoes = [...acoes];

        if (field === "dt_prazo") {
            novasAcoes[index][field] =
                typeof value === "string"
                    ? new Date(value + "T00:00:00")
                    : (value as Date);
        } else {
            novasAcoes[index][field] = value as string;
        }

        setAcoes(novasAcoes);
    };

    const handleOpenModalOficios = () => {
        setOpemModalOficios(true);
    };

    const handleCloseModalOficios = () => {
        setOpemModalOficios(false);
    };

    const handleChangeOficios = (
        index: number,
        field: keyof Oficio,
        value: File | null
    ) => {
        const novosOficios = oficios.map((oficio, i) => {
            if (i === index) {
                return {
                    ...oficio,
                    [field]: value,
                };
            }
            return oficio;
        });

        setOficios(novosOficios);
    };

    const handleChangeAcoesEngaja = (
        index: number,
        field: keyof AcaoEngaja,
        value: string | Date
    ) => {
        const novasAcoes = [...acoesEngaja];

        if (field === "dataPrazo") {
            novasAcoes[index][field] =
                typeof value === "string"
                    ? new Date(value + "T00:00:00")
                    : (value as Date);
        } else {
            novasAcoes[index][field] = value as string;
        }

        setAcoesEngaja(novasAcoes);
    };

    const adicionarCampoAcaoEngaja = () => {
        if (acoesEngaja.length >= 4) {
            toast.error(
                "Limite de ações por engajamento atingido, maximo 4 ações"
            );
        } else {
            setAcoesEngaja([
                ...acoesEngaja,
                { acao: "", responsavel: "", status: "", dataPrazo: undefined },
            ]);
        }
    };

    const removerCampoAcaoEngaja = (index: number) => {
        const novasAcoes = [...acoesEngaja];
        novasAcoes.splice(index, 1);
        setAcoesEngaja(novasAcoes);
    };

    const adicionarCampoOficio = () => {
        setOficios([
            ...oficios,
            {
                id: null,
                caminho_oficio_entrada: "",
                caminho_oficio_resposta: "",
            },
        ]);
    };

    const removerCampoOficio = (index: number) => {
        const novosOficios = [...oficios];
        const oficioRemovido = novosOficios[index];
        setOficioParaExclusao((prevState) => [...prevState, oficioRemovido]); // Salva o ofício removido
        novosOficios.splice(index, 1);
        setOficios(novosOficios);
    };

    return (
        <Col sm={12}>
            <div className="grid-dados">
                <Col sm={12}>
                    <Row>
                        <Col sm={9}>
                            {filtrosAtivos.map((filtro, i) => {
                                const filterObj = tiposFiltro.find(
                                    ({ value }: { value: any }) =>
                                        value === filtro
                                );
                                if (!filterObj) return null;

                                const { filter, setFilter, type, opts } =
                                    filterObj;

                                return (
                                    <Col className="filtro-ativo" key={i}>
                                        <Row>
                                            <Col sm={9}>
                                                {type === "date" ? (
                                                    <>
                                                        <button
                                                            onClick={() =>
                                                                setShowPickerData(
                                                                    filtro
                                                                )
                                                            }
                                                            style={{
                                                                border: 0,
                                                                outline: 0,
                                                                backgroundColor:
                                                                    "#0000",
                                                                display:
                                                                    "block",
                                                                width: "100%",
                                                                height: "100%",
                                                                padding: 0,
                                                                margin: 0,
                                                            }}
                                                        >
                                                            <Form.Control
                                                                type="text"
                                                                placeholder={`Filtrar ${filtro}`}
                                                                value={getRangeText(
                                                                    filter as any
                                                                )}
                                                                style={{
                                                                    height: "73%",
                                                                }}
                                                                onFocus={(e) =>
                                                                    e.target.blur()
                                                                }
                                                                readOnly
                                                            />
                                                        </button>
                                                        {showPickerData ===
                                                            filtro && (
                                                            <DateRangeInput
                                                                selected={
                                                                    filter as any
                                                                }
                                                                onChange={
                                                                    setFilter
                                                                }
                                                                onFinish={() =>
                                                                    setShowPickerData(
                                                                        undefined
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    </>
                                                ) : type === "select" ? (
                                                    <Form.Select
                                                        value={
                                                            filter?.toString() ||
                                                            ""
                                                        }
                                                        onChange={(e) => {
                                                            setFilter(
                                                                e.target
                                                                    .value as any
                                                            );
                                                        }}
                                                        style={{
                                                            height: "73%",
                                                        }}
                                                    >
                                                        <option
                                                            label={`Filtrar ${filtro}`}
                                                            value={""}
                                                        />
                                                        {opts?.map((opt, i) => {
                                                            const isString =
                                                                typeof opt ===
                                                                "string";

                                                            return (
                                                                <option
                                                                    key={i}
                                                                    label={
                                                                        isString
                                                                            ? opt
                                                                            : (
                                                                                  opt as OptionType
                                                                              )
                                                                                  .label
                                                                    }
                                                                    value={
                                                                        isString
                                                                            ? opt
                                                                            : (
                                                                                  opt as OptionType
                                                                              )
                                                                                  .value
                                                                    }
                                                                />
                                                            );
                                                        })}
                                                    </Form.Select>
                                                ) : (
                                                    <Form.Control
                                                        type="text"
                                                        placeholder={`Filtrar ${filtro}`}
                                                        value={
                                                            filter?.toString() ||
                                                            ""
                                                        }
                                                        onChange={(e) => {
                                                            setFilter(
                                                                e.target
                                                                    .value as any
                                                            );
                                                        }}
                                                        style={{
                                                            height: "73%",
                                                        }}
                                                    />
                                                )}
                                            </Col>
                                            <Col sm={3}>
                                                <button
                                                    onClick={() => {
                                                        setFilter(undefined);
                                                    }}
                                                    className="botao-filtro mt-2"
                                                >
                                                    {t("Limpar Filtro")}
                                                </button>
                                            </Col>
                                        </Row>
                                    </Col>
                                );
                            })}
                        </Col>
                        <Col sm={3}>
                            <button
                                className="botao-filtro mt-2"
                                onClick={() =>
                                    setOpenModalFiltro(!openModalFiltro)
                                }
                            >
                                {t("Filtros")}
                            </button>
                        </Col>
                    </Row>

                    {openModalFiltro && (
                        <Col sm={12} className="mb-2">
                            <Card>
                                <Card.Body>
                                    <Col
                                        sm={12}
                                        className="justify-content-center"
                                    >
                                        <Row>
                                            {tiposFiltro.map((filtro, i) => (
                                                <Col sm={3} key={i}>
                                                    <button
                                                        className={`filtros-modal ${
                                                            filtrosAtivos?.includes(
                                                                filtro.value
                                                            )
                                                                ? "ativo"
                                                                : ""
                                                        }`}
                                                        onClick={() => {
                                                            handleModalFiltros(
                                                                filtro.value
                                                            );
                                                        }}
                                                    >
                                                        {filtro.label}
                                                    </button>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                </Col>

                <div>
                    {!permissoesUser.demandas_view && (
                        <div>
                            <DataGrid rows={[]} columns={datagridColunas} />
                        </div>
                    )}
                    {permissoesUser.demandas_view && (
                        <DataGrid
                            rows={dadosFiltrados}
                            columns={datagridColunas}
                            onRowClick={handleRowClick}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                        />
                    )}
                </div>
            </div>

            {/* Modal */}
            <Modal
                show={isModalOpen}
                onHide={closeModal}
                className="modal-xl"
                dialogClassName="modal-rollover"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Detalhes da Issue")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedRowData && (
                        <div id="content-grid">
                            <Col sm={12} className="mb-2">
                                <Col sm={12}>
                                    <Col sm={12} className="mt-4 mb-4">
                                        <Row>
                                            <Col sm={4}>
                                                <Form.Label htmlFor="data-entrada">
                                                    {t("Data de entrada")}
                                                </Form.Label>
                                                <Form.Control
                                                    className="formInput"
                                                    type="date"
                                                    value={dataEntrada}
                                                    onChange={(e) => {
                                                        setDataEntrada(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Col>
                                            <Col sm={4}>
                                                <Form.Label>
                                                    {t("Prioridade do Tema")}
                                                </Form.Label>
                                                <Form.Select
                                                    className="formSelect"
                                                    value={prioridadeTema}
                                                    onChange={(event) => {
                                                        setPrioridadeTema(
                                                            event.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value="Vazio">
                                                        {t(
                                                            "Selecione Prioridade"
                                                        )}
                                                    </option>
                                                    <option value="Urgente">
                                                        {t("Urgente (1 dia)")}
                                                    </option>
                                                    <option value="Importante">
                                                        {t(
                                                            "Importante (7 dias)"
                                                        )}
                                                    </option>
                                                    <option value="Média">
                                                        {t("Média (15 dias)")}
                                                    </option>
                                                    <option value="Baixa">
                                                        {t("Baixa (30 dias)")}
                                                    </option>
                                                </Form.Select>
                                            </Col>
                                            <Col sm={4}>
                                                <Form.Label htmlFor="previsao-conclusao">
                                                    {t("Previsão de conclusão")}
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="previsao-conclusao"
                                                    placeholder="Previsão de conclusão"
                                                    disabled
                                                    value={String(
                                                        prioridadeData
                                                    )}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={12} className="mt-4 mb-4">
                                        <Row>
                                            <Col sm={3}>
                                                <Form.Label>
                                                    {t("Origem")}
                                                </Form.Label>
                                                <Form.Select
                                                    className="formSelect"
                                                    required
                                                    value={origem}
                                                    onChange={(e) => {
                                                        setOrigem(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value={""}>
                                                        {t("Selecione Origem")}
                                                    </option>
                                                    <option
                                                        value={
                                                            "Poder Público Municipal - Secretaria"
                                                        }
                                                    >
                                                        {t(
                                                            "Poder Público Municipal - Secretaria"
                                                        )}
                                                    </option>
                                                    <option
                                                        value={
                                                            "Poder Público Municipal - Autarquia"
                                                        }
                                                    >
                                                        {t(
                                                            "Poder Público Municipal - Autarquia"
                                                        )}
                                                    </option>
                                                    <option
                                                        value={
                                                            "Poder Público Municipal - Câmara de Vereadores"
                                                        }
                                                    >
                                                        {t(
                                                            "Poder Público Municipal - Câmara de Vereadores"
                                                        )}
                                                    </option>
                                                    <option
                                                        value={
                                                            "Poder Público Municipal - Prefeitura"
                                                        }
                                                    >
                                                        {t(
                                                            "Poder Público Municipal - Prefeitura"
                                                        )}
                                                    </option>

                                                    <option
                                                        value={
                                                            "Poder Público Estadual"
                                                        }
                                                    >
                                                        {t(
                                                            "Poder Público Estadual"
                                                        )}
                                                    </option>
                                                    <option
                                                        value={
                                                            "Poder Público Federal"
                                                        }
                                                    >
                                                        {t(
                                                            "Poder Público Federal"
                                                        )}
                                                    </option>
                                                    <option
                                                        value={
                                                            "Ministério Público"
                                                        }
                                                    >
                                                        {t(
                                                            "Ministério Público"
                                                        )}
                                                    </option>
                                                    <option
                                                        value={
                                                            "Entidades Setoriais"
                                                        }
                                                    >
                                                        {t(
                                                            "Entidades Setoriais"
                                                        )}
                                                    </option>
                                                    <option
                                                        value={
                                                            "Entidades do Terceiro Setor"
                                                        }
                                                    >
                                                        {t(
                                                            "Entidades do Terceiro Setor"
                                                        )}
                                                    </option>
                                                    <option
                                                        value={"Setor Privado"}
                                                    >
                                                        {t("Setor Privado")}
                                                    </option>
                                                    <option value={"Academia"}>
                                                        {t("Academia")}
                                                    </option>
                                                    <option value={"outros"}>
                                                        {t("Outros")}
                                                    </option>
                                                </Form.Select>
                                            </Col>
                                            <Col sm={3}>
                                                <Form.Label>
                                                    {t("Esfera")}
                                                </Form.Label>
                                                <Form.Select
                                                    value={esferaDemanda}
                                                    onChange={(e) => {
                                                        setEsferaDemanda(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value={""}>
                                                        {t(
                                                            "Selecione a Esfera"
                                                        )}
                                                    </option>
                                                    <option value={"Federal"}>
                                                        {t("Federal")}
                                                    </option>
                                                    <option value={"Estadual"}>
                                                        {t("Estadual")}
                                                    </option>
                                                    <option value={"Municipal"}>
                                                        {t("Municipal")}
                                                    </option>
                                                </Form.Select>
                                            </Col>
                                            <Col sm={3}>
                                                <Form.Label>
                                                    {t("Estado")}
                                                </Form.Label>
                                                <Form.Select
                                                    className="formSelect"
                                                    required
                                                    value={estado}
                                                    onChange={(e) => {
                                                        setEstado(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value={""}>
                                                        {t(
                                                            "Selecione o estado"
                                                        )}
                                                    </option>
                                                    {listaEstados?.map(
                                                        (
                                                            estado: any,
                                                            i: any
                                                        ) => {
                                                            return (
                                                                <option
                                                                    key={i}
                                                                    value={
                                                                        estado.sigla
                                                                    }
                                                                >
                                                                    {
                                                                        estado.nome
                                                                    }
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </Form.Select>
                                            </Col>
                                            <Col sm={3}>
                                                <Form.Label>
                                                    {t("Municipio")}
                                                </Form.Label>
                                                <Form.Select
                                                    className="formSelect"
                                                    required
                                                    value={municipio}
                                                    onChange={(e) => {
                                                        setMunicipio(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value={""}>
                                                        {t(
                                                            "Selecione o municipio"
                                                        )}
                                                    </option>
                                                    {listaMunicipios[
                                                        estado
                                                    ]?.map(
                                                        (
                                                            municipio: any,
                                                            i: any
                                                        ) => {
                                                            return (
                                                                <option
                                                                    key={i}
                                                                    value={
                                                                        municipio
                                                                    }
                                                                >
                                                                    {municipio}
                                                                </option>
                                                            );
                                                        }
                                                    )}
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                    </Col>

                                    {origem === "outros" && (
                                        <Col sm={12} className="mt-4 mb-4">
                                            <Row>
                                                <Col>
                                                    <Form.Control
                                                        className="formInput"
                                                        type={"text"}
                                                        placeholder="Digite Origem"
                                                        required
                                                        value={origemTexto}
                                                        onChange={(e) => {
                                                            setOrigemTexto(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    )}
                                    <Col sm={12} className="mt-4 mb-4">
                                        <Row>
                                            <Col sm={5}>
                                                <Form.Label>
                                                    Stakeholder(s)
                                                </Form.Label>
                                                {Array.isArray(stakeholder) &&
                                                    stakeholder.map(
                                                        (
                                                            stakeholder: any,
                                                            index: any
                                                        ) => (
                                                            <div
                                                                key={
                                                                    stakeholder.id
                                                                }
                                                                className="mb-2"
                                                            >
                                                                <Row>
                                                                    <Col
                                                                        sm={11}
                                                                    >
                                                                        <Select
                                                                            onChange={(
                                                                                e: any
                                                                            ) => {
                                                                                handleChangeStakeholderDemandaExterna(
                                                                                    stakeholder.id,
                                                                                    e.value
                                                                                );
                                                                            }}
                                                                            options={
                                                                                optionsStakeholders
                                                                            }
                                                                            value={optionsStakeholders.find(
                                                                                (
                                                                                    option: any
                                                                                ) =>
                                                                                    option.value ===
                                                                                    stakeholder.value
                                                                            )}
                                                                            isSearchable={
                                                                                true
                                                                            }
                                                                            placeholder="Selecione Stakeholder"
                                                                        />
                                                                    </Col>
                                                                    <Col sm={1}>
                                                                        {index >
                                                                            0 && (
                                                                            <button
                                                                                type="button"
                                                                                className="button-excluir-stake"
                                                                                onClick={() =>
                                                                                    handleRemoveStakeholderDemanda(
                                                                                        stakeholder
                                                                                    )
                                                                                }
                                                                            >
                                                                                <FaTimes />
                                                                            </button>
                                                                        )}
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )
                                                    )}
                                            </Col>
                                            <Col sm={7}>
                                                <Form.Label>
                                                    {t("Add Stakeholder")}
                                                </Form.Label>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    style={{ width: "100%" }}
                                                    onClick={
                                                        handleAddStakeholderDemandaExterna
                                                    }
                                                >
                                                    {t("Adicionar Stakeholder")}
                                                </button>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={12} className="mt-4 mb-4">
                                        <Row>
                                            <Col sm={4}>
                                                <Form.Label>
                                                    {t("Forma de Contato")}
                                                </Form.Label>
                                                <Form.Select
                                                    className="formSelect"
                                                    value={formaContato}
                                                    onChange={(e) => {
                                                        setFormaContato(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value="">
                                                        {t(
                                                            "Selecione forma de contato"
                                                        )}
                                                    </option>
                                                    <option value="email">
                                                        {t("Email")}
                                                    </option>
                                                    <option value="Whatsapp">
                                                        {t("Whatsapp")}
                                                    </option>
                                                    <option value="Ligação Telefônica">
                                                        {t(
                                                            "Ligação Telefônica"
                                                        )}
                                                    </option>
                                                    <option value="Reunião">
                                                        {t("Reunião")}
                                                    </option>
                                                    <option value="Contato com Funcionários">
                                                        {t(
                                                            "Contato com Funcionários"
                                                        )}
                                                    </option>
                                                    <option value="Ofício">
                                                        {t("Ofício")}
                                                    </option>
                                                    <option value="Outros">
                                                        {t("Outros")}
                                                    </option>
                                                </Form.Select>
                                            </Col>
                                            {formaContato === "Outros" && (
                                                <Col
                                                    sm={6}
                                                    className="mt-4 mb-4"
                                                >
                                                    <Row>
                                                        <Col>
                                                            <Form.Control
                                                                className="formInput"
                                                                type={"text"}
                                                                placeholder="Digite forma de contato"
                                                                required
                                                                value={
                                                                    formaContatoTexto
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setFormaContatoTexto(
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )}

                                            <Col sm={4}>
                                                <Form.Label>
                                                    {t("Categoria do Tema")}
                                                </Form.Label>
                                                <Form.Select
                                                    className="formSelect"
                                                    value={categoriaTema}
                                                    onChange={(e) => {
                                                        setCategoriaTema(
                                                            () => e.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value="">
                                                        {t(
                                                            "Selecione Categoria"
                                                        )}
                                                    </option>
                                                    <option value="Questões Tributárias">
                                                        {t(
                                                            "Questões Tributárias"
                                                        )}
                                                    </option>
                                                    <option value="Questões Ambientais">
                                                        {t(
                                                            "Questões Ambientais"
                                                        )}
                                                    </option>
                                                    <option value="Questões Fundiárias">
                                                        {t(
                                                            "Questões Fundiárias"
                                                        )}
                                                    </option>
                                                    <option value="Licenciamento">
                                                        {t("Licenciamento")}
                                                    </option>
                                                    <option value="Continuidade Operacional">
                                                        {t(
                                                            "Continuidade Operacional"
                                                        )}
                                                    </option>
                                                    <option value="Projetos">
                                                        {t("Projetos")}
                                                    </option>
                                                    <option value="Infraestrutura">
                                                        {t("Infraestrutura")}
                                                    </option>
                                                    <option value="Empregabilidade e Capacitação">
                                                        {t(
                                                            "Empregabilidade e Capacitação"
                                                        )}
                                                    </option>
                                                    <option value="Programa Instituto Mosaic">
                                                        {t(
                                                            "Programa Instituto Mosaic"
                                                        )}
                                                    </option>
                                                    <option value="Relacionamento Institucional">
                                                        {t(
                                                            "Relacionamento Institucional"
                                                        )}
                                                    </option>
                                                    <option value="Doações">
                                                        {t("Doações")}
                                                    </option>
                                                    <option value="Patrocínio">
                                                        {t("Patrocínio")}
                                                    </option>
                                                    <option value="outros">
                                                        {t("Outros")}
                                                    </option>
                                                </Form.Select>
                                            </Col>
                                            {categoriaTema === "outros" && (
                                                <Col
                                                    sm={12}
                                                    className="mt-4 mb-4"
                                                >
                                                    <Row>
                                                        <Col>
                                                            <Form.Control
                                                                className="formInput"
                                                                type={"text"}
                                                                placeholder="Digite categoria do tema"
                                                                required
                                                                value={
                                                                    categoriaTemaTexto
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setCategoriaTemaTexto(
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )}

                                            <Col sm={4}>
                                                <Form.Label>
                                                    {t(
                                                        "Impacto financeiro (potencial)"
                                                    )}
                                                </Form.Label>
                                                <Form.Select
                                                    className="formSelect"
                                                    value={impactoFinanceiro}
                                                    onChange={(e) => {
                                                        setImpactoFinanceiro(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value="">
                                                        {t(
                                                            "Selecione o impacto financeiro"
                                                        )}
                                                    </option>
                                                    <option value="Não identeificado">
                                                        {t("Não identeificado")}
                                                    </option>
                                                    <option value="Em análise">
                                                        {t("Em análise")}
                                                    </option>
                                                    <option value="< US$ 10 M">
                                                        {t("< US$ 10 M")}
                                                    </option>
                                                    <option value="Entre US$ 10M  e US$ 50 M">
                                                        {t(
                                                            "Entre US$ 10M  e US$ 50 M"
                                                        )}
                                                    </option>
                                                    <option value="> US$ 50M">
                                                        {t("> US$ 50M")}
                                                    </option>
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col sm={12}>
                                        <Row>
                                            <Col sm={5}>
                                                <Form.Label>
                                                    {t("Tema")}
                                                </Form.Label>
                                                <Form.Control
                                                    className="formInput"
                                                    type={"text"}
                                                    as="textarea"
                                                    required
                                                    value={descricaoTema}
                                                    onChange={(e) => {
                                                        setDescricaoTema(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Col>
                                            <Col sm={3}>
                                                <Form.Label>
                                                    {t("Criticidade do Tema")}
                                                </Form.Label>
                                                <Form.Select
                                                    className="formSelect"
                                                    value={criticidadeTema}
                                                    onChange={(e) => {
                                                        setCriticidadeTema(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    <option>
                                                        {t(
                                                            "Selecione Criticidade"
                                                        )}
                                                    </option>
                                                    <option value="Alta">
                                                        {t("Alto")}
                                                    </option>
                                                    <option value="Média">
                                                        {t("Médio")}
                                                    </option>
                                                    <option value="Baixa">
                                                        {t("Baixo")}
                                                    </option>
                                                </Form.Select>
                                            </Col>

                                            <Col sm={4}>
                                                <Form.Label>
                                                    {t("Relevância")}
                                                </Form.Label>
                                                <Form.Select
                                                    value={relevancia}
                                                    className="formSelect"
                                                    onChange={(event) => {
                                                        setRelevancia(
                                                            event.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value="">
                                                        {t(
                                                            "Seleciona Relevância"
                                                        )}
                                                    </option>
                                                    <option value="Favorável">
                                                        {t("Favorável")}
                                                    </option>
                                                    <option value="Requer Atenção">
                                                        {t("Requer Atenção")}
                                                    </option>
                                                    <option value="Neutro">
                                                        {t("Neutro")}
                                                    </option>
                                                    <option value="Não Favorável">
                                                        {t("Não Favorável")}
                                                    </option>
                                                </Form.Select>
                                            </Col>

                                            {/* <Col sm={4}>
                        <Form.Label>{t("Status Atendimento")}</Form.Label>
                        <Form.Select
                          required
                          className="formSelect"
                          value={statusAtendimento}
                          onChange={(e) => {
                            setStatusAtendimento(e.target.value);
                          }}
                        >
                          <option>{t("Selecione Status")}</option>
                          <option value={"Atendido"}>{t("Atendido")}</option>
                          <option value={"Em Andamento"}>
                            {t("Em Andamento")}
                          </option>
                          <option value={"Negado"}>{t("Negado")}</option>
                        </Form.Select>
                      </Col> */}
                                        </Row>
                                    </Col>

                                    <Col sm={12} className="mt-4 mb-4">
                                        <Row>
                                            <Col sm={5}>
                                                <Form.Label>
                                                    {t("Tratativa")}
                                                </Form.Label>
                                                <Form.Control
                                                    className="formInput"
                                                    type={"text"}
                                                    as="textarea"
                                                    value={tratativa}
                                                    onChange={(e) => {
                                                        setTratativa(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Col>
                                            <Col sm={3}>
                                                <Form.Label>
                                                    {t("Conclusão")}
                                                </Form.Label>
                                                <Form.Control
                                                    className="formInput mt-4"
                                                    type="date"
                                                    value={String(
                                                        dataConclusao
                                                    )}
                                                    onChange={(e) => {
                                                        setDataConclusao(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Col>
                                            {/* <Col sm={2} className="mt-4">
                        <Form.Check
                          type="switch"
                          id="oficioSwitch"
                          label="Ofício?"
                          checked={oficioBool}
                          onChange={() => setOficioBool(!oficioBool)}
                        />
                      </Col> */}
                                            <Col sm={2}>
                                                <Form.Check
                                                    className="mt-4"
                                                    type="switch"
                                                    id="oficioSwitch"
                                                    label="Demanda atendida?"
                                                    checked={atendida}
                                                    onChange={() =>
                                                        setAtendida(!atendida)
                                                    }
                                                />
                                            </Col>
                                            <Col sm={2} className="mt-4">
                                                <Form.Check
                                                    className="formSwitch"
                                                    type="switch"
                                                    label={t("Plano de Ação?")}
                                                    checked={planoAcao}
                                                    onChange={(e) => {
                                                        setPlanoAcao(
                                                            e.target.checked
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>

                                    {/* {formaContato === "Ofício" && (
            <div> */}
                                    {/* {listaOficiosGeral.map((oficio, index) => (
                <div key={index}>
                  <Row className="mt-3">
                    <Col sm={6}>
                      <Form.Label>{`Ofício de Entrada ${
                        index + 1
                      }:`}</Form.Label>
                      <div className="cadastroFotos">
                        <Form.Control
                          type="file"
                          name={`oficio_entrada_${index}`}
                          multiple
                          className="inputFoto "
                          onChange={(e: any) => {
                            handleChangeOficios(
                              index,
                              "oficioEntrada",
                              e.target.files[0] ? e.target.files[0] : null
                            );
                          }}
                        />
                      </div>
                    </Col>
                    <Col sm={6}>
                      <Form.Label>{`Ofício de Resposta ${
                        index + 1
                      }:`}</Form.Label>
                      <div className="cadastroFotos">
                        <Form.Control
                          type="file"
                          name={`oficio_resposta_${index}`}
                          multiple
                          className="inputFoto"
                          onChange={(e: any) => {
                            handleChangeOficios(
                              index,
                              "oficioSaida",
                              e.target.files[0] ? e.target.files[0] : null
                            );
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Col>
                    {index > 0 && (
                      <Row>
                        <Col sm={12}>
                          <Button
                            style={{ width: "100%" }}
                            variant="danger mt-3 btn-sm"
                            onClick={() => removerCampoOficio(index)}
                          >
                            Remover ofício
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </div>
              ))}
              <Col sm={12} className="mt-3">
                <Button
                  className="btn btn-primary btn-sm"
                  style={{ width: "100%" }}
                  onClick={adicionarCampoOficio}
                >
                  Adicionar ofício
                </Button>
              </Col>
            </div>
          )} */}

                                    {planoAcao && (
                                        <div>
                                            <Col sm={12} className="mt-4 mb-4">
                                                <Row>
                                                    <Col sm={12}>
                                                        <Form.Label>
                                                            {t(
                                                                "Áreas Envolvidas"
                                                            )}
                                                        </Form.Label>
                                                        <Form.Group as={Row}>
                                                            <Col>
                                                                <Row>
                                                                    <Select
                                                                        isMulti
                                                                        options={
                                                                            optionsAreas
                                                                        }
                                                                        value={
                                                                            areasEnvolvidas
                                                                        }
                                                                        onChange={
                                                                            handleAreasChange
                                                                        }
                                                                    />
                                                                </Row>
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <div>
                                                <Col
                                                    sm={12}
                                                    className="mt-4 mb-4"
                                                >
                                                    <Row>
                                                        {acoes &&
                                                            acoes.map(
                                                                (
                                                                    acao: any,
                                                                    index: any
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <Col
                                                                            sm={
                                                                                12
                                                                            }
                                                                            className="mt-4 mb-4"
                                                                        >
                                                                            <Row>
                                                                                <Col
                                                                                    sm={
                                                                                        6
                                                                                    }
                                                                                >
                                                                                    <Form.Label>
                                                                                        {t(
                                                                                            `Ação ${
                                                                                                index +
                                                                                                1
                                                                                            }`
                                                                                        )}
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        className="formInput"
                                                                                        type="text"
                                                                                        value={
                                                                                            acao.acao &&
                                                                                            acao.acao
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleChangeAcoes(
                                                                                                index,
                                                                                                "acao",
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </Col>
                                                                                <Col
                                                                                    sm={
                                                                                        6
                                                                                    }
                                                                                >
                                                                                    <Form.Label>
                                                                                        {t(
                                                                                            "Responsável"
                                                                                        )}
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        className="formInput"
                                                                                        type="text"
                                                                                        value={
                                                                                            acao.responsavel
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleChangeAcoes(
                                                                                                index,
                                                                                                "responsavel",
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col
                                                                            sm={
                                                                                12
                                                                            }
                                                                            className="mt-4 mb-4"
                                                                        >
                                                                            <Row>
                                                                                <Col
                                                                                    sm={
                                                                                        6
                                                                                    }
                                                                                >
                                                                                    <Form.Label>
                                                                                        {t(
                                                                                            "Prazo"
                                                                                        )}
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        className="formInput"
                                                                                        type="date"
                                                                                        required
                                                                                        value={
                                                                                            (acao.dt_prazo
                                                                                                ? new Date(
                                                                                                      acao.dt_prazo
                                                                                                  )
                                                                                                      .toISOString()
                                                                                                      .split(
                                                                                                          "T"
                                                                                                      )[0]
                                                                                                : "") ||
                                                                                            ""
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleChangeAcoes(
                                                                                                index,
                                                                                                "dt_prazo",
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </Col>
                                                                                <Col
                                                                                    sm={
                                                                                        6
                                                                                    }
                                                                                >
                                                                                    <Form.Label>
                                                                                        {t(
                                                                                            "Status"
                                                                                        )}
                                                                                    </Form.Label>
                                                                                    <Form.Select
                                                                                        className="formSelect"
                                                                                        value={
                                                                                            acao.status
                                                                                        }
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleChangeAcoes(
                                                                                                index,
                                                                                                "status",
                                                                                                e
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <option>
                                                                                            {t(
                                                                                                "Selecionar Status"
                                                                                            )}
                                                                                        </option>
                                                                                        <option>
                                                                                            {t(
                                                                                                "Não Iniciado"
                                                                                            )}
                                                                                        </option>
                                                                                        <option>
                                                                                            {t(
                                                                                                "Em Andamento"
                                                                                            )}
                                                                                        </option>
                                                                                        <option>
                                                                                            {t(
                                                                                                "Concluído"
                                                                                            )}
                                                                                        </option>
                                                                                    </Form.Select>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                        <Col
                                                                            sm={
                                                                                12
                                                                            }
                                                                        >
                                                                            {index >=
                                                                                0 && (
                                                                                <Row>
                                                                                    <Col
                                                                                        sm={
                                                                                            12
                                                                                        }
                                                                                    >
                                                                                        <Button
                                                                                            style={{
                                                                                                width: "100%",
                                                                                            }}
                                                                                            variant="danger mt-2 mb-3"
                                                                                            onClick={() =>
                                                                                                removerCampoAcao(
                                                                                                    index
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            Remover
                                                                                            Ação
                                                                                        </Button>
                                                                                    </Col>
                                                                                </Row>
                                                                            )}
                                                                        </Col>
                                                                    </div>
                                                                )
                                                            )}
                                                    </Row>
                                                    <Col sm={12}>
                                                        <button
                                                            className="btn btn-primary"
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            type="button"
                                                            onClick={
                                                                adicionarCampoAcao
                                                            }
                                                        >
                                                            {t(
                                                                "Adicionar Ação"
                                                            )}
                                                        </button>
                                                    </Col>
                                                </Col>
                                            </div>
                                        </div>
                                    )}
                                    <Row>
                                        <Col sm={4}>
                                            <Form.Label>
                                                {t("Gerar Engajamento?")}
                                            </Form.Label>
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btn-sm"
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    onClick={
                                                        handleOpenModalEngajamentoCadastro
                                                    }
                                                >
                                                    {t("Gerar Engajamento")}
                                                </button>
                                            </div>
                                            <Form.Label>
                                                <strong>
                                                    {engajamentosByDemanda.length +
                                                        (engajementosSalvos.length -
                                                            1)}{" "}
                                                    Engajamento(s) salvos
                                                </strong>
                                            </Form.Label>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Label>
                                                {t("Visualizar Engajamentos")}
                                            </Form.Label>
                                            <div>
                                                <Button
                                                    type="button"
                                                    className="btn btn-primary btn-sm"
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    onClick={
                                                        handleOpenModalGrid
                                                    }
                                                >
                                                    {t(
                                                        "Visualizar Engajamentos"
                                                    )}
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Label>
                                                {t("Visualizar Oficios")}
                                            </Form.Label>
                                            <div>
                                                <Button
                                                    type="button"
                                                    className="btn btn-primary btn-sm"
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    onClick={
                                                        handleOpenModalOficios
                                                    }
                                                >
                                                    {t("Visualizar Oficios")}
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Col sm={11}>
                                        <Row className="mt-2">
                                            <Col
                                                sm={12}
                                                className="cadastroFotos"
                                                style={{ marginLeft: "2%" }}
                                            >
                                                <Form.Label>
                                                    {t(
                                                        "Selecione até 4 documentos/Fotos, pressionando Ctrl e selecionando os aquivos desejados (Use arquivos .pdf, .jpg, jpeg, png, e webp com no máximo 2048 kB)"
                                                    )}
                                                </Form.Label>
                                                <Form.Control
                                                    type="file"
                                                    name="avatar"
                                                    multiple
                                                    className="inputFoto"
                                                    style={{ fontSize: "120%" }}
                                                    onChange={handleFileChange}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={12}>
                                        <Row className="mt-2">
                                            <Col sm={12}>
                                                <ExibirImagemCarrousel
                                                    imageUrls={[
                                                        selectedRowData.foto,
                                                        selectedRowData.foto2,
                                                        selectedRowData.foto3,
                                                        selectedRowData.foto4,
                                                    ]}
                                                />
                                            </Col>
                                            {/* <Col sm={6}>
                        <ExibirImagemCarrousel imageUrls={[selectedRowData.foto]} />
                      </Col>
                      <Col sm={6}>
                        <ExibirImagem imageUrl={selectedRowData.foto2} />
                      </Col> */}
                                        </Row>
                                    </Col>
                                    {/* <Col sm={12}>
                    <Col sm={6}>
                      <ExibirPDF pdfData={selectedRowData.OficioEntrada} />
                    </Col>
                  </Col> */}
                                    <Col sm={12}>
                                        <Row>
                                            {permissoesUser.demandas_edit ? (
                                                <Col sm={4}>
                                                    <button
                                                        className="botao-grid"
                                                        onClick={
                                                            HandleEditDemanda
                                                        }
                                                    >
                                                        {t("Salvar")}
                                                    </button>
                                                </Col>
                                            ) : (
                                                <Col sm={4}>
                                                    <button
                                                        className="botao-grid"
                                                        onClick={() =>
                                                            toast.error(
                                                                "Você não tem permissão para editar uma demanda!"
                                                            )
                                                        }
                                                    >
                                                        {t("Salvar")}
                                                    </button>
                                                </Col>
                                            )}
                                            <Col sm={4}>
                                                <button
                                                    className="botao-grid"
                                                    onClick={closeModal}
                                                >
                                                    {t("Cancelar")}
                                                </button>
                                            </Col>
                                            <Col sm={4}>
                                                <button
                                                    className="botao-grid"
                                                    type="button"
                                                    onClick={
                                                        handleOpenModalDelete
                                                    }
                                                >
                                                    {t("Excluir")}
                                                </button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Col>
                            </Col>
                        </div>
                    )}
                </Modal.Body>
            </Modal>

            {/* MODAL CONFIRMACÇÂO DE EXCLUSAO */}
            <Modal
                show={showModalDelete}
                onHide={handleCloseModalDelete}
                centered={true}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div id="modalLogin">
                        <form className="form">
                            <p className="titleForgotPassword">
                                {t("Deseja Excluir Demanda?")}
                            </p>

                            <button
                                className="botao-grid"
                                type="button"
                                onClick={deleteDemanda}
                            >
                                {t("Sim")}
                            </button>
                            <button
                                className="botao-grid"
                                type="button"
                                onClick={handleCloseModalDelete}
                            >
                                {t("Não")}
                            </button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>

            {/* MODAL ENGAJAMENTO */}
            <Modal
                show={showModalEngajamentoCadastro}
                onHide={handleCloseModalEngajamentoCadastro}
                className="modal-lg"
                dialogClassName="modal-rollover"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Cadastrar Engajamento")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id="content-grid">
                        <Form
                            onSubmit={handleSubmitFormEngajamento}
                            encType="multipart/form-data"
                        >
                            <Col sm={12} className="mt-4 mb-4">
                                <Row>
                                    <Col sm={4}>
                                        <Form.Label style={{ fontSize: "85%" }}>
                                            {t("Esfera")}
                                        </Form.Label>
                                        <Form.Select
                                            value={esferaEngaja}
                                            onChange={(e) => {
                                                setEsferaEngaja(e.target.value);
                                            }}
                                        >
                                            <option value={""}>
                                                {t("Selecione a Esfera")}
                                            </option>
                                            <option value={"Federal"}>
                                                {t("Federal")}
                                            </option>
                                            <option value={"Estadual"}>
                                                {t("Estadual")}
                                            </option>
                                            <option value={"Municipal"}>
                                                {t("Municipal")}
                                            </option>
                                        </Form.Select>
                                    </Col>
                                    <Col sm={4}>
                                        <Form.Label>{t("Estado")}</Form.Label>
                                        <Form.Select
                                            required
                                            value={estado}
                                            onChange={(e) => {
                                                setEstadoEngajamento(
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            <option value={""}>
                                                {t("Selecione o estado")}
                                            </option>
                                            {listaEstados?.map(
                                                (estado: any, i: any) => {
                                                    return (
                                                        <option
                                                            key={i}
                                                            value={estado.sigla}
                                                        >
                                                            {estado.nome}
                                                        </option>
                                                    );
                                                }
                                            )}
                                        </Form.Select>
                                    </Col>

                                    <Col sm={4}>
                                        <Form.Label>
                                            {t("Municipio")}
                                        </Form.Label>
                                        <Form.Select
                                            required
                                            value={municipio}
                                            onChange={(e) => {
                                                setMunicipioEngajamento(
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            <option value={""}>
                                                {t("Selecione o municipio")}
                                            </option>
                                            {estado &&
                                                listaMunicipios[estado]?.map(
                                                    (municipio: any) => (
                                                        <option
                                                            key={municipio}
                                                            value={municipio}
                                                        >
                                                            {municipio}
                                                        </option>
                                                    )
                                                )}
                                        </Form.Select>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={12} className="mt-4 mb-4">
                                <Row>
                                    <Col sm={12}>
                                        <Form.Label>{t("Data")}</Form.Label>
                                        <Form.Control
                                            required
                                            className="formInput"
                                            type={"date"}
                                            value={
                                                dataEngajamento
                                                    ? dataEngajamento
                                                          .toISOString()
                                                          .split("T")[0]
                                                    : ""
                                            }
                                            onChange={(e) => {
                                                setDataEngajamento(
                                                    new Date(
                                                        e.target.value +
                                                            " 00:00:00"
                                                    )
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>

                            <Col sm={12}>
                                <Form.Label>
                                    {t("Participantes Mosaic")}
                                </Form.Label>
                                <Col sm={12}>
                                    <Row>
                                        <Col sm={6}>
                                            <Form.Control
                                                placeholder="Participantes Mosaic"
                                                className="formInput"
                                                value={
                                                    participantesMosaicList[0]
                                                        ?.value || ""
                                                }
                                                type="text"
                                                onChange={(event) => {
                                                    const updatedList = [
                                                        ...participantesMosaicList,
                                                    ];
                                                    updatedList[0] = {
                                                        ...updatedList[0],
                                                        value: event.target
                                                            .value,
                                                    };
                                                    setParticipantesMosaicList(
                                                        updatedList
                                                    );
                                                }}
                                            />
                                        </Col>
                                        <Col sm={6}>
                                            <Button
                                                type="button"
                                                className="btn btn-primary"
                                                style={{ width: "100%" }}
                                                onClick={
                                                    handleAddParticipanteMosaic
                                                }
                                            >
                                                {t(
                                                    "Adicionar Participantes Mosaic"
                                                )}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                {participantesMosaicList
                                    .slice(1)
                                    .map((participanteMosaic, index) => (
                                        <div
                                            key={participanteMosaic.id}
                                            className="mt-2 mb-2"
                                        >
                                            <Row className="mt-3">
                                                <Col sm={11}>
                                                    <Form.Control
                                                        placeholder="Participantes Mosaic"
                                                        className="formInput"
                                                        value={
                                                            participanteMosaic.value
                                                        }
                                                        type="text"
                                                        onChange={(event) => {
                                                            const updatedList =
                                                                participantesMosaicList.map(
                                                                    (item) =>
                                                                        item.id ===
                                                                        participanteMosaic.id
                                                                            ? {
                                                                                  ...item,
                                                                                  value: event
                                                                                      .target
                                                                                      .value,
                                                                              }
                                                                            : item
                                                                );
                                                            setParticipantesMosaicList(
                                                                updatedList
                                                            );
                                                        }}
                                                    />
                                                </Col>
                                                <Col sm={1}>
                                                    <button
                                                        type="button"
                                                        className="button-excluir-stake"
                                                        onClick={() =>
                                                            handleRemoveParticipanteMosaic(
                                                                participanteMosaic.id
                                                            )
                                                        }
                                                    >
                                                        <FaTimes />
                                                    </button>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                            </Col>

                            <Col sm={12} className="mt-4">
                                <Form.Label>
                                    {t("Participantes Externos")}
                                </Form.Label>
                                <Col sm={12}>
                                    <Row className="mb-2">
                                        <Col sm={6}>
                                            <Form.Control
                                                placeholder="Participantes Externos"
                                                className="formInput"
                                                value={
                                                    participantesExternosList[0]
                                                        ?.value || ""
                                                }
                                                type="text"
                                                onChange={(event) => {
                                                    const updatedList = [
                                                        ...participantesExternosList,
                                                    ];
                                                    updatedList[0] = {
                                                        ...updatedList[0],
                                                        value: event.target
                                                            .value,
                                                    };
                                                    setParticipantesExternosList(
                                                        updatedList
                                                    );
                                                }}
                                            />
                                        </Col>
                                        <Col sm={6}>
                                            <Button
                                                type="button"
                                                className="btn btn-primary"
                                                style={{ width: "100%" }}
                                                onClick={
                                                    handleAddParticipanteExternos
                                                }
                                            >
                                                {t(
                                                    "Adicionar Participantes Externos"
                                                )}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                {participantesExternosList
                                    .slice(1)
                                    .map((participanteExternos, index) => (
                                        <div
                                            key={participanteExternos.id}
                                            className="mt-2 mb-2"
                                        >
                                            <Row>
                                                <Col sm={11} className="mt-2">
                                                    <Form.Control
                                                        placeholder="Participantes Externos"
                                                        className="formInput"
                                                        value={
                                                            participanteExternos.value
                                                        }
                                                        type="text"
                                                        onChange={(event) => {
                                                            const updatedList =
                                                                participantesExternosList.map(
                                                                    (item) =>
                                                                        item.id ===
                                                                        participanteExternos.id
                                                                            ? {
                                                                                  ...item,
                                                                                  value: event
                                                                                      .target
                                                                                      .value,
                                                                              }
                                                                            : item
                                                                );
                                                            setParticipantesExternosList(
                                                                updatedList
                                                            );
                                                        }}
                                                    />
                                                </Col>
                                                <Col sm={1}>
                                                    <button
                                                        type="button"
                                                        className="button-excluir-stake"
                                                        onClick={() =>
                                                            handleRemoveParticipanteExternos(
                                                                participanteExternos.id
                                                            )
                                                        }
                                                    >
                                                        <FaTimes />
                                                    </button>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                            </Col>

                            {formaDeContato === "Outros" ? (
                                <div>
                                    <Col sm={12} className="mt-4 mb-4">
                                        <Row>
                                            <Col sm={6}>
                                                <Form.Label>
                                                    {t("Forma de engajamento")}
                                                </Form.Label>
                                                <Form.Select
                                                    required
                                                    className="inputSelect"
                                                    onChange={(event) => {
                                                        setFormaDeContato(
                                                            event.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value="">
                                                        {t("Selecione Formato")}
                                                    </option>
                                                    <option value="Virtual">
                                                        {t("Virtual")}
                                                    </option>
                                                    <option value="Presencial">
                                                        {t("Presencial")}
                                                    </option>
                                                    <option value="Ligação Telefônica">
                                                        {t(
                                                            "Ligação Telefônica"
                                                        )}
                                                    </option>
                                                    <option value="Outros">
                                                        {t("Outros")}
                                                    </option>
                                                </Form.Select>
                                            </Col>

                                            <Col sm={6}>
                                                <Form.Label>
                                                    {t("Digite")}
                                                </Form.Label>
                                                <Form.Control
                                                    required
                                                    value={formaDeContatoText}
                                                    className="formInput"
                                                    type="text"
                                                    placeholder="forma de contato"
                                                    onChange={(e) => {
                                                        setFormaDeContatoText(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} className="mt-3">
                                                <Form.Label>
                                                    {t(
                                                        "Selecione foto/documento"
                                                    )}
                                                </Form.Label>
                                                <div
                                                    className="cadastroFotos"
                                                    style={{ padding: "1%" }}
                                                >
                                                    <Form.Control
                                                        type="file"
                                                        name="avatar"
                                                        multiple
                                                        className="inputFoto"
                                                        style={{
                                                            fontSize: "100%",
                                                        }}
                                                        onChange={(e: any) => {
                                                            setFotoEngajamento(
                                                                e.target
                                                                    .files[0]
                                                                    ? e.target
                                                                          .files[0]
                                                                    : null
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </div>
                            ) : (
                                <Col sm={12} className="mt-4 mb-4">
                                    <Row>
                                        <Col sm={6}>
                                            <Form.Label>
                                                {t("Forma de engajamento")}
                                            </Form.Label>
                                            <Form.Select
                                                className="inputSelect"
                                                value={formaDeContato}
                                                onChange={(event) => {
                                                    setFormaDeContato(
                                                        event.target.value
                                                    );
                                                }}
                                            >
                                                <option value="">
                                                    {t("Selecione Formato")}
                                                </option>
                                                <option value="Virtual">
                                                    {t("Virtual")}
                                                </option>
                                                <option value="Presencial">
                                                    {t("Presencial")}
                                                </option>
                                                <option value="Ligação Telefônica">
                                                    {t("Ligação Telefônica")}
                                                </option>
                                                <option value="Outros">
                                                    {t("Outros")}
                                                </option>
                                            </Form.Select>
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Label>
                                                {t("Selecione foto/documento")}
                                            </Form.Label>
                                            <div
                                                className="cadastroFotos"
                                                style={{ padding: "1%" }}
                                            >
                                                <Form.Control
                                                    type="file"
                                                    name="avatarEngajamento"
                                                    multiple
                                                    className="inputFoto"
                                                    style={{ fontSize: "100%" }}
                                                    onChange={(e: any) => {
                                                        setFotoEngajamento(
                                                            e.target.files[0]
                                                                ? e.target
                                                                      .files[0]
                                                                : null
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                            <Col sm={12} className="mb-3">
                                <Row>
                                    <Col sm={8}>
                                        <Form.Label>
                                            {t("Resumo Engajamento")}
                                        </Form.Label>
                                        <Form.Control
                                            required
                                            placeholder="Resumo sobre Engajamento"
                                            className="formInput"
                                            maxLength={1000}
                                            as="textarea"
                                            type={"text"}
                                            value={descricao}
                                            onChange={(e) => {
                                                setDescricao(e.target.value);
                                            }}
                                        />
                                    </Col>

                                    <Col sm={4}>
                                        <Form.Check
                                            className="formSwitch"
                                            type="switch"
                                            style={{
                                                marginTop: "6%",
                                                marginLeft: "5%",
                                            }}
                                            label={t("Plano de Ação?")}
                                            checked={planoAcaoEngaja}
                                            onChange={(e) => {
                                                setPlanoAcaoEngaja(
                                                    e.target.checked
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Col>

                            {planoAcaoEngaja && (
                                <div>
                                    <Col sm={12} className="mt-4 mb-4">
                                        <Row>
                                            <Col sm={12}>
                                                <Form.Label>
                                                    {t("Áreas Envolvidas")}
                                                </Form.Label>
                                                <Form.Group as={Row}>
                                                    <Col>
                                                        <Row>
                                                            <Select
                                                                isMulti
                                                                options={
                                                                    optionsAreas
                                                                }
                                                                value={
                                                                    areasEnvolvidasEngaja
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setAreasEnvolvidasEngaja(
                                                                        e
                                                                    );
                                                                }}
                                                                placeholder="Selecione"
                                                            />
                                                        </Row>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col sm={12} className="mt-4 mb-4">
                                        <Row>
                                            {acoesEngaja.map((acao, index) => (
                                                <div key={index}>
                                                    <Col
                                                        sm={12}
                                                        className="mt-4 mb-4"
                                                    >
                                                        <Row>
                                                            <Col sm={6}>
                                                                <Form.Label>
                                                                    {t(
                                                                        `Ação ${
                                                                            index +
                                                                            1
                                                                        }`
                                                                    )}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    className="formInput"
                                                                    type="text"
                                                                    value={
                                                                        acao.acao
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleChangeAcoesEngaja(
                                                                            index,
                                                                            "acao",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col sm={6}>
                                                                <Form.Label>
                                                                    {t(
                                                                        "Responsável"
                                                                    )}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    className="formInput"
                                                                    type="text"
                                                                    value={
                                                                        acao.responsavel
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleChangeAcoesEngaja(
                                                                            index,
                                                                            "responsavel",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col
                                                        sm={12}
                                                        className="mt-4 mb-4"
                                                    >
                                                        <Row>
                                                            <Col sm={6}>
                                                                <Form.Label>
                                                                    {t("Prazo")}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    className="formInput"
                                                                    type="date"
                                                                    required
                                                                    value={
                                                                        (acao.dataPrazo
                                                                            ? new Date(
                                                                                  acao.dataPrazo
                                                                              )
                                                                                  .toISOString()
                                                                                  .split(
                                                                                      "T"
                                                                                  )[0]
                                                                            : "") ||
                                                                        ""
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleChangeAcoesEngaja(
                                                                            index,
                                                                            "dataPrazo",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col sm={6}>
                                                                <Form.Label>
                                                                    {t(
                                                                        "Status"
                                                                    )}
                                                                </Form.Label>
                                                                <Form.Select
                                                                    className="formSelect"
                                                                    value={
                                                                        acao.status
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleChangeAcoesEngaja(
                                                                            index,
                                                                            "status",
                                                                            e
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                >
                                                                    <option>
                                                                        {t(
                                                                            "Selecionar Status"
                                                                        )}
                                                                    </option>
                                                                    <option>
                                                                        {t(
                                                                            "Não Iniciado"
                                                                        )}
                                                                    </option>
                                                                    <option>
                                                                        {t(
                                                                            "Em Andamento"
                                                                        )}
                                                                    </option>
                                                                    <option>
                                                                        {t(
                                                                            "Concluído"
                                                                        )}
                                                                    </option>
                                                                </Form.Select>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col sm={12}>
                                                        {index > 0 && (
                                                            <Row>
                                                                <Col sm={12}>
                                                                    <Button
                                                                        style={{
                                                                            width: "100%",
                                                                        }}
                                                                        variant="danger mt-2 mb-3 btn-sm"
                                                                        onClick={() =>
                                                                            removerCampoAcaoEngaja(
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        Remover
                                                                        Ação
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </Col>
                                                </div>
                                            ))}
                                        </Row>
                                        <Col sm={12}>
                                            <button
                                                className="btn btn-primary btn-sm"
                                                style={{ width: "100%" }}
                                                type="button"
                                                onClick={
                                                    adicionarCampoAcaoEngaja
                                                }
                                            >
                                                {t("Adicionar Ação")}
                                            </button>
                                        </Col>
                                    </Col>
                                </div>
                            )}

                            <Col sm={12}>
                                <div
                                    style={{
                                        display: "flex",
                                        alignContent: "space-",
                                    }}
                                >
                                    <Col sm={6}>
                                        <button
                                            className="btn btn-primary"
                                            style={{ width: "90%" }}
                                            type="submit"
                                        >
                                            {t("Salvar")}
                                        </button>
                                    </Col>
                                    <Col sm={6}>
                                        <button
                                            className="btn btn-danger"
                                            type="reset"
                                            style={{ width: "95%" }}
                                            onClick={
                                                handleCloseModalEngajamentoCadastro
                                            }
                                        >
                                            {t("Cancelar")}
                                        </button>
                                    </Col>
                                </div>
                            </Col>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>

            {/* MODAL OFICIOS */}
            <Modal
                show={opemModalOficios}
                onHide={handleCloseModalOficios}
                centered={true}
                size="lg"
            >
                <Modal.Header closeButton>
                    <h3>Ofícios</h3>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <h5>Ofícios de Entrada</h5>
                            {oficios.map((oficio: any, i: number) => {
                                const extensao =
                                    oficio?.caminho_oficio_entrada?.slice(-3);
                                return (
                                    <Col className="mt-3" key={i}>
                                        <Form.Label> Oficio {i + 1}</Form.Label>
                                        <Col sm={12} className="cadastroFotos">
                                            <Form.Label>
                                                Selecione foto/PDF para editar
                                                Ofício.
                                            </Form.Label>
                                            <Form.Control
                                                type="file"
                                                name={`oficio_entrada${i}`}
                                                className="inputFoto"
                                                onChange={(e: any) => {
                                                    handleChangeOficios(
                                                        i,
                                                        "caminho_oficio_entrada",
                                                        e.target.files[0]
                                                            ? e.target.files[0]
                                                            : null
                                                    );
                                                }}
                                            />
                                        </Col>
                                        <Col className="mt-2">
                                            {extensao === "pdf" ? (
                                                <ExibirPDF
                                                    pdfPath={
                                                        oficio.caminho_oficio_entrada
                                                    }
                                                />
                                            ) : typeof extensao !== "string" ? (
                                                typeof extensao !==
                                                    "string" && (
                                                    <p>
                                                        Arquivo salvo com
                                                        sucesso
                                                    </p>
                                                )
                                            ) : (
                                                <ExibirImagem
                                                    imageUrl={
                                                        oficio.caminho_oficio_entrada
                                                    }
                                                />
                                            )}
                                        </Col>
                                        <Row>
                                            <Col sm={12}>
                                                <Button
                                                    style={{ width: "100%" }}
                                                    variant="danger mt-3 btn-sm"
                                                    onClick={() =>
                                                        removerCampoOficio(i)
                                                    }
                                                >
                                                    Remover ofícios {i + 1}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                );
                            })}
                        </Col>

                        <Col>
                            <h5>Ofícios de Resposta</h5>
                            {oficios.map((oficio: any, i: number) => {
                                const extensao =
                                    oficio?.caminho_oficio_resposta?.slice(-3);
                                return (
                                    <Col className="mt-3" key={i}>
                                        <Form.Label> Oficio {i + 1}</Form.Label>
                                        <Col sm={12} className="cadastroFotos">
                                            <Form.Label>
                                                Selecione foto/PDF para editar
                                                Ofício.
                                            </Form.Label>
                                            <Form.Control
                                                type="file"
                                                name={`oficio_resposta${i}`}
                                                className="inputFoto"
                                                onChange={(e: any) => {
                                                    handleChangeOficios(
                                                        i,
                                                        "caminho_oficio_resposta",
                                                        e.target.files[0]
                                                            ? e.target.files[0]
                                                            : null
                                                    );
                                                }}
                                            />
                                        </Col>
                                        <Col className="mt-2">
                                            {extensao === "pdf" ? (
                                                <ExibirPDF
                                                    pdfPath={
                                                        oficio.caminho_oficio_resposta
                                                    }
                                                />
                                            ) : typeof extensao !== "string" ? (
                                                <p>Arquivo salvo com sucesso</p>
                                            ) : (
                                                <ExibirImagem
                                                    imageUrl={
                                                        oficio.caminho_oficio_resposta
                                                    }
                                                />
                                            )}
                                        </Col>
                                        <Row>
                                            <Col sm={12}>
                                                <Button
                                                    style={{ width: "100%" }}
                                                    variant="danger mt-3 btn-sm"
                                                    onClick={() =>
                                                        removerCampoOficio(i)
                                                    }
                                                >
                                                    Remover ofícios {i + 1}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                );
                            })}
                        </Col>
                        <Col sm={12} className="mt-3">
                            <Button
                                className="btn btn-primary btn-sm"
                                style={{ width: "100%" }}
                                onClick={adicionarCampoOficio}
                            >
                                Adicionar ofício
                            </Button>
                        </Col>
                        <Col sm={12}>
                            <button
                                type="button"
                                className="btn btn-primary mt-4"
                                style={{ width: "100%" }}
                                onClick={handleCloseModalOficios}
                            >
                                Salvar
                            </button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            {/* {MODAL DE VISUALIZAR OS GRIDS} */}
            <Modal
                show={showModalGrid}
                onHide={handleCloseModalGrid}
                centered={true}
                size="lg"
                dialogClassName="modal-rollover"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Visualizar Engajamentos")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-cadastro mb-5">
                    <GridEngajamentoDemandaRg
                        config={config}
                        idDemanda={idDemanda}
                    />
                </Modal.Body>
            </Modal>
        </Col>
    );
}

export default GridDemandaExternaGov;
