import "./styles.css";
import { Col, Form, Modal, Row, Button, Card } from "react-bootstrap";
import { BiSolidHelpCircle } from "react-icons/bi";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState, useCallback, useMemo } from "react";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import Select from "react-select";
import Api from "../../../config/api";
import { FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { DateRange } from "react-day-picker";
import DateRangeInput from "../../../utils/DateRangeInput";

interface AcaoEngaja {
    acao: string;
    responsavel: string;
    status: string;
    dataPrazo: Date | undefined;
}

interface ListaMunicipios {
    [key: string]: string[];
}

interface OptionType {
    label: string;
    value: string | number;
}

function EngajamentosRg({
    config,
    // permissoesUser,
    listaStakeholders,
    listaEstados,
    listaMunicipios,
}: {
    config: any;
    listaEstados: [string];
    listaMunicipios: ListaMunicipios;
    listaStakeholders: [string];
}) {
    const { t } = useTranslation();

    const [loadingButton, setLoadingButton] = useState<boolean>(false);
    const [showModalDemandas, setShowModalDemandas] = useState<boolean>(false);
    const [selectedRowData, setSelectedRowData] = useState<any | null>(null);
    const [showModalRegister, setShowModalRegister] = useState<boolean>(false);

    // Form
    const [dataEngajamento, setDataEngajamento] = useState<Date | null>();
    const [formaDeContatoText, setFormaDeContatoText] = useState<string>("");
    const [fotoEngajamento, setFotoEngajamento] = useState<any>(null);
    const [participantesMosaicList, setParticipantesMosaicList] = useState([
        {
            id: 1,
            value: "",
        },
    ]);
    const [
        participantesExternosEngajamento,
        setParticipantesExternosEngajamento,
    ] = useState<string>("");
    const [participantesExternosList, setParticipantesExternosList] = useState([
        {
            id: 1,
            value: "",
        },
    ]);
    const [participantesMosaicEngajamento, setParticipantesMosaicEngajamento] =
        useState<string>("");
    const [descricao, setDescricao] = useState<string>("");
    const [formaDeContato, setFormaDeContato] = useState<string>("");
    const [estado, setEstado] = useState<string>("");
    const [tema, setTema] = useState<string>("");
    const [esfera, setEsfera] = useState<string>("");
    const [municipio, setMunicipio] = useState<string>("");
    const [idDemanda, setIdDemanda] = useState();
    const [demandaSelecionada, setDemandaSelecionada] = useState(false);
    const [listademandaExterna, setListaDemandaExterna] = useState<any[]>([]);
    const [listademandaInterna, setListaDemandaInterna] = useState<any[]>([]);
    const [viewListaExterna, setViewListaExterna] = useState(true);
    const [idDemandaAtrelada, setIdDemandaAtrelada] = useState<any>({
        demanda: "",
        id: 0,
    });
    const [tipoDemanda, setTipoDemanda] = useState("externa");

    const [planoAcaoEngaja, setPlanoAcaoEngaja] = useState<boolean>(false);
    const [areasEnvolvidasEngaja, setAreasEnvolvidasEngaja] = useState<any>([]);
    const [acoesEngaja, setAcoesEngaja] = useState<AcaoEngaja[]>([
        { acao: "", responsavel: "", status: "", dataPrazo: undefined },
    ]);

    const [listaEstadosEngaja, setListaEstadosEngaja] = useState<string[]>();
    const [listaMunicipiosEngaja, setListaMunicipiosEngaja] =
        useState<string[]>();
    const [keyForSelect, setKeyForSelect] = useState<any>();

    const [filtroDataEntrada, setFiltroDataEntrada] = useState<DateRange>();
    const [filtroEstado, setFiltroEstado] = useState<string>();
    const [filtroMunicipio, setFiltroMunicipio] = useState<string>();
    const [filtrosAtivos, setFiltroAtivo] = useState<string[]>([]);
    const [openModalFiltro, setOpenModalFiltro] = useState(false);
    const [showPickerData, setShowPickerData] = useState<string>();

    const [origemEngaja, setOrigemEngaja] = useState<string>();

    const handleCloseModalRegister = () => {
        setShowModalRegister(false);
    };

    const handleOpemModalRegister = () => {
        if (demandaSelecionada === false) {
            toast.error(
                "Atrele a uma demanda antes de registrar o Engajamento!"
            );
        } else {
            setShowModalRegister(true);
        }
    };

    const handleClickListaExterna = () => {
        setViewListaExterna(true);
        setTipoDemanda("externa");
    };

    const handleClickListaInterna = () => {
        setViewListaExterna(false);
        setTipoDemanda("interna");
    };

    const handleOpenModalDemandas = () => {
        setShowModalDemandas(true);
    };

    const handleCloseModalDemandas = () => {
        setShowModalDemandas(false);
    };

    useEffect(() => {
        Api.GetDemandaRgExterna(config).then((data) => {
            const sortedData = data.demandas.sort(
                (a: any, b: any) => b.id - a.id
            );
            setListaDemandaExterna(sortedData);
        });

        Api.GetDemandaRgInterna(config).then((data) => {
            const sortedData = data.sort((a: any, b: any) => b.id - a.id);
            setListaDemandaInterna(sortedData);
        });

        Api.GetLocales().then((data) => {
            setListaEstadosEngaja(data.localidades.estados);
            setListaMunicipiosEngaja(data.localidades.municipios);
        });
    }, []);

    // Funcs Form
    const handleRemoveParticipanteMosaic = (id: number) => {
        const updatedParticipantesMosaic = participantesMosaicList.filter(
            (participanteMosaic) => participanteMosaic.id !== id
        );
        setParticipantesMosaicList(updatedParticipantesMosaic);
    };

    const handleAddParticipanteExternos = () => {
        const newParticipanteExternos = {
            id: participantesExternosList.length + 1,
            value: participantesExternosEngajamento,
        };
        setParticipantesExternosList([
            ...participantesExternosList,
            newParticipanteExternos,
        ]);
        setParticipantesExternosEngajamento("");
    };

    const handleRemoveParticipanteExternos = (id: number) => {
        const updatedParticipantesExternos = participantesExternosList.filter(
            (participanteExternos) => participanteExternos.id !== id
        );
        setParticipantesExternosList(updatedParticipantesExternos);
    };

    const handleAddParticipanteMosaic = () => {
        const newParticipanteMosaic = {
            id: participantesMosaicList.length + 1,
            value: participantesMosaicEngajamento,
        };
        setParticipantesMosaicList([
            ...participantesMosaicList,
            newParticipanteMosaic,
        ]);
        setParticipantesMosaicEngajamento("");
    };

    const handleSubmitForm = async (event: any) => {
        event.preventDefault();
        setLoadingButton(true);

        const participantesMosaicStringfy = JSON.stringify(
            participantesMosaicList.map(({ value }: { value: any }) => {
                return value;
            })
        );

        const participantesExternosStringfy = JSON.stringify(
            participantesExternosList.map(({ value }: { value: any }) => {
                return value;
            })
        );

        const formData = new FormData();
        formData.append("data", String(dataEngajamento));
        formData.append("participantesMosaic", participantesMosaicStringfy);
        formData.append("participantesExternos", participantesExternosStringfy);
        formData.append(
            "contato",
            formaDeContato === "Outros" ? formaDeContatoText : formaDeContato
        );
        formData.append("descricao", descricao);

        if (fotoEngajamento !== null) {
            formData.append("avatar", fotoEngajamento[0]);
        }
        formData.append("estado", estado);
        formData.append("tema", tema);
        formData.append("municipio", municipio);
        formData.append("esfera", esfera);
        formData.append(
            idDemandaAtrelada.demanda === "externa"
                ? "idDemandaExterna"
                : "idDemandaInterna",
            idDemandaAtrelada.id
        );

        formData.append("planoAcao", String(planoAcaoEngaja));
        formData.append(
            "areasEnvolvidas",
            JSON.stringify(areasEnvolvidasEngaja)
        );

        formData.append("acoes", JSON.stringify(acoesEngaja));
        origemEngaja && formData.append("origem", origemEngaja);

        await Api.PostEngajamentoRg(formData, config)
            .then((res: any) => {
                toast.success(t("Cadastro feito com sucesso!"));
                if (res) setLoadingButton(false);
                handleClearFields();
                handleCloseModalRegister();
            })
            .catch((err: any) => {
                toast.error(t("Não foi possível fazer o cadastro!"));
                if (err) setLoadingButton(false);
                handleCloseModalRegister();
            });

        setLoadingButton(false);
    };

    const handleClearFields = () => {
        setDescricao("");
        setDataEngajamento(new Date());
        setFormaDeContato("");
        setFotoEngajamento(null);
        setIdDemandaAtrelada({ demanda: "", id: 0 });
        setEstado("");
        setMunicipio("");
        setEsfera("");
        setParticipantesMosaicList([
            {
                id: 1,
                value: "",
            },
        ]);
        setParticipantesExternosList([
            {
                id: 1,
                value: "",
            },
        ]);
        setPlanoAcaoEngaja(false);
        setAreasEnvolvidasEngaja([]);
        setAcoesEngaja([
            { acao: "", responsavel: "", status: "", dataPrazo: undefined },
        ]);
        setKeyForSelect(new Date().getTime());
    };

    const handleRowClick = (params: any) => {
        toast.info("Demanda atrelada com sucesso. Registre o engajamento!");
        setSelectedRowData(params.row);
        // setEstado(params.row.estado);
        // setMunicipio(params.row.municipio);
        setIdDemandaAtrelada({ demanda: tipoDemanda, id: params.row.id });
        // setIsModalOpen(true);

        handleCloseModalDemandas();
        setDemandaSelecionada(true);
    };

    useEffect(() => {
        if (!selectedRowData) return;
        setIdDemanda(selectedRowData.id || 0);
    }, []);

    const datagridColunas: readonly GridColDef<any>[] = [
        {
            field: "dt_entrada",
            headerName: t("Data de Entrada") as string,
            type: "date",
            width: 150,
            valueGetter: ({ row }: { row: any }) => {
                if (!row.dt_entrada) {
                    return null;
                }
                const dataEntrada = new Date(row.dt_entrada);
                dataEntrada.setHours(dataEntrada.getHours() + 4);
                return dataEntrada;
            },
            renderCell: (params: GridRenderCellParams<any, any, any, any>) => {
                return params.value && params.value instanceof Date
                    ? format(params.value, "dd/MM/yyyy", { locale: ptBR })
                    : "";
            },
        },
        {
            field: "estado",
            headerName: t("header_Estado") as string,
            type: "select",
            width: 120,
        },
        {
            field: "municipio",
            headerName: t("header_Municipio") as string,
            type: "string",
            width: 120,
        },
        {
            field: "origem",
            headerName: t("Origem") as string,
            type: "string",
            width: 200,
        },
        {
            field: "prioridade_tema",
            headerName: t("Prioridade") as string,
            type: "string",
            width: 150,
        },
        {
            field: "descricao_tema",
            headerName: t("Descrição") as string,
            type: "string",
            width: 200,
        },
        {
            field: "categoria_tema",
            headerName: t("Categoria") as string,
            type: "string",
            width: 150,
        },
        {
            field: "relevancia",
            headerName: "Relevância" as string,
            type: "text",
            width: 150,
        },
    ];

    const handleChangeAcoesEngaja = (
        index: number,
        field: keyof AcaoEngaja,
        value: string | Date
    ) => {
        const novasAcoes = [...acoesEngaja];

        if (field === "dataPrazo") {
            novasAcoes[index][field] =
                typeof value === "string"
                    ? new Date(value + "T00:00:00")
                    : (value as Date);
        } else {
            novasAcoes[index][field] = value as string;
        }

        setAcoesEngaja(novasAcoes);
    };

    const adicionarCampoAcaoEngaja = () => {
        if (acoesEngaja.length >= 4) {
            toast.error(
                "Limite de ações por engajamento atingido, maximo 4 ações"
            );
        } else {
            setAcoesEngaja([
                ...acoesEngaja,
                { acao: "", responsavel: "", status: "", dataPrazo: undefined },
            ]);
        }
    };

    const removerCampoAcaoEngaja = (index: number) => {
        const novasAcoes = [...acoesEngaja];
        novasAcoes.splice(index, 1);
        setAcoesEngaja(novasAcoes);
    };

    const optionsAreas = [
        { value: "Operações", label: "Operações" },
        { value: "Projetos", label: "Projetos" },
        { value: "EHS", label: "EHS" },
        { value: "Jurídico", label: "Jurídico" },
        { value: "Tributário", label: "Tributário" },
        {
            value: "Relações com Comunidades",
            label: "Relações com Comunidades",
        },
        { value: "Financeiro", label: "Financeiro" },
        { value: "CSC", label: "CSC" },
        { value: "RH", label: "RH" },
        { value: "Comunicação", label: "Comunicação" },
        { value: "Relações Institucionais", label: "Relações Institucionais" },
        { value: "Relações Governamentais", label: "Relações Governamentais" },
        { value: "Instituto Mosaic", label: "Instituto Mosaic" },
        { value: "Licenciamento", label: "Licenciamento" },

    ];

    interface MunicipiosPorEstado {
        [estado: string]: string[];
    }
    // filtros estado e municipio externa
    const municipiosPorEstadoExterna: MunicipiosPorEstado = {};

    const [estadosExistentesExterna, municipiosExistentesExterna] =
        useMemo(() => {
            const estados: string[] = [];
            const municipios: string[] = [];

            listademandaExterna.forEach((r) => {
                r.estado &&
                    !estados.includes(r.estado) &&
                    estados.push(r.estado);
                r.municipio &&
                    !municipios.includes(r.municipio) &&
                    municipios.push(r.municipio);
            });

            return [
                estados.sort((a, b) => a.localeCompare(b)),
                municipios.sort((a, b) => a.localeCompare(b)),
            ];
        }, [listademandaExterna]);

    listademandaExterna.forEach((r) => {
        if (r.estado && r.municipio) {
            if (!municipiosPorEstadoExterna[r.estado]) {
                municipiosPorEstadoExterna[r.estado] = [];
            }

            if (!municipiosPorEstadoExterna[r.estado].includes(r.municipio)) {
                municipiosPorEstadoExterna[r.estado].push(r.municipio);
            }
        }
    });

    // filtros estado e municipio interna
    const municipiosPorEstadoInterna: MunicipiosPorEstado = {};

    const [estadosExistentesInterna, municipiosExistentesInterna] =
        useMemo(() => {
            const estados: string[] = [];
            const municipios: string[] = [];

            listademandaInterna.forEach((r) => {
                r.estado &&
                    !estados.includes(r.estado) &&
                    estados.push(r.estado);
                r.municipio &&
                    !municipios.includes(r.municipio) &&
                    municipios.push(r.municipio);
            });

            return [
                estados.sort((a, b) => a.localeCompare(b)),
                municipios.sort((a, b) => a.localeCompare(b)),
            ];
        }, [listademandaInterna]);

    listademandaInterna.forEach((r) => {
        if (r.estado && r.municipio) {
            if (!municipiosPorEstadoInterna[r.estado]) {
                municipiosPorEstadoInterna[r.estado] = [];
            }

            if (!municipiosPorEstadoInterna[r.estado].includes(r.municipio)) {
                municipiosPorEstadoInterna[r.estado].push(r.municipio);
            }
        }
    });

    const tiposFiltro = [
        {
            value: "Data de Entrada",
            label: t("Data de Entrada"),
            type: "date",
            filter: filtroDataEntrada,
            setFilter: setFiltroDataEntrada,
        },
        {
            value: "Estado",
            label: t("Estado"),
            type: "select",
            opts: viewListaExterna
                ? estadosExistentesExterna
                : estadosExistentesInterna,
            filter: filtroEstado,
            setFilter: setFiltroEstado,
        },
        {
            value: "Municipio",
            label: t("Municipio"),
            type: "select",
            opts: viewListaExterna
                ? municipiosExistentesExterna
                : municipiosExistentesInterna,
            filter: filtroMunicipio,
            setFilter: setFiltroMunicipio,
        },
    ];

    const handleModalFiltros = (filtro: string) => {
        setFiltroAtivo((filtros) => {
            const newFiltros = [...filtros];
            const fIndex = newFiltros.findIndex((f) => f === filtro);
            if (fIndex >= 0) {
                newFiltros.splice(fIndex, 1);
                tiposFiltro
                    .find((f) => f.value === filtro)
                    ?.setFilter(undefined);
            } else {
                newFiltros.push(filtro);
            }
            return newFiltros;
        });
        setOpenModalFiltro(true);
    };

    const dadosFiltradosExterna = useMemo(
        () =>
            listademandaExterna.filter((v) => {
                const dataEntrada = new Date(v.dt_entrada);
                dataEntrada.setHours(dataEntrada.getHours() + 3);
                return (
                    (!filtroEstado ||
                        v.estado
                            ?.toLowerCase()
                            .includes(filtroEstado?.toLowerCase())) &&
                    (!filtroMunicipio ||
                        v.municipio
                            ?.toLowerCase()
                            .includes(filtroMunicipio?.toLowerCase())) &&
                    (!filtroDataEntrada ||
                        (dataEntrada >=
                            (filtroDataEntrada.from || new Date(0)) &&
                            dataEntrada <=
                                (filtroDataEntrada.to
                                    ? filtroDataEntrada.to
                                    : filtroDataEntrada.from || new Date())))
                );
            }),
        [listademandaExterna, filtroEstado, filtroMunicipio, filtroDataEntrada]
    );

    const dadosFiltradosInterna = useMemo(
        () =>
            listademandaInterna.filter((v) => {
                const dataEntrada = new Date(v.dt_entrada);
                dataEntrada.setHours(dataEntrada.getHours() + 3);
                return (
                    (!filtroEstado ||
                        v.estado
                            ?.toLowerCase()
                            .includes(filtroEstado?.toLowerCase())) &&
                    (!filtroMunicipio ||
                        v.municipio
                            ?.toLowerCase()
                            .includes(filtroMunicipio?.toLowerCase())) &&
                    (!filtroDataEntrada ||
                        (dataEntrada >=
                            (filtroDataEntrada.from || new Date(0)) &&
                            dataEntrada <=
                                (filtroDataEntrada.to
                                    ? filtroDataEntrada.to
                                    : filtroDataEntrada.from || new Date())))
                );
            }),
        [listademandaInterna, filtroEstado, filtroMunicipio, filtroDataEntrada]
    );

    const getRangeText = (range: DateRange): string => {
        if (!range?.from) return "";

        return (
            format(range.from, "dd/MM/yyyy", { locale: ptBR }) +
            " à " +
            format(range.to || range.from, "dd/MM/yyyy", { locale: ptBR })
        );
    };

    return (
        <div>
            <Col sm={12} id="engajamentoPage">
                <Form
                    // onSubmit={handleSubmitForm}
                    encType="multipart/form-data"
                >
                    <Col sm={12} className="mt-4 mb-4">
                        <Row>
                            <Col sm={12}>
                                <Form.Label>{t("Data")}</Form.Label>
                                <Form.Control
                                    required
                                    className="formInput"
                                    type={"date"}
                                    value={
                                        dataEngajamento
                                            ? dataEngajamento
                                                  .toISOString()
                                                  .split("T")[0]
                                            : ""
                                    }
                                    onChange={(e) => {
                                        setDataEngajamento(
                                            new Date(
                                                e.target.value + " 00:00:00"
                                            )
                                        );
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={12} className="mt-4 mb-4">
                        <Row>
                            <Col sm={4}>
                                <Form.Label>{t("Esfera")}</Form.Label>
                                <Form.Select
                                    value={esfera}
                                    onChange={(e) => {
                                        setEsfera(e.target.value);
                                    }}
                                >
                                    <option value={""}>
                                        {t("Selecione a Esfera")}
                                    </option>
                                    <option value={"Federal"}>
                                        {t("Federal")}
                                    </option>
                                    <option value={"Estadual"}>
                                        {t("Estadual")}
                                    </option>
                                    <option value={"Municipal"}>
                                        {t("Municipal")}
                                    </option>
                                </Form.Select>
                            </Col>

                            <Col sm={4}>
                                <Form.Label>{t("Estado")}</Form.Label>
                                <Form.Select
                                    key={keyForSelect}
                                    value={estado}
                                    onChange={(e) => {
                                        setEstado(e.target.value);
                                    }}
                                >
                                    <option value={""}>
                                        {t("Selecione o estado")}
                                    </option>
                                    {listaEstados?.map(
                                        (estado: any, i: any) => {
                                            return (
                                                <option
                                                    key={i}
                                                    value={estado.sigla}
                                                >
                                                    {estado.nome}
                                                </option>
                                            );
                                        }
                                    )}
                                </Form.Select>
                            </Col>

                            <Col sm={4}>
                                <Form.Label>{t("Municipio")}</Form.Label>
                                <Form.Select
                                    value={municipio}
                                    key={1}
                                    onChange={(e) => {
                                        setMunicipio(e.target.value);
                                    }}
                                >
                                    <option value={""}>
                                        {t("Selecione o municipio")}
                                    </option>
                                    {estado && listaMunicipios[estado] ? (
                                        listaMunicipios[estado].map(
                                            (municipio: string) => (
                                                <option
                                                    key={municipio}
                                                    value={municipio}
                                                >
                                                    {municipio}
                                                </option>
                                            )
                                        )
                                    ) : (
                                        <option value="">
                                            Nenhum município disponível
                                        </option>
                                    )}
                                </Form.Select>
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={12} className="mt-4 mb-4">
                        <Row>
                            <Col sm={6}>
                                <Form.Label>{t("Origem")}</Form.Label>
                                <Form.Select
                                    required
                                    value={origemEngaja}
                                    className="formSelect"
                                    onChange={(e) => {
                                        setOrigemEngaja(e.target.value);
                                    }}
                                >
                                    <option value={""}>
                                        {t("Selecione Origem")}
                                    </option>
                                    <option
                                        value={
                                            "Poder Público Municipal - Prefeitura"
                                        }
                                    >
                                        {t(
                                            "Poder Público Municipal - Prefeitura"
                                        )}
                                    </option>
                                    <option
                                        value={
                                            "Poder Público Municipal - Secretaria"
                                        }
                                    >
                                        {t(
                                            "Poder Público Municipal - Secretaria"
                                        )}
                                    </option>
                                    <option
                                        value={
                                            "Poder Público Municipal - Autarquia"
                                        }
                                    >
                                        {t(
                                            "Poder Público Municipal - Autarquia"
                                        )}
                                    </option>
                                    <option
                                        value={
                                            "Poder Público Municipal - Câmara de Vereadores"
                                        }
                                    >
                                        {t(
                                            "Poder Público Municipal - Câmara de Vereadores"
                                        )}
                                    </option>
                                    <option value={"Poder Público Estadual"}>
                                        {t("Poder Público Estadual")}
                                    </option>
                                    <option value={"Poder Público Federal"}>
                                        {t("Poder Público Federal")}
                                    </option>
                                    <option value={"Ministério Público"}>
                                        {t("Ministério Público")}
                                    </option>
                                    <option value={"Entidades Setoriais"}>
                                        {t("Entidades Setoriais")}
                                    </option>
                                    <option
                                        value={"Entidades do Terceiro Setor"}
                                    >
                                        {t("Entidades do Terceiro Setor")}
                                    </option>
                                    <option value={"Setor Privado"}>
                                        {t("Setor Privado")}
                                    </option>
                                    <option value={"Academia"}>
                                        {t("Academia")}
                                    </option>
                                    <option value={"outros"}>
                                        {t("Outros")}
                                    </option>
                                </Form.Select>
                            </Col>
                            <Col sm={6}>
                                <Form.Label>{t("Tema")}</Form.Label>
                                <Form.Control
                                    required
                                    className="formInput"
                                    type={"text"}
                                    value={tema}
                                    onChange={(e) => {
                                        setTema(e.target.value);
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col sm={12}>
                        <Form.Label>{t("Participantes Mosaic")}</Form.Label>
                        <Col sm={12}>
                            <Row>
                                <Col sm={6}>
                                    <Form.Control
                                        required
                                        placeholder="Participantes Mosaic"
                                        className="formInput"
                                        value={
                                            participantesMosaicList[0]?.value ||
                                            ""
                                        }
                                        type="text"
                                        onChange={(event) => {
                                            const updatedList = [
                                                ...participantesMosaicList,
                                            ];
                                            updatedList[0] = {
                                                ...updatedList[0],
                                                value: event.target.value,
                                            };
                                            setParticipantesMosaicList(
                                                updatedList
                                            );
                                        }}
                                    />
                                </Col>
                                <Col sm={6}>
                                    <Button
                                        type="button"
                                        className="btn btn-primary"
                                        style={{ width: "100%" }}
                                        onClick={handleAddParticipanteMosaic}
                                    >
                                        {t("Adicionar Participantes Mosaic")}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        {participantesMosaicList
                            .slice(1)
                            .map((participanteMosaic, index) => (
                                <div
                                    key={participanteMosaic.id}
                                    className="mt-2 mb-2"
                                >
                                    <Row className="mt-3">
                                        <Col sm={11}>
                                            <Form.Control
                                                placeholder="Participantes Mosaic"
                                                className="formInput"
                                                value={participanteMosaic.value}
                                                type="text"
                                                onChange={(event) => {
                                                    const updatedList =
                                                        participantesMosaicList.map(
                                                            (item) =>
                                                                item.id ===
                                                                participanteMosaic.id
                                                                    ? {
                                                                          ...item,
                                                                          value: event
                                                                              .target
                                                                              .value,
                                                                      }
                                                                    : item
                                                        );
                                                    setParticipantesMosaicList(
                                                        updatedList
                                                    );
                                                }}
                                            />
                                        </Col>
                                        <Col sm={1}>
                                            <button
                                                type="button"
                                                className="button-excluir-stake"
                                                onClick={() =>
                                                    handleRemoveParticipanteMosaic(
                                                        participanteMosaic.id
                                                    )
                                                }
                                            >
                                                <FaTimes />
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                    </Col>

                    <Col sm={12} className="mt-4">
                        <Form.Label>{t("Participantes Externos")}</Form.Label>
                        <Col sm={12}>
                            <Row className="mb-2">
                                <Col sm={6}>
                                    <Form.Control
                                        placeholder="Participantes Externos"
                                        className="formInput"
                                        value={
                                            participantesExternosList[0]
                                                ?.value || ""
                                        }
                                        type="text"
                                        onChange={(event) => {
                                            const updatedList = [
                                                ...participantesExternosList,
                                            ];
                                            updatedList[0] = {
                                                ...updatedList[0],
                                                value: event.target.value,
                                            };
                                            setParticipantesExternosList(
                                                updatedList
                                            );
                                        }}
                                    />
                                </Col>
                                <Col sm={6}>
                                    <Button
                                        type="button"
                                        className="btn btn-primary"
                                        style={{ width: "100%" }}
                                        onClick={handleAddParticipanteExternos}
                                    >
                                        {t("Adicionar Participantes Externos")}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        {participantesExternosList
                            .slice(1)
                            .map((participanteExternos, index) => (
                                <div
                                    key={participanteExternos.id}
                                    className="mt-2 mb-2"
                                >
                                    <Row>
                                        <Col sm={11} className="mt-2">
                                            <Form.Control
                                                placeholder="Participantes Externos"
                                                className="formInput"
                                                value={
                                                    participanteExternos.value
                                                }
                                                type="text"
                                                onChange={(event) => {
                                                    const updatedList =
                                                        participantesExternosList.map(
                                                            (item) =>
                                                                item.id ===
                                                                participanteExternos.id
                                                                    ? {
                                                                          ...item,
                                                                          value: event
                                                                              .target
                                                                              .value,
                                                                      }
                                                                    : item
                                                        );
                                                    setParticipantesExternosList(
                                                        updatedList
                                                    );
                                                }}
                                            />
                                        </Col>
                                        <Col sm={1}>
                                            <button
                                                type="button"
                                                className="button-excluir-stake"
                                                onClick={() =>
                                                    handleRemoveParticipanteExternos(
                                                        participanteExternos.id
                                                    )
                                                }
                                            >
                                                <FaTimes />
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                    </Col>

                    {formaDeContato === "Outros" ? (
                        <div>
                            <Col sm={12} className="mt-4 mb-4">
                                <Row>
                                    <Col sm={6}>
                                        <Form.Label>
                                            {t("Forma de engajamento")}
                                        </Form.Label>
                                        <Form.Select
                                            required
                                            className="inputSelect"
                                            onChange={(event) => {
                                                setFormaDeContato(
                                                    event.target.value
                                                );
                                            }}
                                        >
                                            <option value="">
                                                {t("Selecione Formato")}
                                            </option>
                                            <option value="Virtual">
                                                {t("Virtual")}
                                            </option>
                                            <option value="Presencial">
                                                {t("Presencial")}
                                            </option>
                                            <option value="Ligação Telefônica">
                                                {t("Ligação Telefônica")}
                                            </option>
                                            <option value="Outros">
                                                {t("Outros")}
                                            </option>
                                        </Form.Select>
                                    </Col>

                                    <Col sm={6}>
                                        <Form.Label>{t("Digite")}</Form.Label>
                                        <Form.Control
                                            required
                                            value={formaDeContatoText}
                                            className="formInput"
                                            type="text"
                                            placeholder="forma de contato"
                                            onChange={(e) => {
                                                setFormaDeContatoText(
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} className="mt-3">
                                        <Form.Label>
                                            {t("Selecione foto/documento")}
                                        </Form.Label>
                                        <div
                                            className="cadastroFotos"
                                            style={{ padding: "1%" }}
                                        >
                                            <Form.Control
                                                type="file"
                                                name="avatar"
                                                className="inputFoto"
                                                multiple
                                                style={{ fontSize: "100%" }}
                                                onChange={(e: any) => {
                                                    setFotoEngajamento(
                                                        e.target.files
                                                            ? e.target.files
                                                            : null
                                                    );
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </div>
                    ) : (
                        <Col sm={12} className="mt-4 mb-4">
                            <Row>
                                <Col sm={6}>
                                    <Form.Label>
                                        {t("Forma de engajamento")}
                                    </Form.Label>
                                    <Form.Select
                                        required
                                        className="inputSelect"
                                        value={formaDeContato}
                                        onChange={(event) => {
                                            setFormaDeContato(
                                                event.target.value
                                            );
                                        }}
                                    >
                                        <option value="">
                                            {t("Selecione Formato")}
                                        </option>
                                        <option value="Virtual">
                                            {t("Virtual")}
                                        </option>
                                        <option value="Presencial">
                                            {t("Presencial")}
                                        </option>
                                        <option value="Ligação Telefônica">
                                            {t("Ligação Telefônica")}
                                        </option>
                                        <option value="Outros">
                                            {t("Outros")}
                                        </option>
                                    </Form.Select>
                                </Col>
                                <Col sm={6}>
                                    <Form.Label>
                                        {t("Selecione foto/documento")}
                                    </Form.Label>
                                    <div
                                        className="cadastroFotos"
                                        style={{ padding: "1%" }}
                                    >
                                        <Form.Control
                                            type="file"
                                            name="avatar"
                                            className="inputFoto"
                                            multiple
                                            style={{ fontSize: "100%" }}
                                            onChange={(e: any) => {
                                                setFotoEngajamento(
                                                    e.target.files
                                                        ? e.target.files
                                                        : null
                                                );
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    )}
                    <Col sm={12} className="mb-3">
                        <Row>
                            <Col sm={8}>
                                <Form.Label>
                                    {t("Resumo Engajamento")}
                                </Form.Label>
                                <Form.Control
                                    required
                                    placeholder="Resumo sobre Engajamento"
                                    className="formInput"
                                    maxLength={1000}
                                    as="textarea"
                                    type={"text"}
                                    value={descricao}
                                    onChange={(e) => {
                                        setDescricao(e.target.value);
                                    }}
                                />
                            </Col>

                            <Col sm={4}>
                                <Form.Check
                                    className="formSwitch"
                                    type="switch"
                                    style={{
                                        marginTop: "6%",
                                        marginLeft: "5%",
                                    }}
                                    label={t("Plano de Ação?")}
                                    checked={planoAcaoEngaja}
                                    onChange={(e) => {
                                        setPlanoAcaoEngaja(e.target.checked);
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>

                    {planoAcaoEngaja && (
                        <div>
                            <Col sm={12} className="mt-4 mb-4">
                                <Row>
                                    <Col sm={12}>
                                        <Form.Label>
                                            {t("Áreas Envolvidas")}
                                        </Form.Label>
                                        <Form.Group as={Row}>
                                            <Col>
                                                <Row>
                                                    <Select
                                                        isMulti
                                                        options={optionsAreas}
                                                        value={
                                                            areasEnvolvidasEngaja
                                                        }
                                                        onChange={(e) => {
                                                            setAreasEnvolvidasEngaja(
                                                                e
                                                            );
                                                        }}
                                                        placeholder="Selecione"
                                                    />
                                                </Row>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>

                            <Col sm={12} className="mt-4 mb-4">
                                <Row>
                                    {acoesEngaja.map((acao, index) => (
                                        <div key={index}>
                                            <Col sm={12} className="mt-4 mb-4">
                                                <Row>
                                                    <Col sm={6}>
                                                        <Form.Label>
                                                            {t(
                                                                `Ação ${
                                                                    index + 1
                                                                }`
                                                            )}
                                                        </Form.Label>
                                                        <Form.Control
                                                            className="formInput"
                                                            type="text"
                                                            value={acao.acao}
                                                            onChange={(e) =>
                                                                handleChangeAcoesEngaja(
                                                                    index,
                                                                    "acao",
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </Col>
                                                    <Col sm={6}>
                                                        <Form.Label>
                                                            {t("Responsável")}
                                                        </Form.Label>
                                                        <Form.Control
                                                            className="formInput"
                                                            type="text"
                                                            value={
                                                                acao.responsavel
                                                            }
                                                            onChange={(e) =>
                                                                handleChangeAcoesEngaja(
                                                                    index,
                                                                    "responsavel",
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={12} className="mt-4 mb-4">
                                                <Row>
                                                    <Col sm={6}>
                                                        <Form.Label>
                                                            {t("Prazo")}
                                                        </Form.Label>
                                                        <Form.Control
                                                            className="formInput"
                                                            type="date"
                                                            required
                                                            value={
                                                                (acao.dataPrazo
                                                                    ? new Date(
                                                                          acao.dataPrazo
                                                                      )
                                                                          .toISOString()
                                                                          .split(
                                                                              "T"
                                                                          )[0]
                                                                    : "") || ""
                                                            }
                                                            onChange={(e) =>
                                                                handleChangeAcoesEngaja(
                                                                    index,
                                                                    "dataPrazo",
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </Col>
                                                    <Col sm={6}>
                                                        <Form.Label>
                                                            {t("Status")}
                                                        </Form.Label>
                                                        <Form.Select
                                                            className="formSelect"
                                                            value={acao.status}
                                                            onChange={(e) =>
                                                                handleChangeAcoesEngaja(
                                                                    index,
                                                                    "status",
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        >
                                                            <option>
                                                                {t(
                                                                    "Selecionar Status"
                                                                )}
                                                            </option>
                                                            <option>
                                                                {t(
                                                                    "Não Iniciado"
                                                                )}
                                                            </option>
                                                            <option>
                                                                {t(
                                                                    "Em Andamento"
                                                                )}
                                                            </option>
                                                            <option>
                                                                {t("Concluído")}
                                                            </option>
                                                        </Form.Select>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={12}>
                                                {index > 0 && (
                                                    <Row>
                                                        <Col sm={12}>
                                                            <Button
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                variant="danger mt-2 mb-3 btn-sm"
                                                                onClick={() =>
                                                                    removerCampoAcaoEngaja(
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                Remover Ação
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                )}
                                            </Col>
                                        </div>
                                    ))}
                                </Row>
                                <Col sm={12}>
                                    <button
                                        className="btn btn-primary btn-sm"
                                        style={{ width: "100%" }}
                                        type="button"
                                        onClick={adicionarCampoAcaoEngaja}
                                    >
                                        {t("Adicionar Ação")}
                                    </button>
                                </Col>
                            </Col>
                        </div>
                    )}

                    <Col sm={12}>
                        <Row>
                            <Col sm={4}>
                                <button
                                    onClick={handleOpenModalDemandas}
                                    className="botao-engajamento"
                                    type="button"
                                >
                                    {demandaSelecionada
                                        ? "Issue Atrelada"
                                        : "Atrelar Issue"}
                                </button>
                            </Col>
                            <Col sm={4}>
                                <button
                                    type="button"
                                    className="botao-engajamento"
                                    onClick={handleOpemModalRegister}
                                >
                                    {t("Registrar")}
                                </button>
                            </Col>
                            <Col sm={4}>
                                <button
                                    type="button"
                                    className="botao-engajamento"
                                    onClick={handleClearFields}
                                >
                                    {t("Limpar")}
                                </button>
                            </Col>
                        </Row>
                    </Col>
                </Form>
            </Col>

            {/* MODAL Lista de demandas */}
            <Modal
                show={showModalDemandas}
                onHide={handleCloseModalDemandas}
                centered={true}
                size="lg"
            >
                <Modal.Header>
                    <Col sm={12}>
                        <Row className="mb-4">
                            <Col sm={6}>
                                <button
                                    className={`btn btn-primary ${
                                        viewListaExterna ? "active" : ""
                                    }`}
                                    onClick={handleClickListaExterna}
                                    style={{ width: "95%" }}
                                >
                                    Issue
                                </button>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                {filtrosAtivos.map((filtro, i) => {
                                    const filterObj = tiposFiltro.find(
                                        ({ value }: { value: any }) =>
                                            value === filtro
                                    );
                                    if (!filterObj) return null;

                                    const { filter, setFilter, type, opts } =
                                        filterObj;

                                    return (
                                        <Col className="filtro-ativo" key={i}>
                                            <Row
                                                style={{
                                                    marginLeft: "3%",
                                                    marginTop: "1px",
                                                }}
                                            >
                                                <Col sm={7}>
                                                    {type === "date" ? (
                                                        <>
                                                            <button
                                                                onClick={() =>
                                                                    setShowPickerData(
                                                                        filtro
                                                                    )
                                                                }
                                                                style={{
                                                                    border: 0,
                                                                    outline: 0,
                                                                    backgroundColor:
                                                                        "#0000",
                                                                    display:
                                                                        "block",
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    padding: 0,
                                                                    margin: 0,
                                                                }}
                                                            >
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder={`Filtrar ${filtro}`}
                                                                    value={getRangeText(
                                                                        filter as any
                                                                    )}
                                                                    style={{
                                                                        height: "73%",
                                                                    }}
                                                                    onFocus={(
                                                                        e
                                                                    ) =>
                                                                        e.target.blur()
                                                                    }
                                                                    readOnly
                                                                />
                                                            </button>
                                                            {showPickerData ===
                                                                filtro && (
                                                                <DateRangeInput
                                                                    selected={
                                                                        filter as any
                                                                    }
                                                                    onChange={
                                                                        setFilter
                                                                    }
                                                                    onFinish={() =>
                                                                        setShowPickerData(
                                                                            undefined
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </>
                                                    ) : type === "select" ? (
                                                        <Form.Select
                                                            value={
                                                                filter?.toString() ||
                                                                ""
                                                            }
                                                            onChange={(e) => {
                                                                setFilter(
                                                                    e.target
                                                                        .value as any
                                                                );
                                                            }}
                                                            style={{
                                                                height: "73%",
                                                            }}
                                                        >
                                                            <option
                                                                label={`Filtrar ${filtro}`}
                                                                value={""}
                                                            />
                                                            {opts?.map(
                                                                (opt, i) => {
                                                                    const isString =
                                                                        typeof opt ===
                                                                        "string";

                                                                    return (
                                                                        <option
                                                                            key={
                                                                                i
                                                                            }
                                                                            label={
                                                                                isString
                                                                                    ? opt
                                                                                    : (
                                                                                          opt as OptionType
                                                                                      )
                                                                                          .label
                                                                            }
                                                                            value={
                                                                                isString
                                                                                    ? opt
                                                                                    : (
                                                                                          opt as OptionType
                                                                                      )
                                                                                          .value
                                                                            }
                                                                        />
                                                                    );
                                                                }
                                                            )}
                                                        </Form.Select>
                                                    ) : (
                                                        <Form.Control
                                                            type="text"
                                                            placeholder={`Filtrar ${filtro}`}
                                                            value={
                                                                filter?.toString() ||
                                                                ""
                                                            }
                                                            onChange={(e) => {
                                                                setFilter(
                                                                    e.target
                                                                        .value as any
                                                                );
                                                            }}
                                                            style={{
                                                                height: "73%",
                                                            }}
                                                        />
                                                    )}
                                                </Col>
                                                <Col sm={4}>
                                                    <button
                                                        onClick={() => {
                                                            setFilter(
                                                                undefined
                                                            );
                                                        }}
                                                        className="botao-filtro mt-2"
                                                    >
                                                        {t("Limpar Filtro")}
                                                    </button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    );
                                })}
                                <Col sm={12}>
                                    <button
                                        style={{
                                            width: "90%",
                                            marginLeft: "4%",
                                        }}
                                        className="botao-filtro mt-2"
                                        onClick={() =>
                                            setOpenModalFiltro(!openModalFiltro)
                                        }
                                    >
                                        {t("Filtros")}
                                    </button>
                                </Col>
                                {openModalFiltro && (
                                    <Col sm={12} className="mb-2">
                                        <Card>
                                            <Card.Body>
                                                <Col
                                                    sm={12}
                                                    className="justify-content-center"
                                                >
                                                    <Row>
                                                        {tiposFiltro.map(
                                                            (filtro, i) => (
                                                                <Col
                                                                    sm={4}
                                                                    key={i}
                                                                >
                                                                    <button
                                                                        className={`filtros-modal ${
                                                                            filtrosAtivos?.includes(
                                                                                filtro.value
                                                                            )
                                                                                ? "ativo"
                                                                                : ""
                                                                        }`}
                                                                        onClick={() => {
                                                                            handleModalFiltros(
                                                                                filtro.value
                                                                            );
                                                                        }}
                                                                    >
                                                                        {
                                                                            filtro.label
                                                                        }
                                                                    </button>
                                                                </Col>
                                                            )
                                                        )}
                                                    </Row>
                                                </Col>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Modal.Header>
                <Modal.Body>
                    {viewListaExterna ? (
                        <DataGrid
                            rows={dadosFiltradosExterna}
                            columns={datagridColunas}
                            onRowClick={handleRowClick}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 7,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                        />
                    ) : (
                        <DataGrid
                            rows={dadosFiltradosInterna}
                            columns={datagridColunas}
                            onRowClick={handleRowClick}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 7,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                        />
                    )}
                </Modal.Body>
            </Modal>

            {/* MODAL CONFIRMACÇÂO DE REGISTRO */}
            <Modal
                show={showModalRegister}
                onHide={handleCloseModalRegister}
                centered={true}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div id="modalLogin">
                        <form className="form">
                            <p className="titleForgotPassword">
                                {t("Deseja Registrar Engajamento?")}
                            </p>

                            <button
                                className="botao-grid"
                                disabled={loadingButton}
                                type="button"
                                onClick={handleSubmitForm}
                            >
                                {loadingButton ? "Cadastrando..." : "Sim"}
                            </button>
                            <button
                                className="botao-grid"
                                type="button"
                                onClick={handleCloseModalRegister}
                            >
                                {t("Não")}
                            </button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default EngajamentosRg;
